import { I18N } from "@aurelia/i18n";
import { IRouter } from '@aurelia/router';
import { bindable, inject } from "aurelia";
import { toastifyError } from '@helpers/toastify';
import { IAurelia, IEventAggregator } from 'aurelia';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';

@inject(I18N, IAurelia, IRouter, IAuthService, IEventAggregator, IValidationRules, newInstanceForScope(IValidationController))
export class OtpPage {
  @bindable length: number = 6;
  @bindable value: string = '';

  private email: string;
  private isLoading: boolean;
  private inputs: HTMLInputElement[] = [];

  private intervalId: NodeJS.Timeout;

  private timeLeft: number;
  private initialTime: number = 300;
  private countdown: string | null = '04:00';

  constructor(
    private I18N: I18N,
    private au: IAurelia,
    private router: IRouter,
    private auth: IAuthService,
    readonly ea: IEventAggregator,
    private validationRules: IValidationRules,
    private validationController: IValidationController
  ) { }

  bound() {
    this.email = localStorage.getItem('copymagico-email');

    if (!this.email || this.email === '' || this.email === 'undefined') {
      this.router.load('/login');
    }
  }

  attached() {
    this.startCountdown();

    this.inputs = Array.from(document.querySelectorAll('.otp-input'));
  }

  handleInput(event: Event, index: number) {
    const input = event.target as HTMLInputElement;

    const value = input.value

    if (value === '') this.movePrevious(index);

    // verificar se é numero se não for apagar do input
    if (!/^[0-9]$/.test(value)) {
      input.value = '';

      return
    }

    if (value) {
      input.value = value; // Garante que o número apareça no campo
      this.moveNext(index);
    } else {
      this.movePrevious(index);
    }

    this.updateValue();

    if (this.value.length === 6) {
      this.signInWithOTP();
    }
  }

  pasteCode(event: ClipboardEvent) {
    event.preventDefault();

    const pasteData = event.clipboardData?.getData('text') || '';
    const numbersOnly = pasteData.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (!numbersOnly) return;

    for (let i = 0; i < this.length; i++) {
      if (numbersOnly.at(i)) { // Fixed syntax error with .at() method
        this.inputs.at(i).value = numbersOnly.at(i); // Atualiza o input com os números colados
      } else {
        this.inputs.at(i).value = ''; // Limpa os campos restantes
      }
    }

    this.updateValue(); // Atualiza o valor total do OTP
    this.inputs?.at(this.length - 1)?.focus(); // Move o foco para o último campo
    if (numbersOnly.length === this.length) {
      this.signInWithOTP();
    }
  }

  movePrevious(index: number) {
    if (index > 0) {
      this.inputs?.at(index - 1)?.focus(); // Move o foco para o input anterior
    }
  }

  moveNext(index: number) {
    if (index < this.length - 1) {
      this.inputs?.at(index + 1)?.focus();
    }
  }

  updateValue() {
    this.value = this.inputs.map(input => input.value).join('');
  }

  startCountdown() {
    this.timeLeft = this.initialTime;

    this.intervalId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.updateCountdownDisplay();
      } else {
        clearInterval(this.intervalId);
        this.countdown = null;
      }
    }, 1000);
  }

  updateCountdownDisplay() {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;

    this.countdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  private async resendOTP() {
    try {
      const response = await this.auth.signInOTP({ email: this.email });

      if (!response) throw new Error('Algo deu errado, tente novamente mais tarde');

      this.startCountdown();
    } catch (error) {
      if (error instanceof Error) {
        toastifyError({ message: error.message });
      } else {
        toastifyError({ message: 'Algo deu errado, tente novamente mais tarde' });
      }
    }
  }

  private async signInWithOTP() {
    this.isLoading = true;

    try {
      const response = await this.auth.verifyEmailOTP({ email: this.email, otp: this.value });

      if (!response) throw new Error('Algo deu errado, tente novamente mais tarde');

      localStorage.removeItem('copymagico-email');

      this.router.load('/');
    } catch (error) {
      if (error instanceof Error) {
        toastifyError({ message: error.message });
      } else {
        toastifyError({ message: 'Algo deu errado, tente novamente mais tarde' });
      }
    } finally {
      this.isLoading = false;
    }
  }


}
