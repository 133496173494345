export class PaymentSuccessPage {

  constructor() { }

  canLoad() {
    console.log('purc')
    window.dataLayer.push({
      event: 'purchased',
    });
    return '/'
  }
}