import { inject, watch } from 'aurelia';
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { dealAttachments_constraint, dealAttachments_update_column, ModelTypes } from '@zeus';

import { IOpportunityService } from '../../services/opportunity-service';

@inject(IZeusClient, IOpportunityService)
export class AttachmentsSection {
  private opportunity: ModelTypes["deal"];
  private attachments: ModelTypes["dealAttachments"][] = [];

  private files: ModelTypes['files'][] = [];

  constructor(
    private zeusClient: ZeusClient,
    private opportunityService: IOpportunityService
  ) { }

  attached() {
    this.opportunity = this.opportunityService.opportunitySubscription;
    this.attachments = this.opportunity?.attachments;

    this.files = this.attachments?.map(attachment => ({ ...attachment.file })) || [];
  }

  @watch('opportunityService.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal']) {
    this.opportunity = newData;
    this.attachments = this.opportunity?.attachments;

    this.files = this.attachments?.map(attachment => ({ ...attachment.file })) || [];
  }

  async handleSave() {
    try {
      const mutation = await this.zeusClient.chain('mutation')({
        insert_dealAttachments: [
          {
            objects: this.files.map(file => ({
              fileId: file.id,
              dealId: this.opportunity.id,
              id: this.attachments.find(attachment => attachment.fileId === file.id)?.id,
            })),
            on_conflict: {
              constraint: dealAttachments_constraint.dealAttachments_pkey,
              update_columns: [
                dealAttachments_update_column.dealId,
                dealAttachments_update_column.fileId,
              ]
            }
          },
          {
            affected_rows: true,
            returning: {
              id: true,
            }
          }
        ]
      });

      if (mutation?.insert_dealAttachments?.affected_rows === 0) throw new Error('Error saving data!')

      toastifySuccess({ message: 'Dados salvo com sucesso' });
    } catch (error) {
      console.error(error)

      toastifyError({ message: 'Erro ao salvar dados' });
    }
  }

  private async handleDeleteFile(id: string): Promise<void> {
    try {

      const mutation = await this.zeusClient.chain('mutation')({
        delete_dealAttachments_by_pk: [
          {
            id: this.attachments.find(attachment => attachment.fileId === id)?.id,
          },
          {
            id: true,
          }
        ],

        deleteFile: [
          {
            id,
          },
          {
            id: true,
          }
        ]
      });

      if (!mutation?.delete_dealAttachments_by_pk?.id || !mutation?.deleteFile?.id) throw new Error('Error deleting file!')

      toastifySuccess({ message: 'Arquivo deletado com sucesso' });
    } catch (error) {
      console.error(error);

      toastifyError({ message: 'Erro ao deletar arquivo' });
    }
  }
}