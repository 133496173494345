import { inject } from "aurelia";
import { IEventAggregator } from 'aurelia';
import { interactionChannel } from '@data/index';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { ICustomElementController } from '@aurelia/runtime-html';
import { IValidationController } from '@aurelia/validation-html';
import { toastifySuccess, toastifyError } from '@helpers/toastify';
import { IInteractionsService } from '../services/interactions-service';
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';

@inject(IEventAggregator, IDialogDom, IInteractionsService, IValidationRules, newInstanceForScope(IValidationController))
export class InteractionDialog implements IDialogCustomElementViewModel {
  private date: string;
  private channel: string;
  private observation: string;
  private opportunityId: string;
  private success: boolean = false;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private channelData = interactionChannel;

  constructor(
    readonly ea: IEventAggregator,
    private dialogDom: DefaultDialogDom,
    private service: IInteractionsService,
    private validationRules: IValidationRules,
    private validationController: IValidationController,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  private closeAndSubmit() {
    this.$dialog.ok();
  }

  async activate(model?: { opportunityId: string }) {
    this.opportunityId = model.opportunityId;
  }

  private async handleSave() {
    try {
      const response = await this.service.createInteraction({
        date: new Date(this.date),
        channel: this.channel,
        success: this.success,
        observation: this.observation,
        dealId: this.opportunityId,
      });

      if (!response) throw new Error('Erro ao salvar dados');

      this.closeAndSubmit();

      toastifySuccess({ message: 'Dados salvo com sucesso' });
    } catch (error) {
      if (error instanceof Error) {
        toastifyError({ message: error.message });
      } else {
        toastifyError({ message: 'Algo deu errado, tente novamente mais tarde' });
      }
    }
  }
}