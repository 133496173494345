import { inject } from "aurelia";
import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IInteractionsService {
  getChannelById(channelId: string): string;

  getInteractions(): Promise<ModelTypes["dealInteraction"][]>;
  createInteraction(command: ModelTypes["dealInteraction_insert_input"]): Promise<ModelTypes["dealInteraction"]>;
}

@inject(IZeusClient)
class InteractionsService implements IInteractionsService {

  constructor(private zeusClient: ZeusClient) { }

  async getInteractions(): Promise<ModelTypes["dealInteraction"][]> {
    const result = await this.zeusClient.chain('query')({
      dealInteraction: [
        {},
        {
          id: true,
          success: true,
          channel: true,
          observation: true,
          date: true,
          created_at: true,
        },
      ]
    });

    return result.dealInteraction as ModelTypes["dealInteraction"][];
  }

  async createInteraction(command: ModelTypes["dealInteraction_insert_input"]): Promise<ModelTypes["dealInteraction"]> {
    const result = await this.zeusClient.chain('mutation')({
      insert_dealInteraction_one: [
        {
          object: command,
        },
        {
          id: true,
        }
      ]
    });

    return result.insert_dealInteraction_one as ModelTypes["dealInteraction"];
  }

  getChannelById(channelId: string): string {
    switch (channelId) {
      case "f7d82e08-ddb0-4bd7-9465-95356a8f1257":
        return "Email";
      case "69f932d6-d249-4d68-84fc-6edc83d0c0f5":
        return "Ligação";
      case "366583b2-8b96-4d02-8c6c-42d61e99eb5b":
        return "WhatsApp";
      case "724a84b4-6960-45db-a2a9-5ffec9f66741":
        return "Outro";
      default:
        return "Unknown";
    }
  }

}

export const IInteractionsService = DI.createInterface<IInteractionsService>((x) => x.singleton(InteractionsService));
