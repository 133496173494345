import { CustomElement } from '@aurelia/runtime-html';
export const name = "header-component";
export const template = "<section>\n\n  <div class=\"flex justify-between items-center gap-2\">\n    <!-- <i class=\"fa-solid fa-address-card text-lg text-gray-900 dark:text-white\"></i> -->\n\n    <div class=\"flex flex-col\">\n      <h5 class=\"text-base font-bold text-gray-900 dark:text-white\">${title}</h5>\n      <h6 class=\"text-sm font-normal text-gray-700 dark:text-gray-400\">${subtitle}</h6>\n    </div>\n\n    <au-slot name=\"actions\"></au-slot>\n  </div>\n\n</section>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
