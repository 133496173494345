import { inject } from "aurelia";
import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

@valueConverter('monthYear')
@inject(I18N)
export class MonthYear {
  constructor(private I18N: I18N) { }

  toView(date) {
    return format(date, 'MMMM yyyy', { locale: ptBR });
  }
}