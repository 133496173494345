import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/undraw/undraw-component";
import * as d1 from "@components/card-header/card-header-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
export const name = "add-card-dialog";
export const template = "\n\n\n\n<div class=\"bg-white rounded-lg shadow dark:bg-gray-700 min-h-[30vh] h-full w-[30vw]\">\n\n  <!-- begin::header -->\n  <div class=\"flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600\">\n\n    <div class=\"flex flex-col\">\n      <h3 class=\"text-xl font-semibold text-gray-900 dark:text-white\">Adicionar Cartão</h3>\n      <!-- <p class=\"text-sm font-normal text-gray-700 dark:text-gray-400\">...</p> -->\n    </div>\n\n    <button type=\"button\" click.trigger=\"$dialog.cancel()\"\n      class=\"text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white\"\n      data-modal-hide=\"defaultModal\">\n      <svg class=\"w-3 h-3\" aria-hidden=\"true\" xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 14 14\">\n        <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"\n          d=\"m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6\" />\n      </svg>\n      <span class=\"sr-only\">Close modal</span>\n    </button>\n  </div>\n  <!-- end::header -->\n\n  <!-- begin::body -->\n  <div class=\"flex flex-col gap-4 p-4\">\n    <div id=\"paymentElement\" ref=\"paymentElement\"></div>\n\n\n    <!-- begin::footer -->\n    <div class=\"flex items-center p-4 justify-end space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600\">\n\n      <button type=\"button\" click.trigger=\"$dialog.cancel()\" class=\"btn-primary\">Fechar</button>\n      <spinner-button-component label=\"Salvar\" btn-class=\"btn-success\"\n        trigger-function.bind=\"() => handleAddCard()\"></spinner-button-component>\n    </div>\n    <!-- end::footer -->\n\n  </div>";
export default template;
export const dependencies = [ d0, d1, d2 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
