/* eslint-disable @typescript-eslint/no-explicit-any */
import { nhost } from '@lib/nhost';
import { IEventAggregator, inject } from "aurelia";
import { AuthChangeEvent, NhostSession, User } from '@nhost/nhost-js';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { GoogleAnalyticsHelper, GoogleAnalyticsUserPlan } from '@helpers/google-analytics/google-analytics-helper';

@inject(IEventAggregator)
export class AuthService implements IAuthService {
  user: User | null = null;
  userRole: string | string[] = null;

  constructor(private EA: IEventAggregator) {
    nhost.auth.onAuthStateChanged((event: AuthChangeEvent, session: NhostSession) => {
      if (session && session.user) {
        this.user = session.user;
        this.userRole = session.user.defaultRole

        GoogleAnalyticsHelper.setUserId(session.user.id);
        const plan = session.user.defaultRole === 'user' ? GoogleAnalyticsUserPlan.Free : (session.user.defaultRole === 'user-premium' ? GoogleAnalyticsUserPlan.Premium : GoogleAnalyticsUserPlan.FreeTrial);
        GoogleAnalyticsHelper.setUserPlan(plan);
      } else {
        this.user = null;
      }
    })
  }

  async signUp({ email, password, metadata }: { email: string, password: string, metadata: any }): Promise<void> {
    try {

      const metadataToSend = {
        ...metadata,
        ...(this.getCookieValue('cpgclid') && { cpgclid: this.getCookieValue('cpgclid') }),
      }


      await nhost.auth.signUp({
        email,
        password,
        options: {
          ...metadataToSend && { metadata: metadataToSend }
        }
      });

    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async signIn({ email, password }: { email: string, password: string }): Promise<void> {
    try {
      const { error } = await nhost.auth.signIn({ email, password })

      if (error) {
        await nhost.auth.sendVerificationEmail({ email })

        throw new Error(error.message)
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }
  /**
   * Retrieves the value of a cookie by name.
   * @param name - The name of the cookie.
   * @returns The cookie value if found, otherwise null.
   */
  getCookieValue(name: string): string | null {
    // Create the pattern to search for the cookie.
    const nameEQ = `${name}=`;
    // Split document.cookie on semicolons to get individual cookie strings.
    const cookies = document.cookie.split(';');

    // Iterate over the cookie array.
    for (let cookie of cookies) {
      // Trim leading whitespace.
      cookie = cookie.trim();

      // Check if the cookie starts with the desired cookie name.
      if (cookie.startsWith(nameEQ)) {
        // Return the cookie value (everything after the cookie name and equals sign).
        return cookie.substring(nameEQ.length);
      }
    }
    // Return null if the cookie was not found.
    return null;
  }
  async signInOTP({ email, metadata }: { email: string, metadata?: any }): Promise<boolean> {
    try {
      const metadataToSend = {
        ...metadata,
        ...(this.getCookieValue('cpgclid') && { cpgclid: this.getCookieValue('cpgclid') }),
      }
      const { error } = await nhost.auth.signInEmailOTP(email, {
        ...metadataToSend && { metadata: metadataToSend }
      })

      if (error) throw new Error(error.message)

      return true
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async verifyEmailOTP({ email, otp }: { email: string, otp: string }): Promise<NhostSession | null> {
    try {
      const { session, error } = await nhost.auth.verifyEmailOTP(email, otp)

      if (error) throw new Error(error.message)

      return session
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async signInForGoogle(metadata: any): Promise<void> {
    try {

      const metadataToSend = {
        ...metadata,
        ...this.getCookieValue('cpgclid') && { cpgclid: this.getCookieValue('cpgclid') }
      };

      await nhost.auth.signIn({
        provider: 'google',
        options: {
          ...metadataToSend && { metadata: metadataToSend }
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async signOut(): Promise<void> {
    try {
      await nhost.auth.signOut()

    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async resetPassword({ email }: { email: string }): Promise<void> {
    try {
      await nhost.auth.resetPassword({
        email,
        options: {
          redirectTo: "/my-account"
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async changePassword(newPassword: string): Promise<void> {
    try {
      await nhost.auth.changePassword({ newPassword })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  getAccessToken(): string {
    const accessToken = nhost.auth.getAccessToken()
    return accessToken
  }

  getUser(): User {
    const user = nhost.auth.getUser()
    return user;
  }


}