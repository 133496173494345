import { CustomElement } from '@aurelia/runtime-html';
export const name = "media-component";
export const template = "<div class=\"p-4 rounded-lg bg-gray-50 dark:bg-gray-800\" id=\"media\" role=\"tabpanel\" aria-labelledby=\"media-tab\">\n\n  <div class=\"relative\">\n    <div class=\"grid grid-cols-2 gap-2 mt-3 h-96 overflow-y-auto scrollbar scrollbar-h-1 scrollbar-w-1.5 scrollbar-thumb-rounded-md pr-1\">\n      <div repeat.for=\"item of userMedia\" class=\"grow shrink-0 basis-0\">\n        <div class=\"${selectedImageId == item.id ? 'border-4 border-orange-500 bg-orange-100' : 'border border-gray-200 dark:border-gray-200'} cursor-pointer items-center flex flex-col justify-center hover:bg-blue-50 focus:ring-4 focus:ring-blue-300 rounded-lg p-1\" click.trigger=\"selectMedia(item.id)\">\n          <img src=\"${item.url}\" class=\"w-full rounded-lg overflow-hidden\">\n        </div>\n      </div>\n    </div>\n  </div>\n\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
