import { inject } from "aurelia";
import { IRouter } from '@aurelia/router';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient, IRouter)
export class FacebookSuccessPage {
  async loading(parameters: { code: any, state: any }) {
    if (parameters) {
      const { code, state } = parameters;

      await this.handleFacebookTokenCallback(code, state);
    }
  }

  constructor(
    private zeusClient: ZeusClient,
    private router: IRouter
  ) { }

  private async handleFacebookTokenCallback(code: string, brandId: string) {
    await this.zeusClient.facebookAuthCallback(code, brandId);
  }
}