/* eslint-disable @typescript-eslint/no-explicit-any */
import { DI } from "aurelia";
import { NhostSession, User } from '@nhost/nhost-js';
import { AuthService } from '@services/auth-service/auth-service';
export interface IAuthService {
  user: User;
  signUp({ email, password, metadata }: { email: string, password: string, metadata: any }): Promise<void>
  signIn({ email, password }: { email: string, password: string }): Promise<void>
  signInForGoogle(metadata: any): Promise<void>
  signOut(): Promise<void>;
  resetPassword({ email }: { email: string }): Promise<void>
  getAccessToken(): string;
  getUser(): User;
  changePassword(newPassword: string): Promise<void>
  signInOTP({ email, metadata }: { email: string, metadata?: any }): Promise<boolean>
  verifyEmailOTP({ email, otp }: { email: string, otp: string }): Promise<NhostSession | null>
}

export const IAuthService = DI.createInterface<IAuthService>(x => x.singleton(AuthService));