import { inject } from "aurelia";
import { IRouter } from "@aurelia/router";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IRouter, IZeusClient)
export class WordpressSuccessPage {
  finalizing: boolean = true;
  isSuccess: boolean = false;

  constructor(
    private router: IRouter,
    private zeusClient: ZeusClient
  ) { }

  async load(parameters) {
    if (parameters) {

      const { brandId } = parameters;

      if (parameters.success == 'false') {
        this.isSuccess = false;
      } else if (parameters.error) {
        this.isSuccess = false;
      } else {
        this.isSuccess = true;

        try {
          await this.zeusClient.insertUserAppIntegration('wordpress', brandId, parameters);
          this.finalizing = false

        } catch (error) {
          console.log(parameters)
        }
      }
    }
  }
}