import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/promise-bind/pending/pending-component";
export const name = "product-thumbnail-image-component";
export const template = "\n\n<section>\n  <tenplate promise.bind=\"getThumbnailURL(product)\">\n\n    <template pending>\n      <pending-component></pending-component>\n    </template>\n\n    <template then.from-view=\"data\">\n\n      <div class=\"relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-700 border\">\n        <template if.bind=\"data\">\n          <img class=\"w-full h-full\" src=\"${data}\" alt=\"logo\" />\n        </template>\n        <template else>\n          <i class=\"fas fa-question text-sm text-gray-600 dark:text-gray-300\"></i>\n        </template>\n      </div>\n\n    </template>\n\n    <template catch.from-view=\"error\">\n      <div class=\"relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-700 border\">\n        <i class=\"fas fa-question text-sm text-gray-600 dark:text-gray-300\"></i>\n      </div>\n    </template>\n\n  </tenplate>\n</section>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
