import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { ITemplateService } from '../../services/templates-service';
import { IRouter } from '@aurelia/router';

@inject(IRouter,ITemplateService)
export class TextTemplatesPage {
  private channel: string = 'texts';

  constructor(    private router: IRouter,    private service: ITemplateService  ) { }

  private async getNewTemplateType(channel: string): Promise<ModelTypes['newTemplateType'][]> {
    const result = await this.service.getNewTemplateType(channel);

    const filteredResult = result.filter((item) => item.id !== 'fbb9b8d8-07e8-4fa3-b115-f3c4f69d9816' && item.id !== '8af49843-7de1-4538-8830-f32144a716d0');

    return filteredResult;
  }

  private async handleRedirect(): Promise<void> {
    await this.router.load('/text');
  }
}