import { CustomElement } from '@aurelia/runtime-html';
import "./calendar-component-widget.css";
import * as d1 from "./value-converters/month-year.ts";
import * as d2 from "./value-converters/date-to-iso-day.ts";
import * as d3 from "./value-converters/day-of-month.ts";
import * as d4 from "./value-converters/same-month-class.ts";
import * as d5 from "./value-converters/same-day-class.ts";
import * as d6 from "./value-converters/date-to-full-date-format.ts";
export const name = "calendar-component-widget";
export const template = "\n\n\n\n\n\n\n<div ref=\"calendarHtmlElement\"></div>";
export default template;
export const dependencies = [ d1, d2, d3, d4, d5, d6 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
