import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { IRouter } from "@aurelia/router";
import { newInstanceForScope } from '@aurelia/kernel';
import { ICustomElementController } from "@aurelia/runtime-html";
import { IValidationController } from '@aurelia/validation-html';
import { IValidationRules, ValidateInstruction } from '@aurelia/validation';
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { ICreateProjectService, SizeObject } from './services/create-project-service';
import { GoPremiumDialog } from "@components/calendar/components/go-premium-dialog/go-premium-dialog";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom, IDialogService } from '@aurelia/dialog';
import { GoogleAnalyticsEventAction, GoogleAnalyticsEventCategory, GoogleAnalyticsHelper } from "@helpers/google-analytics/google-analytics-helper";

@inject(IDialogDom, IValidationRules, ICreateProjectService, IRouter, newInstanceForScope(IValidationController), IDialogService, IAuthService)
export class CreateProjectDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private selectedBrandId: string;
  private brandsData: ModelTypes["brands"][] = [];

  private selectedAudienceId: string;
  private audiencesData: ModelTypes["audiences"][] = []

  private selectedProductId: string;
  private productsData: ModelTypes["products"][] = []

  private selectedDisplayDimensionId: string;
  private displayDimensionData: SizeObject[] = []

  private flexText: string;
  private selectedTemplateId: string;
  private selectedImage: string;
  private currentImageTabIndex: number = 0;

  private currentStepIndex: number = 0;
  private steps: { index: number, title: string }[] = [
    { index: 0, title: 'Informações' },
    { index: 1, title: 'Templates' },
    { index: 2, title: 'Gerar' },
  ]

  constructor(
    private dialogDom: DefaultDialogDom,
    private validationRules: IValidationRules,
    private service: ICreateProjectService,
    private router: IRouter,
    private validationController: IValidationController,
    private dialogService: IDialogService,
    private authService: IAuthService
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

    validationRules
      .on(this)
      .ensure('selectedBrandId')
      .required().withMessage('Selecione uma marca')

      .ensure('selectedProductId')
      .required().withMessage('Selecione um produto')

    validationRules
      .on(this, 'stepOne')
      .ensure('selectedBrandId')
      .required().withMessage('Selecione uma marca')

      .ensure('selectedProductId')
      .required().withMessage('Selecione um produto')

      .ensure('selectedDisplayDimensionId')
      .required().withMessage('Selecione um formato')

    validationRules
      .on(this, 'stepTwo')
      .ensure('selectedTemplateId')
      .required().withMessage('Selecione um template')
  }

  async activate() {
    this.getData();
  }

  private async getData(): Promise<void> {
    const [audiences, products, brands, formats] = await Promise.all([
      this.service.getAudiences(),
      this.service.getProducts(),
      this.service.getBrands(),
      this.service.getFormats()
    ]);

    this.audiencesData = audiences;
    this.productsData = products;
    this.brandsData = brands;
    this.displayDimensionData = formats;

    setTimeout(() => {
      if (this.brandsData.length > 0) {
        this.selectedBrandId = this.brandsData[0].id;
      }

      if (this.audiencesData.length > 0) {
        this.selectedAudienceId = this.audiencesData[0].id;
      }
    }, 1);
  }

  private async getAvailableDisplays(selectedDisplayDimensionId: string): Promise<ModelTypes["pTemplate"][]> {
    if (!selectedDisplayDimensionId) return;

    const dimmension = this.displayDimensionData.find(x => x.id === selectedDisplayDimensionId);

    const result = await this.service.getTemplates({ height: dimmension.height, width: dimmension.width });

    return result;
  }

  async close() {
    this.$dialog.ok();
  }

  async handleGenerateProject() {
    const validateResult = await this.validationController.validate();

    if (!validateResult.valid) return;
    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }
    // if (!this.selectedAudienceId) toastifyWarning({ message: "É necessário um público alvo." });

    await this.service.insertUserMedia(this.selectedImage);

    const result = await this.service.createNewProject(
      {
        brandId: this.selectedBrandId,
        productId: this.selectedProductId,
        audienceId: this.selectedAudienceId,
        templateId: this.selectedTemplateId,
        image: this.selectedImage,
        artDirectional: this.flexText,
      }
    )

    if (result) {
      GoogleAnalyticsHelper.trackEvent(GoogleAnalyticsEventAction.Click, GoogleAnalyticsEventCategory.Post, 'create-post');

      this.close();

      await this.router.load(`/editor/${result.projectId}`, {
        data: {
          brandId: this.selectedBrandId,
          productId: this.selectedProductId,
          audienceId: this.selectedAudienceId,
          prompt: this.flexText,
        }
      });
    }
  }

  private async advanceStep(): Promise<void> {
    if (this.currentStepIndex === 0) {
      const validate = await this.validationController.validate(new ValidateInstruction(this, undefined, undefined, 'stepOne'));

      if (validate.valid) {
        this.currentStepIndex++;
      }
    } else if (this.currentStepIndex === 1) {
      const validate = await this.validationController.validate(new ValidateInstruction(this, undefined, undefined, 'stepTwo'));

      if (validate.valid) {
        this.currentStepIndex++;
      }
    }
  }

  private returnStep() {
    this.currentStepIndex--;
  }
}