/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from "aurelia";
import { BindingMode, bindable } from 'aurelia';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient)
export class AiComponent {
  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;

  private query: string;
  private callTriggerAIImageSearch: boolean = false;

  private images: any[] = [];
  private selectedImageId: string;

  private isLoading: boolean = false;
  private error: string | null = null;

  constructor(private zeusClient: ZeusClient) { }

  async generateImageFromPrompt() {
    try {
      this.isLoading = true;
      this.callTriggerAIImageSearch = true;

      const result = await this.zeusClient.generateImageFromPrompt(this.query)

      this.selectedImageId = null;
      this.images = result.images;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      this.error = 'Não foi possivel gerar a imagem';
    } finally {
      this.isLoading = false;
    }
  }

  private selectImage(id: string) {
    this.selectedImageId = id;
    this.selectedImage = id;
    // this.selectedImage = this.images.find(i => i.id === id).url;
  }

  detaching() {
    this.callTriggerAIImageSearch = false;
    this.selectedImage = null;
    this.selectedImageId = null;
    this.images = [];
    this.query = null;
  }
}