import { CustomElement } from '@aurelia/runtime-html';
export const name = "radio-button-component";
export const template = "<div if.bind=\"validate\" validation-errors.from-view=\"errors\">\n  <div class=\"w-full flex flex-col\">\n    <label class=\"\">${label}</label>\n    <div class=\"flex gap-4 justify-center items-center flex-wrap\">\n\n      <label repeat.for=\"option of options\" for=\"${id + '-' + option.value}\"\n        class=\"w-full flex-1 justify-center items-center gap-4 px-4 py-2 border rounded-lg ${disabled === true ? 'cursor-not-allowed': 'cursor-pointer'} ${errors.length ? '!border-red-600' : ''} ${option.value === selectedValue ? 'bg-blue-700 text-white' : 'bg-white'}\">\n        <input ...$attrs disabled.bind=\"disabled\" checked.bind=\"selectedValue\" type=\"radio\"\n          id=\"${id + '-' + option.value}\" model.bind=\"option.value\"\n          class=\"${option.value === selectedValue ? 'ring-2 ring-white' : ''}\" />\n        ${option.label}\n      </label>\n\n    </div>\n  </div>\n\n  <span if.bind=\"errors.length > 0\" style=\"color:red; font-size: 10px;\">${errors[0].result.message}</span>\n</div>\n\n\n<div else class=\"w-full flex flex-col\">\n  <label class=\"\">${label}</label>\n  <div class=\"flex gap-4 justify-center items-center flex-wrap\">\n\n    <label repeat.for=\"option of options\" for=\"${id + '-' + option.value}\"\n      class=\"w-auto flex justify-center items-center gap-4 px-4 py-2 border rounded-3xl ${disabled === true ? 'cursor-not-allowed': 'cursor-pointer'} ${option.value === selectedValue ? 'bg-blue-700 text-white' : 'bg-white'}\">\n      <input ...$attrs disabled.bind=\"disabled\" checked.bind=\"selectedValue\" type=\"radio\"\n        id=\"${id + '-' + option.value}\" model.bind=\"option.value\"\n        class=\"${option.value === selectedValue ? 'ring-2 ring-white' : ''}\" />\n      ${option.label}\n    </label>\n\n  </div>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
