import { inject } from "aurelia";
import { IRouter } from '@aurelia/router';
import { IEventAggregator, bindable } from 'aurelia';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { categoryOfProduct, natureOfProduct } from '@data/index';
import { IValidationController } from '@aurelia/validation-html';
import { IProductsService } from '../services/products-service';
import { StorageUploadFileResponse } from '@nhost/nhost-js';
import { nhost } from '@lib/nhost';
import { IZeusClient, ZeusClient } from "@services/graphql-service/zeus-client";
import { IDialogService } from "@aurelia/dialog";
import { FullScreenSpinnerDialog } from "@components/full-screen-spinner-dialog/full-screen-spinner-dialog";
import { toastifyError, toastifySuccess } from "@helpers/toastify";
import { GoogleAnalyticsEventAction, GoogleAnalyticsEventCategory, GoogleAnalyticsHelper } from "@helpers/google-analytics/google-analytics-helper";
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { GoPremiumDialog } from "@components/calendar/components/go-premium-dialog/go-premium-dialog";
import { IConfirmAction } from '@components/confirm-action';

@inject(IRouter, IDialogService, IZeusClient, IEventAggregator, IProductsService, IValidationRules, newInstanceForScope(IValidationController), IAuthService, IConfirmAction)
export class ProductPage {

  @bindable imageOneFile: File;
  @bindable imageOneFileId: string;

  @bindable imageTwoFile: File;
  @bindable imageTwoFileId: string;

  @bindable imageThreeFile: File;
  @bindable imageThreeFileId: string;

  @bindable imageFourFile: File;
  @bindable imageFourFileId: string;

  private brandId: string;
  private productId: string;
  private type: string;
  private alias: string;
  private category: string;
  private linkConversion: string;
  private price: number;
  private salePrice: number;
  private wordKeys: string[] = [];
  private description: string;
  // private wordKeysTagify: Tagify;

  private characteristicOne: string;
  private characteristicOneDescription: string;
  private characteristicTwo: string;
  private characteristicTwoDescription: string;
  private characteristicThree: string;
  private characteristicThreeDescription: string;
  private differentialOne: string;
  private differentialOneDescription: string;
  private differentialTwo: string;
  private differentialTwoDescription: string;
  private differentialThree: string;
  private differentialThreeDescription: string;
  private persuasiveDescription: string;

  private slug: string;

  private natureOfProductData = natureOfProduct;
  private categoryOfProductData = categoryOfProduct;

  constructor(
    private router: IRouter,
    private dialogService: IDialogService,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private service: IProductsService,
    private validationRules: IValidationRules,
    private validationController: IValidationController,
    private authService: IAuthService,
    private confirmAction: IConfirmAction,
  ) {
    validationRules
      .on(this)
      .ensure('alias')
      .required().withMessage('Nome do produto é obrigatório')

      .ensure('type')
      .required().withMessage('Natureza do produto é obrigatório')

      .ensure('category')
      .required().withMessage('Categoria é obrigatório')
  }

  async loading(params: { id: string; brandId: string; }) {
    this.productId = params.id;
    this.brandId = params.brandId;

    if (this.productId) {
      await this.getProductById(this.productId);
    }
  }

  private async getProductById(id: string) {
    const product = await this.service.getProductById(id);

    this.type = product.type;
    this.alias = product.alias;
    this.slug = product.slug;
    this.category = product.category;
    this.linkConversion = product.linkConversion;
    this.price = product.price;
    this.salePrice = product.salePrice;
    this.wordKeys = product.wordKeys;
    this.description = product.description;
    this.imageOneFileId = product.imageOneId;
    this.imageTwoFileId = product.imageTwoId;
    this.imageThreeFileId = product.imageThreeId;
    this.imageFourFileId = product.imageFourId;

    this.characteristicOne = product.characteristicOne;
    this.characteristicOneDescription = product.characteristicOneDescription;
    this.characteristicTwo = product.characteristicTwo;
    this.characteristicTwoDescription = product.characteristicTwoDescription;
    this.characteristicThree = product.characteristicThree;
    this.characteristicThreeDescription = product.characteristicThreeDescription;
    this.differentialOne = product.differentialOne;
    this.differentialOneDescription = product.differentialOneDescription;
    this.differentialTwo = product.differentialTwo;
    this.differentialTwoDescription = product.differentialTwoDescription;
    this.differentialThree = product.differentialThree;
    this.differentialThreeDescription = product.differentialThreeDescription;
    this.persuasiveDescription = product.persuasiveDescription;
  }

  private async handleUpsertProduct() {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }

    await this.handleSaveProduct();
  }

  private async handleSaveProduct() {
    await this.confirmAction.open({
      type: "success",
      title: "Salvar a produto",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        try {
          let imageOneFileResponse: StorageUploadFileResponse | null = null;
          let imageTwoFileResponse: StorageUploadFileResponse | null = null;
          let imageThreeFileResponse: StorageUploadFileResponse | null = null;
          let imageFourFileResponse: StorageUploadFileResponse | null = null;

          if (this.imageOneFile) {
            imageOneFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageOneFile,
              ...this.imageOneFileId && { fileId: this.imageOneFileId }
            });
          }

          if (this.imageTwoFile) {
            imageTwoFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageTwoFile,
              ...this.imageTwoFileId && { fileId: this.imageTwoFileId }
            });
          }

          if (this.imageThreeFile) {
            imageThreeFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageThreeFile,
              ...this.imageThreeFileId && { fileId: this.imageThreeFileId }
            });
          }

          if (this.imageFourFile) {
            imageFourFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageFourFile,
              ...this.imageFourFileId && { fileId: this.imageFourFileId }
            });
          }
          const currentBrand = await this.service.getBrand();

          const mutation = await this.service.upsertProduct({
            alias: this.alias,
            type: this.type,
            category: this.category,
            price: this.price ? this.price : null,
            salePrice: this.salePrice ? this.salePrice : null,
            description: this.description,
            brandId: currentBrand.id,
            linkConversion: this.linkConversion,
            imageOneId: imageOneFileResponse ? imageOneFileResponse.fileMetadata.id : this.imageOneFileId,
            imageTwoId: imageTwoFileResponse ? imageTwoFileResponse.fileMetadata.id : this.imageTwoFileId,
            imageThreeId: imageThreeFileResponse ? imageThreeFileResponse.fileMetadata.id : this.imageThreeFileId,
            imageFourId: imageFourFileResponse ? imageFourFileResponse.fileMetadata.id : this.imageFourFileId,
            characteristicOne: this.characteristicOne,
            characteristicOneDescription: this.characteristicOneDescription,
            characteristicTwo: this.characteristicTwo,
            characteristicTwoDescription: this.characteristicTwoDescription,
            characteristicThree: this.characteristicThree,
            characteristicThreeDescription: this.characteristicThreeDescription,
            differentialOne: this.differentialOne,
            differentialOneDescription: this.differentialOneDescription,
            differentialTwo: this.differentialTwo,
            differentialTwoDescription: this.differentialTwoDescription,
            differentialThree: this.differentialThree,
            differentialThreeDescription: this.differentialThreeDescription,
            persuasiveDescription: this.persuasiveDescription,
            slug: this.slug,
          });

          if (!mutation?.id) throw new Error('Erro ao salvar produto');

          GoogleAnalyticsHelper.trackEvent(GoogleAnalyticsEventAction.Click, GoogleAnalyticsEventCategory.Product, 'create product');

          toastifySuccess({ message: 'Produto salvo com sucesso' });

          if (!this.productId) this.router.load(`/product/${mutation.id}`);

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          toastifyError({ message: 'Erro ao salvar produto' });
        }
      }
    });
  }

  async fillUsingAI() {
    try {
      if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
        await this.dialogService.open({
          component: () => GoPremiumDialog,
          model: {},
          lock: true,
          startingZIndex: 100,
        });

        return
      }

      const spinnerDialog = await this.dialogService.open({
        component: () => FullScreenSpinnerDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      const result = await this.zeusClient.chain('query')({
        generateProductDataWithAi: [
          {
            category: this.category,
            productName: this.alias,
          },
          {
            description: true,
            persuasiveDescription: true,
            characteristicOne: true,
            characteristicOneDescription: true,
            characteristicTwo: true,
            characteristicTwoDescription: true,
            characteristicThree: true,
            characteristicThreeDescription: true,
            differentialOne: true,
            differentialOneDescription: true,
            differentialTwo: true,
            differentialTwoDescription: true,
            differentialThree: true,
            differentialThreeDescription: true,
          }
        ]
      });

      if (result.generateProductDataWithAi) {
        this.description = result.generateProductDataWithAi.description;
        this.persuasiveDescription = result.generateProductDataWithAi.persuasiveDescription;
        this.characteristicOne = result.generateProductDataWithAi.characteristicOne;
        this.characteristicOneDescription = result.generateProductDataWithAi.characteristicOneDescription;
        this.characteristicTwo = result.generateProductDataWithAi.characteristicTwo;
        this.characteristicTwoDescription = result.generateProductDataWithAi.characteristicTwoDescription;
        this.characteristicThree = result.generateProductDataWithAi.characteristicThree;
        this.characteristicThreeDescription = result.generateProductDataWithAi.characteristicThreeDescription;
        this.differentialOne = result.generateProductDataWithAi.differentialOne;
        this.differentialOneDescription = result.generateProductDataWithAi.differentialOneDescription;
        this.differentialTwo = result.generateProductDataWithAi.differentialTwo;
        this.differentialTwoDescription = result.generateProductDataWithAi.differentialTwoDescription;
        this.differentialThree = result.generateProductDataWithAi.differentialThree;
        this.differentialThreeDescription = result.generateProductDataWithAi.differentialThreeDescription;

        toastifySuccess({ message: 'Dados preenchidos com sucesso' });
        spinnerDialog.dialog.cancel();
      } else {
        toastifyError({ message: 'Erro ao preencher dados' });
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      toastifyError({ message: 'Erro ao preencher dados' });
    }
  }
}