import { inject } from "aurelia";
import { ModelTypes } from "@zeus";
import { ICustomElementController } from "@aurelia/runtime-html";
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IZeusClient, ZeusClient } from "@services/graphql-service/zeus-client";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom, IDialogService } from '@aurelia/dialog';
import { FullScreenSpinnerDialog } from "@components/full-screen-spinner-dialog/full-screen-spinner-dialog";

@inject(IDialogDom, IZeusClient, IDialogService)
export class EditCaptionDialog implements IDialogCustomElementViewModel {
  private project: ModelTypes['project'];
  private artDirectional: string;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom,
    private zeusClient: ZeusClient,
    private dialogService: IDialogService,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model: { project: ModelTypes['project'] }) {
    this.project = model.project;
  }

  async close() {
    this.$dialog.ok();
  }

  private async handleGenerateCaptionProject(): Promise<void> {
    const spinnerDialog = await this.dialogService.open({
      component: () => FullScreenSpinnerDialog,
      model: {},
      lock: true,
      startingZIndex: 100,
    });

    try {


      const response = await this.zeusClient.chain('mutation')({
        generateCaptionProject: [
          {
            brandId: this.project.brandId,
            productId: this.project.productId,
            artDirectional: this.artDirectional,
          },
          {
            caption: true
          }
        ]
      });

      if (!response?.generateCaptionProject?.caption) {
        toastifyError({ message: 'Erro ao gerar legenda' })

        return;
      }

      this.project.caption = response.generateCaptionProject.caption;

      toastifySuccess({ message: 'Legenda gerada com sucesso' })
    } catch (error) {
      toastifyError({ message: 'Erro ao gerar legenda' })
    } finally {
      spinnerDialog.dialog.ok();
    }
  }

  private async handleSaveTemplate(): Promise<void> {
    const response = await this.zeusClient.chain('mutation')({
      update_project_by_pk: [
        {
          pk_columns: { id: this.project.id },
          _set: { caption: this.project.caption }
        },
        {
          id: true
        }
      ]
    });

    if (!response?.update_project_by_pk?.id) {
      toastifyError({ message: 'Error saving caption' })

      return;
    }

    toastifySuccess({ message: 'Caption saved successfully' })

    this.$dialog.ok();
  }
}