import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/promise-bind/pending/pending-component";
export const name = "full-screen-spinner-dialog";
export const template = "\n\n\n<div class=\"h-full w-full flex justify-center items-center bg-white rounded-lg border-blue-500\"\n  style=\"border-width: thick;\">\n  <div class=\"flex flex-col justify-center items-center p-4 mb-5\">\n    <div class=\"flex flex-row items-center justify-center\">\n      <span t=\"undraw.spinnerinstagram\" class=\"text-sm font-semibold text-blue-500 dark:text-blue-500 p-4 my-2\">Em\n        desenvolvimento, isso pode levar alguns segundos</span>\n    </div>\n    <div class=\"relative flex justify-center items-center m-5\">\n      <div class=\"absolute animate-spin rounded-full h-40 w-40 border-t-4 border-b-4 border-blue-500\"></div>\n      <img src=\"assets/images/undraw-displays/wizard-cartoon-illustration.jpg\" class=\"rounded-full w-36 w-36\">\n    </div>\n  </div>\n</div>\n\n\n\n\n\n<!-- <div class=\"h-full w-full flex  flex-col items-center justify-center\">\n  <pending-component></pending-component>\n\n</div> -->";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
