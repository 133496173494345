import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { bindable } from 'aurelia';
import Toastify from 'toastify-js';
import { watch } from '@aurelia/runtime-html';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient)
export class InformationSection {
  @bindable website: ModelTypes['website']

  constructor(
    private zeusClient: ZeusClient,
  ) { }

  @watch('website')
  async handleDealSubscription(newData: ModelTypes['website'], oldData: ModelTypes['website']) {
    if (newData && newData !== oldData) {
      this.website = newData;
    }
  }

  async handleSave() {
    try {
      const mutation = await this.zeusClient.chain('mutation')({
        update_website_by_pk: [
          {
            pk_columns: { id: this.website.id },
            _set: {
              domain: this.website.domain,
              openGraphDescription: this.website.openGraphDescription,
              googleAnalyticsKey: this.website.googleAnalyticsKey,
              googleTagManagerKey: this.website.googleTagManagerKey
            }
          },
          {
            id: true,
          }
        ],

        update_brands_by_pk: [
          {
            pk_columns: { id: this.website.brandId },
            _set: {
              backgroundColor: this.website.brand.backgroundColor,
              foregroundColor: this.website.brand.foregroundColor,
              accentColor: this.website.brand.accentColor,
            }
          },
          {
            id: true,
          }
        ]
      })

      if (!mutation?.update_website_by_pk?.id) {
        throw new Error('Error saving data!')
      }

      Toastify({ text: 'Salvo com sucesso!', duration: 3000, gravity: 'top', position: 'right', backgroundColor: '#4CAF50', stopOnFocus: true }).showToast();
    } catch (error) {
      console.error(error)

      Toastify({ text: 'Erro ao salvar!', duration: 3000, gravity: 'top', position: 'right', backgroundColor: '#F44336', stopOnFocus: true }).showToast();
    }
  }
}