import { CustomElement } from '@aurelia/runtime-html';
export const name = "image-carousel-component";
export const template = "<div class=\"flex flex-col gap-1.5\">\n  <span class=\"text-base font-bold text-gray-900 dark:text-white\">Imagens do Produto</span>\n\n  <div class=\"swiper relative w-full h-[180px] flex flex-row gap-4 overflow-clip rounded-xl bg-gray-100 dark:bg-gray-700 border\">\n\n    <div class=\"swiper-wrapper\">\n      <template repeat.for=\"item of files\">\n        <div class=\"swiper-slide relative bg-black\">\n          <img src=\"${item.linkImage}\" alt=\"${item.alias}\" class=\"w-full h-full brightness-50 md:brightness-100 opacity-60\">\n        </div>\n      </template>\n    </div>\n\n    <div class=\"absolute bottom-4 right-4 hidden md:flex flex-row gap-4\">\n      <div class=\"swiper-button-prev-custom p-3 rounded-full bg-slate-200 hover:bg-slate-300 cursor-pointer transition-colors duration-500 z-50\">\n        <svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"size-3\">\n          <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M15.75 19.5 8.25 12l7.5-7.5\" />\n        </svg>\n      </div>\n\n      <div class=\"swiper-button-next-custom p-3 rounded-full bg-slate-200 hover:bg-slate-300 cursor-pointer transition-colors duration-500 z-50\">\n        <svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"size-3\">\n          <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m8.25 4.5 7.5 7.5-7.5 7.5\" />\n        </svg>\n      </div>\n    </div>\n\n  </div>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
