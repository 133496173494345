import { ModelTypes, $ } from '@zeus';
import { createEditor } from './editor.js';
import { IDialogService } from '@aurelia/dialog';
import { IDisposable, IEventAggregator, inject } from 'aurelia';
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { EditTemplateDialog } from '../../components/edit-template/edit-template-dialog';

@inject(IAuthService, IEventAggregator, IZeusClient, IDialogService)
export class TemplateEditorPage {
  private id: string;
  private template: ModelTypes['pTemplate'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private store: any = null;
  private colorThemeSubscription: IDisposable;

  constructor(
    private auth: IAuthService,
    private ea: IEventAggregator,
    private zeusClient: ZeusClient,
    private dialogService: IDialogService,
  ) { }

  loading(parameters: { id: string; }) {
    this.id = parameters.id;
  }

  attached() {
    this.handleColorTheme();
    this.handleCreateEditor();
    this.handleLoadGetProject();
  }

  private async handleLoadGetProject(): Promise<void> {
    if (!this.id) return;

    const query = await this.zeusClient.chain('query')({
      pTemplate_by_pk: [
        { id: this.id },
        {
          id: true,
          category: true,
          visibility: true,
          previewFileId: true,
          deletedAt: true,
          titleMaxLength: true,
          subtitleMaxLength: true,
          descriptionMaxLength: true,
          json: [{ path: '$' }, true],
          tagScores: [{ path: '$' }, true],
        }
      ]
    });

    if (!query?.pTemplate_by_pk?.id) return;

    this.template = query.pTemplate_by_pk as ModelTypes['pTemplate'];

    if (typeof this.template.json === 'string') {
      this.store.loadJSON(JSON.parse(this.template.json));
    } else {
      this.store.loadJSON(this.template.json);
    }

  }

  private handleCreateEditor(): void {
    if (this.store) return;

    // this.handleSetTranslations();

    const user = this.auth.getUser();

    this.store = createEditor({
      showCredit: true,
      key: 'ZRySQwqaiM3YAu3mXiY_',
      width: "1080",
      height: "1080",
      userId: user.id,
      token: this.auth.getAccessToken(),
      container: document.getElementById('container'),
    });
  }

  private handleColorTheme(): void {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    const div = document.querySelector("#polotno");

    if (colorTheme === 'light') {
      div.classList.remove('bp5-dark');
    } else {
      div.classList.add('bp5-dark');
    }

    this.colorThemeSubscription = this.ea.subscribe('color-theme', (colorTheme: { theme: string }) => {
      if (colorTheme.theme === 'light') {
        div.classList.remove('bp5-dark');
      } else {
        div.classList.add('bp5-dark');
      }
    });
  }

  private async handleSave(): Promise<void> {
    const storeJson = this.store.toJSON();

    try {
      const mutation = await this.zeusClient.chain('mutation')({
        update_pTemplate_by_pk: [
          {
            pk_columns: { id: this.template.id },
            _set: {
              category: this.template.category,
              visibility: this.template.visibility,
              previewFileId: this.template.previewFileId,
              titleMaxLength: this.template.titleMaxLength,
              subtitleMaxLength: this.template.subtitleMaxLength,
              descriptionMaxLength: this.template.descriptionMaxLength,
              json: $('json', 'jsonb')
            }
          },
          {
            id: true,
            category: true,
            visibility: true,
            previewFileId: true,
            deletedAt: true,
            titleMaxLength: true,
            subtitleMaxLength: true,
            descriptionMaxLength: true,
          }
        ]
      }, {
        variables: { json: storeJson }
      });

      if (!mutation.update_pTemplate_by_pk) throw new Error('Failed to save template');

      toastifySuccess({ message: 'Template salvo com sucesso' });
    } catch (error) {
      console.error(error);

      toastifyError({ message: 'Erro ao salvar template' });
    }
  }

  private async handleOpenEdtiTemplateDialog(): Promise<void> {
    const storeJson = this.store.toJSON();

    if (typeof this.template === 'undefined') {
      this.template = {} as ModelTypes['pTemplate'];
    }

    this.template.json = storeJson;

    const { dialog } = await this.dialogService.open({
      component: () => EditTemplateDialog,
      model: { template: this.template },
      lock: true,
      startingZIndex: 100,
    });

    const result = await dialog.closed;

    if (result.status === 'ok') {
      const value = result.value as boolean;

      if (value) {
        // await this.loadData();
      }
    }
  }

  detaching() {
    // this.localeSubscription.dispose();
    this.colorThemeSubscription?.dispose();

    this.store.clear({ keepHistory: true });
    this.store.addPage();
    this.store.setSize(1080, 1080);
  }
}