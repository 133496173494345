import { inject } from "aurelia";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { $, GraphQLVariableType, cursor_ordering, order_by } from '@zeus';
import { bindable } from 'aurelia';

export interface IGenericKanbanConfig {
  headerTitle: string;
  headerSubtitle: string;
  statusTableName: string;
  tableName: string;
  streamName: string;
  columns: string[];
  aggregateName: string;
  data: any[];
  filters: IGenericKanbanFilter[];
  fixedFilters?: IGenericKanbanFilter[];
  fixedColumnFilters?: IGenericKanbanFilter[];
  perPage: number;
  currentPage?: number;
}

export interface IGenericKanbanFilter {
  title: string;
  field: string;
  value: any;
  comparsionType: GenericKanbanComparsionType;
  options?: any[];
  type: GenericKanbanFildInputType;
  between?: {
    title: string;
    value: any;
    comparsionType: GenericKanbanComparsionType;
  }[]
}

export enum GenericKanbanComparsionType {
  Equals = 'equals',
  In = 'in',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  GreaterThanEquals = 'greaterThanEquals',
  LessThanEquals = 'lessThanEquals',
  Like = 'like',
  ILike = 'ilike',
  Between = 'between',
}

export enum GenericKanbanFildInputType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Checkbox = 'checkbox',
  Typeahead = 'typeahead',
}

interface IPagination {
  statusId: string;
  total: number;
  perPage: number;
  currentPage: number;
  loading: boolean;
}

type Structure = {
  [key: string]: Structure | boolean | Array<Structure>;
};

@inject(IZeusClient)
export class GenericKanbanComponent {
  @bindable config: IGenericKanbanConfig;

  private pagination: IPagination[] = [];
  private isLoading: boolean = false;

  constructor(private zeusClient: ZeusClient) { }

  async attached() {
    this.loadData(this.config);

    this.streamData();
  }

  private filterColumnsChanged() {
    const fixedColumnFilters = this.config.fixedColumnFilters?.filter(x => x.value).reduce((acc, filter) => {

      switch (filter.comparsionType) {
        case GenericKanbanComparsionType.Equals:
          acc[filter.field] = { _eq: filter.value };
          break;
        case GenericKanbanComparsionType.In:
          acc[filter.field] = { ...filter.value.length > 0 ? { _in: filter.value } : {} };
          break;
        case GenericKanbanComparsionType.GreaterThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _gt: filter.value };
          break;
        case GenericKanbanComparsionType.LessThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lt: filter.value };
          break;
        case GenericKanbanComparsionType.GreaterThanEquals:

          acc[filter.field] = { ...(acc[filter.field] || {}), _gte: filter.value };
          break;
        case GenericKanbanComparsionType.LessThanEquals:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lte: filter.value };
          break;
        case GenericKanbanComparsionType.Like:
          acc[filter.field] = { _like: `%${filter.value}%` };
          break;
        case GenericKanbanComparsionType.ILike:
          acc[filter.field] = { _ilike: `%${filter.value}%` };
          break;
        case GenericKanbanComparsionType.Between:
          const [min, max] = filter.between;

          acc[filter.field] = { ... (acc[filter.field] || {}), ...(min?.value !== null && typeof min?.value !== undefined && min?.value !== '') && { _gte: min.value }, ...(max?.value !== null && typeof max?.value !== undefined && max?.value !== '') && { _lte: max.value } };
          break;
        default:
          break;
      }

      return acc;
    }, {});

    return fixedColumnFilters;
  }

  private filterChanged() {
    const fixedFilters = this.config.fixedFilters?.filter(x => x.value).reduce((acc, filter) => {

      const fieldParts = filter.field.split('.'); // Dividir o campo por pontos

      switch (filter.comparsionType) {
        case GenericKanbanComparsionType.Equals:
          if (fieldParts.length > 1) {
            acc[fieldParts[0]] = { ...acc[fieldParts[0]], [fieldParts[1]]: { _eq: filter.value } };
          } else {
            acc[filter.field] = { _eq: filter.value };
          }

          break;
        case GenericKanbanComparsionType.In:
          acc[filter.field] = { ...filter.value.length > 0 ? { _in: filter.value } : {} };
          break;
        case GenericKanbanComparsionType.GreaterThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _gt: filter.value };
          break;
        case GenericKanbanComparsionType.LessThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lt: filter.value };
          break;
        case GenericKanbanComparsionType.GreaterThanEquals:

          acc[filter.field] = { ...(acc[filter.field] || {}), _gte: filter.value };
          break;
        case GenericKanbanComparsionType.LessThanEquals:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lte: filter.value };
          break;
        case GenericKanbanComparsionType.Like:
          acc[filter.field] = { _like: `%${filter.value}%` };
          break;
        case GenericKanbanComparsionType.ILike:
          acc[filter.field] = { _ilike: `%${filter.value}%` };
          break;
        case GenericKanbanComparsionType.Between:
          const [min, max] = filter.between;

          acc[filter.field] = { ... (acc[filter.field] || {}), ...(min?.value !== null && typeof min?.value !== undefined && min?.value !== '') && { _gte: min.value }, ...(max?.value !== null && typeof max?.value !== undefined && max?.value !== '') && { _lte: max.value } };
          break;
        default:
          break;
      }

      return acc;
    }, {});

    const filters = this.config.filters.filter(x => x.value).reduce((acc, filter) => {

      switch (filter.comparsionType) {
        case GenericKanbanComparsionType.Equals:
          acc[filter.field] = { _eq: filter.value };
          break;
        case GenericKanbanComparsionType.In:
          acc[filter.field] = { ...filter.value.length > 0 ? { _in: filter.value } : {} };
          break;
        case GenericKanbanComparsionType.GreaterThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _gt: filter.value };
          break;
        case GenericKanbanComparsionType.LessThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lt: filter.value };
          break;
        case GenericKanbanComparsionType.GreaterThanEquals:

          acc[filter.field] = { ...(acc[filter.field] || {}), _gte: filter.value };
          break;
        case GenericKanbanComparsionType.LessThanEquals:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lte: filter.value };
          break;
        case GenericKanbanComparsionType.Like:
          acc[filter.field] = { _like: `%${filter.value}%` };
          break;
        case GenericKanbanComparsionType.ILike:
          acc[filter.field] = { _ilike: `%${filter.value}%` };
          break;
        case GenericKanbanComparsionType.Between:
          const [min, max] = filter.between;

          acc[filter.field] = { ... (acc[filter.field] || {}), ...(min?.value !== null && typeof min?.value !== undefined && min?.value !== '') && { _gte: min.value }, ...(max?.value !== null && typeof max?.value !== undefined && max?.value !== '') && { _lte: max.value } };
          break;
        default:
          break;
      }

      return acc;
    }, {});

    const response = { ...fixedFilters, ...filters };

    return response;
  }

  private async loadData(config: IGenericKanbanConfig): Promise<any> {
    this.isLoading = true;

    const generateWhereColumnFilter = this.filterColumnsChanged();
    const generateWhereFilter = this.filterChanged();

    const statuses = await this.zeusClient.chain('query')({
      [config.statusTableName]: [
        {
          where: {
            ...generateWhereColumnFilter
          },
          order_by: [{ order: order_by.asc }],
        },
        {
          id: true,
          displayName: true,
          order: true
        }
      ],
    });

    const dealPromises: { [statusId: string]: {} } = {};

    for (const status of statuses[config.statusTableName] as any[]) {

      if (typeof status.id !== 'string') throw new Error('Invalid status id');

      dealPromises['d' + status.id.replace(/-/gi, '')] = {
        [config.tableName]: [
          {
            limit: config.perPage,
            offset: (config.currentPage - 1) * config.perPage,
            where: {
              statusId: { _eq: status.id },
              ...generateWhereFilter
            },
            order_by: [{ updated_at: order_by.desc }],
          },
          {
            ...this.generateColumnConfig(config.columns),
          },
        ]
      };

      if (typeof status.id !== 'string') throw new Error('Invalid status id');

      dealPromises['a' + status.id.replace(/-/gi, '')] = {
        [config.aggregateName]: [
          {
            where: {
              statusId: { _eq: status.id },
              ...generateWhereFilter
            }
          },
          {
            aggregate: {
              count: [{ columns: ['id'], distinct: true }, true]
            },
          },
        ],
      };
    }

    const response = await this.zeusClient.chain('query')({ __alias: dealPromises });

    const digestedResponse = Object.keys(response ?? {}).reduce((acc, key) => {
      const reconstructStatusId = this.reformatGuid(key.substring(1, key.length))

      acc[reconstructStatusId] = acc[reconstructStatusId] || {};

      if (key.startsWith('d')) {
        acc[reconstructStatusId][config.tableName] = response[key];
      } else {
        acc[reconstructStatusId][config.aggregateName] = response[key];
      }

      return acc;
    }, {})

    const dealsByStatus: { [statusId: string]: any } = {};

    for (const key in digestedResponse) {
      if (Object.prototype.hasOwnProperty.call(digestedResponse, key)) {
        const statusId = key;

        const dealsData = digestedResponse[statusId][config.tableName];

        dealsByStatus[statusId] = {
          dealStatus: (statuses[config.statusTableName] as any[]).find((status) => status.id === statusId),
          deals: dealsData,
        };
      }
    }

    const order = Object.keys(dealsByStatus).sort((a, b) => dealsByStatus[a].dealStatus.order - dealsByStatus[b].dealStatus.order)

    this.pagination = order.map((statusId) => {
      const status = dealsByStatus[statusId];

      return {
        statusId: statusId,
        currentPage: this.config.currentPage,
        perPage: this.config.perPage,
        total: digestedResponse[statusId][config.aggregateName].aggregate.count,
        loading: false,
      }
    })

    const result = order.map((statusId) => {
      const status = dealsByStatus[statusId];

      return {
        id: statusId,
        displayName: status.dealStatus.displayName,
        deals: digestedResponse[statusId][config.tableName],
        aggregate: digestedResponse[statusId][config.aggregateName].aggregate.count,
      }
    });

    this.config.data = result;
    this.isLoading = false;

  }

  private streamData(): void {
    const stream = this.zeusClient.subscription('subscription')({
      [this.config.streamName]: [
        {
          batch_size: 1,
          cursor: [{ initial_value: { updated_at: new Date() }, ordering: cursor_ordering.ASC }],
        },
        {
          ...this.generateColumnConfig(this.config.columns),
        },
      ],
    });

    stream.error((error: any) => {
      console.log('Error', error);
    });

    stream.on(({ [this.config.streamName]: stream }: { [key: string]: any }) => {
      this.handleRealTimeKanbanData(stream);
    });
  }

  private handleRealTimeKanbanData(data: any) {
    const newData = data[0];

    //check if newDeal exists in any status
    const dealStatus = this.config.data.find((status) => {
      return status.deals.find((deal) => {
        return deal.id === newData.id;
      });
    });

    //if exists, remove from old status
    if (dealStatus) {
      dealStatus.deals = dealStatus.deals.filter((deal) => {
        return deal.id !== newData.id;
      });

      //decrement aggregate count 
      this.config.data.forEach((status) => {
        if (status.id === dealStatus.id)
          status.aggregate -= 1;
      });

      //add to new status
      const newStatus = this.config.data.find((status) => {
        return status.id === newData.statusId;
      });

      //adds deal to start of array
      if (newStatus) {
        newStatus.deals.unshift(newData);
        newStatus.aggregate += 1;
      }
    } else {
      console.log('deal not found in any status');
      //if newDeal does not exist in any status, add to new status
      const newStatus = this.config.data.find((status) => {
        return status.id === newData.statusId;
      });

      if (newStatus) {
        newStatus.deals.unshift(newData);
        newStatus.aggregate += 1;
      }

    }
  }

  private async loadMore(statusId: string, scrollContext: { target: { scrollTop: number; scrollHeight: number; offsetHeight: number; }; }) {

    const currentPagination = this.pagination.find(x => x.statusId === statusId);

    if (!currentPagination.loading && scrollContext.target.scrollTop > (scrollContext.target.scrollHeight - scrollContext.target.offsetHeight) - 2) {

      const totalPage = Math.ceil(currentPagination.total / currentPagination.perPage);

      if (currentPagination.currentPage < totalPage) {
        currentPagination.loading = true;

        currentPagination.currentPage++;

        const generateWhereFilter = this.filterChanged();

        const result = await this.zeusClient.chain('query')({
          [this.config.tableName]: [
            {
              limit: this.config.perPage,
              offset: (currentPagination.currentPage - 1) * currentPagination.perPage,
              where: {
                statusId: { _eq: currentPagination.statusId },
                ...generateWhereFilter
              },
              order_by: [{ created_at: order_by.desc }],
            },
            {
              ...this.generateColumnConfig(this.config.columns),
            },
          ],
          [this.config.aggregateName]: [
            {
              where: $('where', `${this.config.tableName}_bool_exp` as GraphQLVariableType),
            },
            {
              aggregate: {
                count: [{ columns: ['id'], distinct: true }, true]
              },
            },
          ],
        });

        this.config.data.find(x => x.id === statusId).deals.push(...result[this.config.tableName] as any);
      }
    }

    setTimeout(() => {
      currentPagination.loading = false;
    }, 3000);

  }

  private generateColumnConfig(columns: string[]): Structure {
    const result: Structure = {};

    for (const column of columns) {
      const parts = column.split('.');
      const nestedStructure = this.digestNestedStructure(parts, 0);
      this.mergeStructures(result, nestedStructure as Structure);
    }

    return result;
  }

  private digestNestedStructure(parts: string[], index: number): Structure | boolean {
    if (index >= parts.length) return true;

    const part = parts[index];
    const isArray = part.endsWith('[]');
    const key = isArray ? part.slice(0, -2) : part;

    if (isArray) {
      return {
        [key]: [
          {},
          this.digestNestedStructure(parts, index + 1)
        ]
      } as Structure;
    } else {
      return {
        [key]: this.digestNestedStructure(parts, index + 1)
      };
    }
  }

  private mergeStructures(target: Structure, source: Structure): void {
    for (const key in source) {
      if (Array.isArray(source[key]) && Array.isArray(target[key])) {
        this.mergeStructures(target[key][1], source[key][1]);
      } else if (typeof source[key] === 'object' && typeof target[key] === 'object') {
        this.mergeStructures(target[key] as Structure, source[key] as Structure);
      } else {
        target[key] = source[key];
      }
    }
  }

  private reformatGuid(guidWithoutHyphens: string): string {
    if (guidWithoutHyphens.length !== 32) {
      throw new Error('Invalid GUID length');
    }
    const part1 = guidWithoutHyphens.substring(0, 8);
    const part2 = guidWithoutHyphens.substring(8, 12);
    const part3 = guidWithoutHyphens.substring(12, 16);
    const part4 = guidWithoutHyphens.substring(16, 20);
    const part5 = guidWithoutHyphens.substring(20, 32);
    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  private async handleFilter(): Promise<void> {
    this.isLoading = true;
    this.config.currentPage = 1;

    await this.loadData(this.config);

    this.isLoading = false;
  }
}