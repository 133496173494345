import { inject } from "aurelia";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from "@aurelia/dialog";
import { ICustomElementController } from "@aurelia/runtime-html";
import { ModelTypes } from '@zeus';
import { IEventAggregator } from "aurelia";
import { IOpportunityService } from './../../../../services/opportunity-service';
import { toastifyInfo, toastifySuccess, toastifyError } from '@helpers/toastify';

@inject(IDialogDom, IEventAggregator, IOpportunityService,)
export class AdvancedStepComponent implements IDialogCustomElementViewModel {
	private toStatusId: string;
	public opportunity: ModelTypes['deal'];

	constructor(
		private dialogDom: DefaultDialogDom,
		readonly ea: IEventAggregator,
		private service: IOpportunityService,
	) {
		this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
	}

	$dialog: IDialogController;
	$controller?: ICustomElementController<this>;

	async activate(model: { opportunity: ModelTypes['deal'], statusId: string }) {
		this.toStatusId = model.statusId;
		this.opportunity = model.opportunity;
	}

	private async handleAdvanceStep() {
		if (this.opportunity.statusId === this.toStatusId) {
			toastifyInfo({ message: 'Oportunidade já esta no status escolhido' });
			this.closeModal();

			return;
		}

		try {
			const response = await this.service.advanceStep({ dealId: this.opportunity.id, statusId: this.toStatusId });

			if (!response) throw new Error('Erro ao avançar etapa');

			this.closeModal();

			toastifySuccess({ message: 'Oportunidade avançada com sucesso' });
		} catch (error) {
			if (error instanceof Error) {
				toastifyError({ message: error.message });
			} else {
				toastifyError({ message: 'Erro inesperado, tente novamente' });
			}
		}
	}

	private closeModal() {
		this.$dialog.cancel();
	}
}