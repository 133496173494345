import { DI } from 'aurelia';
import { inject } from "aurelia";
import { $, ModelTypes, products_constraint, products_update_column } from '@zeus';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import slugify from 'slugify';

export interface IProductsService {
  getBrand(): Promise<ModelTypes["brands"]>;
  getProducts(): Promise<ModelTypes["products"][]>;
  getProductById(id: string): Promise<ModelTypes["products"]>;
  upsertProduct(command: ModelTypes['products_insert_input']): Promise<ModelTypes["products"]>;
  deleteProductById(id: string): Promise<ModelTypes["products"] | undefined>;
  verifyProductExistBySlug({ id, slug, brandId }: { id: string, slug: string, brandId: string }): Promise<boolean>;
}

@inject(IAuthService, IZeusClient)
export class ProductsService implements IProductsService {

  constructor(
    private auth: IAuthService,
    private zeusClient: ZeusClient
  ) { }

  async getBrand(): Promise<ModelTypes["brands"]> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {
          limit: 1
        },
        {
          id: true,
        }
      ]
    });

    if (!result.brands.length) return;

    return result.brands[0] as ModelTypes["brands"];
  }

  async getProducts(): Promise<ModelTypes["products"][]> {
    const user = this.auth.getUser();

    if (!user) return;

    const result = await this.zeusClient.chain('query')({
      products: [
        {
          where: {
            created_by: { _eq: user.id },
            deleted: { _eq: false },
          }
        },
        {
          id: true,
          alias: true,
          slug: true,
          type: true,
          category: true,
          linkConversion: true,
          price: true,
          salePrice: true,
          description: true,
          imageOneId: true,
          imageTwoId: true,
          imageThreeId: true,
          imageFourId: true,
          created_by: true,
          created_at: true,
          updated_at: true,
        }
      ]
    });

    return result.products as ModelTypes["products"][];
  }

  async getProductById(id: string): Promise<ModelTypes["products"]> {
    const result = await this.zeusClient.chain('query')({
      products_by_pk: [
        { id: id },
        {
          id: true,
          alias: true,
          slug: true,
          type: true,
          category: true,
          linkConversion: true,
          price: true,
          salePrice: true,
          wordKeys: [{ path: '$', }, true],
          description: true,
          imageOneId: true,
          imageTwoId: true,
          imageThreeId: true,
          imageFourId: true,
          created_by: true,
          created_at: true,
          updated_at: true,
          characteristicOne: true,
          characteristicOneDescription: true,
          characteristicTwo: true,
          characteristicTwoDescription: true,
          characteristicThree: true,
          characteristicThreeDescription: true,
          differentialOne: true,
          differentialOneDescription: true,
          differentialTwo: true,
          differentialTwoDescription: true,
          differentialThree: true,
          differentialThreeDescription: true,
          persuasiveDescription: true
        }
      ]
    });

    return result.products_by_pk as ModelTypes["products"];
  }

  async upsertProduct(command: ModelTypes['products_insert_input']): Promise<ModelTypes["products"]> {
    const prouductQuery = await this.zeusClient.chain('query')({
      products: [
        {
          where: { created_by: { _eq: this.auth.getUser().id } },
        },
        {
          id: true,
          slug: true,
          brandId: true
        }
      ]
    });

    const slugCounter = 1;
    const productId = prouductQuery.products?.at(0)?.id;
    const brandId = prouductQuery.products?.at(0)?.brandId;

    let targetSlug = command.slug;
    let slugChallange = slugify(command.alias, { strict: true, lower: true })

    while (!targetSlug) {
      const verifySlug = await this.verifyProductExistBySlug({ ...productId && { id: productId as string }, slug: slugChallange, brandId: brandId as string });

      if (!verifySlug) {
        targetSlug = slugChallange;
      } else {
        slugChallange = slugify(command.alias + '-' + slugCounter, { strict: true, lower: true });
      }
    }

    const result = await this.zeusClient.chain('mutation')({
      insert_products_one: [
        {
          object: {
            ...command,
            slug: targetSlug,
            wordKeys: $('wordKeys', 'jsonb'),
          },
          on_conflict: {
            constraint: products_constraint.projects_pkey,
            update_columns: [
              products_update_column.id,
              products_update_column.alias,
              products_update_column.type,
              products_update_column.category,
              products_update_column.description,
              products_update_column.price,
              products_update_column.salePrice,
              products_update_column.brandId,
              products_update_column.linkConversion,
              products_update_column.imageOneId,
              products_update_column.imageTwoId,
              products_update_column.imageThreeId,
              products_update_column.imageFourId,
              products_update_column.characteristicOne,
              products_update_column.characteristicOneDescription,
              products_update_column.characteristicTwo,
              products_update_column.characteristicTwoDescription,
              products_update_column.characteristicThree,
              products_update_column.characteristicThreeDescription,
              products_update_column.differentialOne,
              products_update_column.differentialOneDescription,
              products_update_column.differentialTwo,
              products_update_column.differentialTwoDescription,
              products_update_column.differentialThree,
              products_update_column.differentialThreeDescription,
              products_update_column.persuasiveDescription,
              products_update_column.slug,
            ]
          }
        },
        {
          id: true,
        }
      ]
    }, {
      variables: {
        wordKeys: command.wordKeys
      }
    });

    return result.insert_products_one as ModelTypes["products"];
  }

  async deleteProductById(id: string): Promise<ModelTypes["products"] | undefined> {
    const result = await this.zeusClient.chain('mutation')({
      update_products_by_pk: [
        {
          pk_columns: {
            id: id,
          },
          _set: {
            deleted: true,
          }
        },
        {
          id: true,
        }
      ]
    });

    return result.update_products_by_pk as ModelTypes["products"];
  }

  async verifyProductExistBySlug({ id, slug, brandId }: { id: string, slug: string, brandId: string }): Promise<boolean> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {
          limit: 1,
          where: {
            id: { _neq: id },
            brandId: { _eq: brandId },
            slug: { _eq: slug },
          }
        },
        {
          id: true,
        }
      ]
    });

    return !!result?.products?.length;
  }
}

export const IProductsService = DI.createInterface<IProductsService>((x) => x.singleton(ProductsService));
