import { nhost } from '@lib/nhost'
import { inject } from "aurelia";
import { FileResponse, StorageDeleteResponse, StorageUploadFileResponse } from '@nhost/nhost-js';
import { IStorageService, UploadFromFileParams, UploadFromUrlParams, UploadParams } from '@interfaces/storage/storage-interface';

@inject()
export class StorageService implements IStorageService {

  async upload(params: UploadParams): Promise<FileResponse> {

    const { id, name, data, bucketId } = params;

    if (typeof data === 'string') {
      return await this.uploadFromUrl({ id, name, url: data, bucketId });
    }

    return await this.uploadFromFile({ id, name, file: data, bucketId });
  }

  async uploadFromFile(params: UploadFromFileParams): Promise<FileResponse> {
    const { id, name, file, bucketId } = params;

    const response: StorageUploadFileResponse = await nhost.storage.upload({
      id,
      file,
      name,
      bucketId,
    });

    return response.fileMetadata;
  }

  async uploadFromUrl(params: UploadFromUrlParams): Promise<FileResponse> {

    const { id, name, url, bucketId } = params;

    const responseFetch: Response = await fetch(url);

    const blob: Blob = await responseFetch.blob();

    const file: File = new File([blob], name || 'image', { type: blob.type });

    const response: StorageUploadFileResponse = await nhost.storage.upload({
      id,
      file,
      name,
      bucketId,
    });

    return response.fileMetadata;
  }

  async deleteById(fileId: string): Promise<boolean> {
    const response: StorageDeleteResponse = await nhost.storage.delete({ fileId });

    if (response.error) {
      return false;
    }

    return true;
  }
}