import { inject } from "aurelia";
import { newInstanceForScope } from '@aurelia/kernel';
import { IRouter } from '@aurelia/router';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { QuillComponent } from '@components/quill/quill-component';
import { ModelTypes } from '@zeus';
import { IEventAggregator, bindable } from 'aurelia';
import { nhost } from '@lib/nhost';
import { ITextModuleService } from './services/text-module-services';
import { IDialogService } from '@aurelia/dialog';
import { SaveTextDialog } from './components/save-text-dialog/save-text-dialog';
import slugify from 'slugify';
import { toastifyError, toastifySuccess, toastifyWarning } from '@helpers/toastify';
import { FullScreenSpinnerDialog } from "@components/full-screen-spinner-dialog/full-screen-spinner-dialog";
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { GoPremiumDialog } from "@components/calendar/components/go-premium-dialog/go-premium-dialog";

@inject(IRouter, IEventAggregator, IDialogService, ITextModuleService, IValidationRules, newInstanceForScope(IValidationController), IAuthService)
export class TextModule {

  @bindable imageFile: File;
  @bindable imageFileId: string;

  private textId: string;
  private base64Data: string;

  quillRef: QuillComponent;

  private brandId: string = null;

  private selectedBrandId: string;
  private selectedProductId: string;
  @bindable alias: string;
  private slug: string;
  private prompt: string;
  @bindable content: string;

  private brands: ModelTypes['brands'][];
  private products: ModelTypes['products'][];

  private format: string;
  private voiceTone: string;
  private category: string;
  private textStyle: string;

  constructor(
    private router: IRouter,
    readonly ea: IEventAggregator,
    private dialogService: IDialogService,
    private service: ITextModuleService,
    readonly validationRules: IValidationRules,
    readonly validationController: IValidationController,
    private authService: IAuthService
  ) {
    validationRules
      .on(this)
      .ensure('alias')
      .required().withMessage('Informe o titulo')

      .ensure('content')
      .required().withMessage('Informe o texto')

      .ensure('imageFile')
      .required().withMessage('Insira uma imagem')
      .satisfies((value: File) => (value !== null && value !== undefined) || (this.imageFileId !== null)).withMessage('Insira uma imagem')
  }

  async loading(params: { id: string; }) {
    if (params.id) {
      this.textId = params.id;
    }
  }

  async attached() {
    if (this.textId) {
      const response = await this.service.getProjectById(this.textId);

      this.content = response?.content;
      this.alias = response?.alias;
      this.selectedProductId = response?.productId;

      if (!response.featuredImageFileId) return

      this.imageFileId = response.featuredImageFileId;

      const featureImageFileId = response?.featuredImageFileId
      const url = nhost.storage.getPublicUrl({ fileId: featureImageFileId });

      this.base64Data = url
    } else {
      const userBrands = await this.service.brands();
      if (userBrands.length === 1) {
        this.brandId = userBrands[0].id;
      }
    }

    this.handleLoadData();

    this.selectedBrandId = this.brandId;
  }

  private async handleLoadData(): Promise<void> {
    this.brands = await this.service.brands();
    this.products = await this.service.products();
  }

  aliasChanged() {
    this.slug = this.alias;
  }

  async addProjectBlog() {

    const result = await this.validationController.validate();

    if (!result.valid) {
      toastifyWarning({ message: 'Preencha todos os campos' });

      return;
    }
    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }
    try {

      if (!this.imageFileId) {
        const imageBannerBlogResponse = await nhost.storage.upload({ file: this.imageFile, bucketId: 'public' });

        this.imageFileId = imageBannerBlogResponse.fileMetadata.id;
      }

      this.content = this.quillRef.quill.root.innerHTML;

      const resultMutation = await this.service.insertProject({
        id: this.textId,
        rawText: '',
        alias: this.alias,
        content: this.content,
        featuredImageFileId: this.imageFileId,
        brandId: this.selectedBrandId,
        productId: this.selectedProductId,
        slug: slugify(this.slug, { strict: true, lower: true }),
      });

      toastifySuccess({ message: 'Projeto salvo com sucesso' });

      this.router.load(`/text/${resultMutation.id}`)
    } catch (e) {
      toastifyError({ message: e });
    }
  }

  async filesChanged(event: { target: { files: FileList }; }) {
    if (!event.target.files) return
    this.imageFile = event.target.files[0]

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.base64Data = fileReader.result as string;
    }
    if (this.imageFile) {
      fileReader.readAsDataURL(this.imageFile);
    }
  }

  private downloadTextFile() {
    const content = this.quillRef.quill.root.innerHTML;

    const blob = new Blob([content], { type: 'text/plain' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'text.txt';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  private async generateFromIA(): Promise<void> {
    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }
    const spinnerDialog = await this.dialogService.open({
      component: () => FullScreenSpinnerDialog,
      model: {},
      lock: true,
      startingZIndex: 100,
    });

    try {
      const mutation = await this.service.blogPostGeneratorEnhancedForCopy({
        brief: this.prompt,
        brandName: this.brands.find((brand) => brand.id === this.selectedBrandId).brandName,
        productName: this.products.find((product) => product.id === this.selectedProductId).alias,
        title: this.alias,
        format: this.format,
        voiceTone: this.voiceTone,
        category: this.category,
        textStyle: this.textStyle,
      });

      if (!mutation || !mutation.html) throw new Error('Erro ao gerar texto');

      this.content = mutation.html;
    } catch (error) {
      toastifyError({ message: error });
    } finally {
      spinnerDialog.dialog.ok();
    }
  }

  private async handlePublish(): Promise<void> {
    await this.dialogService.open({
      component: () => SaveTextDialog,
      model: { projectId: this.textId },
      lock: false,
      startingZIndex: 100,
    });
  }

  async atualizar(): Promise<void> {
    await this.service.atualizar();
  }
}