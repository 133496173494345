import { inject } from "aurelia";
import { I18N } from '@aurelia/i18n';
import { IEventAggregator } from 'aurelia';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IGenericListConfig, GenericListComparsionType, GenericListFildInputType } from '@components/generic-list/interfaces/generic-filtered-interface';
import { toastifyError, toastifySuccess } from '@helpers/toastify';

@inject(I18N, IAuthService, IZeusClient, IEventAggregator)
export class BlogPage {
  private config: IGenericListConfig;

  constructor(
    private I18N: I18N,
    private auth: IAuthService,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator
  ) { }

  loading() {
    this.config = {
      total: 0,
      tableName: 'project',
      streamName: 'project_stream',
      aggregateName: 'project_aggregate',
      columns: ['id', 'alias', 'status', 'type', 'previewImageFileId', 'publishDate', 'scheduledDate', 'deleted'],
      fixedFilters: [{
        comparsionType: GenericListComparsionType.Equals,
        title: 'type',
        field: 'type',
        type: GenericListFildInputType.Text,
        value: 'blog',
      }],
      filters: [],
      data: [],
      perPage: 12,
      currentPage: 1,
    }
  }

  private async deleteProject(projectId: string) {
    const { id } = await this.zeusClient.deleteProjectById(projectId);

    if (!id) {
      toastifyError({ message: 'Erro ao excluir criação' });
      return;
    }

    toastifySuccess({ message: 'Criação excluida com sucesso' });
  }

  private setIconByType(type: string): string {
    switch (type) {
      case 'image':
        return 'fa-regular fa-image';
      case 'advert':
        return 'fa-solid fa-rectangle-ad';
      case 'post':
        return 'fa-solid fa-calendar-days';
      case 'blog':
        return 'fa-solid fa-blog';
      case 'video':
        return 'fa-solid fa-video';
      case 'print':
        return 'fa-solid fa-print';
      case 'presentation':
        return 'fa-solid fa-person-chalkboard';
      default:
        return 'fas fa-question';
    }
  }

  private colorStatus(status: string): string {
    switch (status) {
      case 'draft':
        return 'red';
      case 'scheduled':
        return 'amber';
      case 'posted':
        return 'emerald';
      default:
        return 'blue';
    }
  }
}