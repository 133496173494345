import { CustomElement } from '@aurelia/runtime-html';
export const name = "product-module";
export const template = "";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
