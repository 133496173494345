import { inject } from "aurelia";
import { valueConverter } from "aurelia";
import { I18N } from "@aurelia/i18n";

@valueConverter('labelFromParameters')
@inject(I18N)
export class LabelFromParameters {
  constructor(private I18N: I18N) { }

  toView(id: any, parameters: any[]) {
    if (!parameters) return '';

    const value = parameters.find((p: { id: any; }) => p.id === id)?.Label[this.I18N.getLocale()];

    if (!value) return '';

    return value;
  }
}