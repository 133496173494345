import { CustomElement } from '@aurelia/runtime-html';
export const name = "error-component";
export const template = "<div class=\"flex flex-col justify-center items-center\">\n  <span class=\"text-sm font-semibold text-red-600 dark:text-red-600\">Oops! Alguma coisa saiu errado, tente atualizar a página</span>\n  <!-- <span class=\"text-sm font-medium italic text-red-600 dark:text-red-600 pt-2\">${error}</span> -->\n  <img src=\"assets/images/undraw_error.svg\" alt=\"undraw-error\" class=\"h-80 mt-6\">\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
