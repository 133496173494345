import { inject } from "aurelia";
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationController } from '@aurelia/validation-html';
import { ICustomElementController } from '@aurelia/runtime-html';
import { IValidationRules, ValidateInstruction } from '@aurelia/validation';
import { IStepOne, IStepTwo, IStepThree } from './interfaces/steps-interface'
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IDialogCustomElementViewModel, IDialogController, DefaultDialogDom, IDialogDom } from "@aurelia/dialog";
import { yesOrNoData, socialNetworksData, marketingObjectiveData, expectationData, productOrServiceData, companySegmentData, staffCountData, clientPreferenceData, purchaseBehaviorData, purchaseChallengesData, purchaseInfluencersData, behaviorProfileData, socioeconomicProfileData, personalityData, voiceToneData, natureOfProduct, categoryOfProduct } from '@data/index'
import { userOnboarding_constraint, userOnboarding_update_column } from "@zeus";
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { nhost } from "@lib/nhost";
import { toastifyError } from "@helpers/toastify";
import { IFormInputOption } from "@components/form/interfaces/form-interface";

@inject(IZeusClient, IDialogDom, IValidationRules, newInstanceForScope(IValidationController), IAuthService)
export class Onboarding implements IDialogCustomElementViewModel {
  // campaignObjective: string;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private hasWebsiteOptions: IFormInputOption[] = [
    { label: 'Não', value: false },
    { label: 'Sim', value: true }
  ]


  private stepOne: IStepOne = {
    name: '',
    brandName: '',
    companySegment: '',
    phone: '',
    backgroundColor: '#ff3e00',
    foregroundColor: '#ffffff',
    accentColor: '#4a90e2',
    logoFileId: '',
    voiceTone: '',
    personality: '',

    logoFile: null
  }

  private stepTwo: IStepTwo = {
    alias: '',
    type: '',
    category: '',
    price: '',
    salePrice: '',
    imageOneFileId: '',
    imageTwoFileId: '',
    imageThreeFileId: '',
    imageFourFileId: '',
    stepTwoimageOneFile: null,
    stepTwoimageTwoFile: null,
    stepTwoimageThreeFile: null,
    stepTwoimageFourFile: null,

  }

  private stepThree: IStepThree = {

    hasWebsite: false,
    site: '',
    activateWebsite: false,
  }

  constructor(
    private zeusClient: ZeusClient,
    private dialogDom: DefaultDialogDom,
    private validationRules: IValidationRules,
    private validationController: IValidationController,
    private auth: IAuthService
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

    validationRules
      .on(this.stepOne)
      .ensure('brandName')
      .required()
      .withMessage('Nome da marca é obrigatório')
      .ensure('name')
      .required()
      .withMessage('Nome da empresa é obrigatório')
      .ensure('companySegment')
      .required()
      .withMessage('Segmento da empresa é obrigatório')
      .ensure('phone')
      .required()
      .withMessage('Telefone é obrigatório')
      .ensure('backgroundColor')
      .required()
    // .withMessage('Cor de fundo é obrigatório')
    // .ensure('foregroundColor')
    // .required()
    // .withMessage('Cor do texto é obrigatório')
    // .ensure('accentColor')
    // .required()
    // .withMessage('Cor de destaque é obrigatório')

    // .ensure('logoFile')
    // .required()
    // .when((x: IStepOne) => !x.logoFileId)
    // .withMessage('Selecione um logo')

    validationRules
      .on(this.stepTwo)
      .ensure('alias')

      .required()
      .withMessage('Nome do produto é obrigatório')
      .ensure('type')
      .required()
      .withMessage('Tipo do produto é obrigatório')
      .ensure('category')
      .required()
      .withMessage('Categoria do produto é obrigatório')
    // .ensure('stepTwoimageOneFile')
    // .required()
    // .when((x: IStepTwo) => !x.imageOneFileId)
    // .withMessage('Selecione uma imagem')
    // .ensure('stepTwoimageTwoFile')
    // .required()
    // .when((x: IStepTwo) => !x.imageTwoFileId)
    // .withMessage('Selecione uma imagem')
    // .ensure('stepTwoimageThreeFile')
    // .required()
    // .when((x: IStepTwo) => !x.imageThreeFileId)
    // .withMessage('Selecione uma imagem')
    // .ensure('stepTwoimageFourFile')
    // .required()
    // .when((x: IStepTwo) => !x.imageFourFileId)
    // .withMessage('Selecione uma imagem')

    validationRules
      .on(this.stepThree)
      .ensure('hasWebsite')
      .required()
      .withMessage('Tem site ou não é obrigatório')
      .ensure('activateWebsite')
      .required()
      .withMessage('Ativar ou não é obrigatório')

  }

  currentStepIndex: number = 0;

  private steps: { index: number; stepTitle: string }[] = [
    { index: 0, stepTitle: 'Marca e Identidade' },
    { index: 1, stepTitle: 'Produto ou Serviço' },
    { index: 2, stepTitle: 'Site da Marca' },
    { index: 3, stepTitle: 'Seu Novo Site' },
  ]

  private yesOrNoData = yesOrNoData;
  private staffCountData = staffCountData;
  private productOrServiceData = productOrServiceData;
  private companySegmentData = companySegmentData;
  private socialNetworksData = socialNetworksData;
  private expectationData = expectationData;
  private marketingObjectiveData = marketingObjectiveData;
  private clientPreferenceData = clientPreferenceData;
  private purchaseBehaviorData = purchaseBehaviorData;
  private purchaseChallengesData = purchaseChallengesData;
  private purchaseInfluencersData = purchaseInfluencersData;
  private behaviorProfileData = behaviorProfileData;
  private socioeconomicProfileData = socioeconomicProfileData;
  private personalityData = personalityData;
  private voiceToneData = voiceToneData;
  private natureOfProductData = natureOfProduct;
  private categoryOfProductData = categoryOfProduct;
  async activate() {
    await this.loadOnboardingData();
  }
  async skipStep() {
    await this.zeusClient.chain('mutation')({
      insert_userOnboarding_one: [
        {
          object: {
            userId: this.auth.getUser().id,
            currentStep: this.currentStepIndex + 1,

          },
          on_conflict: {
            constraint: userOnboarding_constraint.userOnboarding_pkey,
            update_columns: [
              userOnboarding_update_column.userId,
              userOnboarding_update_column.currentStep,
            ]
          }
        },
        {
          currentStep: true,
        }
      ],

      ...this.currentStepIndex === 2 && {
        update_userMetadata_by_pk: [{
          pk_columns: {
            id: this.auth.getUser().id
          },
          _set: {
            onboardCompleted: true
          }
        },
        {
          id: true
        }
        ]
      }
    });

    if (this.currentStepIndex === 2) {
      this.$dialog.cancel();
    }
    this.currentStepIndex++
  }

  async saveStep(): Promise<void> {
    if (this.currentStepIndex === 0) {
      const validateStepOne = await this.validationController.validate(new ValidateInstruction(this.stepOne));

      if (!validateStepOne.valid) return;

      let logoFileId = this.stepOne.logoFileId

      if (this.stepOne.logoFile) {
        const { fileMetadata, error } = await nhost.storage.upload({
          bucketId: 'public',
          file: this.stepOne.logoFile,
          ...this.stepOne.logoFileId && { fileId: this.stepOne.logoFileId }
        })
        if (error) {
          toastifyError({ message: 'Erro ao fazer upload da imagem' })
          return
        }
        if (fileMetadata) {
          logoFileId = fileMetadata.id
        }
      }

      const response = await this.zeusClient.saveUserOnboardingStepOne({
        name: this.stepOne.name,
        brandName: this.stepOne.brandName,
        companySegment: this.stepOne.companySegment,
        phone: this.stepOne.phone,
        backgroundColor: this.stepOne.backgroundColor,
        foregroundColor: this.stepOne.foregroundColor,
        accentColor: this.stepOne.accentColor,
        logoFileId: logoFileId,
        voiceTone: this.stepOne.voiceTone,
        personality: this.stepOne.personality,

      });

      this.currentStepIndex = response.currentStep;

    } else if (this.currentStepIndex === 1) {
      const result = await this.validationController.validate(new ValidateInstruction(this.stepTwo));

      if (result.valid) {
        let imageOneFileId = this.stepTwo.imageOneFileId
        let imageTwoFileId = this.stepTwo.imageTwoFileId
        let imageThreeFileId = this.stepTwo.imageThreeFileId
        let imageFourFileId = this.stepTwo.imageFourFileId

        if (this.stepTwo.stepTwoimageOneFile) {
          const { fileMetadata, error } = await nhost.storage.upload({
            bucketId: 'public',
            file: this.stepTwo.stepTwoimageOneFile,
            ...this.stepTwo.imageOneFileId && { fileId: this.stepTwo.imageOneFileId }
          })
          if (error) {
            toastifyError({ message: 'Erro ao fazer upload da imagem' })
            return
          }
          if (fileMetadata) {
            imageOneFileId = fileMetadata.id
          }
        }

        if (this.stepTwo.stepTwoimageTwoFile) {
          const { fileMetadata, error } = await nhost.storage.upload({
            bucketId: 'public',
            file: this.stepTwo.stepTwoimageTwoFile,
            ...this.stepTwo.imageTwoFileId && { fileId: this.stepTwo.imageTwoFileId }
          })
          if (error) {
            toastifyError({ message: 'Erro ao fazer upload da imagem' })
            return
          }
          if (fileMetadata) {
            imageTwoFileId = fileMetadata.id
          }
        }

        if (this.stepTwo.stepTwoimageThreeFile) {
          const { fileMetadata, error } = await nhost.storage.upload({
            bucketId: 'public',
            file: this.stepTwo.stepTwoimageThreeFile,
            ...this.stepTwo.imageThreeFileId && { fileId: this.stepTwo.imageThreeFileId }
          })
          if (error) {
            toastifyError({ message: 'Erro ao fazer upload da imagem' })
            return
          }
          if (fileMetadata) {
            imageThreeFileId = fileMetadata.id
          }
        }

        if (this.stepTwo.stepTwoimageFourFile) {
          const { fileMetadata, error } = await nhost.storage.upload({
            bucketId: 'public',
            file: this.stepTwo.stepTwoimageFourFile,
            ...this.stepTwo.imageFourFileId && { fileId: this.stepTwo.imageFourFileId }
          })
          if (error) {
            toastifyError({ message: 'Erro ao fazer upload da imagem' })
            return
          }
          if (fileMetadata) {
            imageFourFileId = fileMetadata.id
          }
        }

        const response = await this.zeusClient.saveUserOnboardingStepTwo({
          alias: this.stepTwo.alias,
          type: this.stepTwo.type,
          category: this.stepTwo.category,
          price: this.stepTwo.price,
          salePrice: this.stepTwo.salePrice,
          imageOneFileId: imageOneFileId,
          imageTwoFileId: imageTwoFileId,
          imageThreeFileId: imageThreeFileId,
          imageFourFileId: imageFourFileId,
        });

        this.currentStepIndex = response.currentStep;
      }
    } else if (this.currentStepIndex === 2) {
      const result = await this.validationController.validate(new ValidateInstruction(this.stepThree));

      if (result.valid) {
        await this.zeusClient.saveUserOnboardingStepThree({
          hasWebsite: this.stepThree.hasWebsite,
          site: this.stepThree.site,
          activateWebsite: this.stepThree.activateWebsite,
        });

        this.finishOnBoard();
      }
    }
  }

  async loadOnboardingData() {
    const response = await this.zeusClient.getUserOnboarding();

    this.stepOne = Object.assign(this.stepOne, response?.stepOne);
    this.stepTwo = Object.assign(this.stepTwo, response?.stepTwo);
    this.stepThree = Object.assign(this.stepThree, response?.stepThree);
    this.currentStepIndex = response?.currentStep || 0;
  }

  previousStep() {
    this.currentStepIndex--;
  }

  finishOnBoard(): void {
    this.zeusClient.finishOnBoard(this.stepThree.activateWebsite);
    if (this.stepThree.activateWebsite) {
      this.currentStepIndex = 3;
    } else {
      this.$dialog.cancel();
    }
  }

  closeAndStart(): void {
    this.$dialog.cancel();
  }
}