import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "./../../components/header/header-component.ts";
export const name = "interactions-section";
export const template = "\n\n<section class=\"h-full p-4\">\n  <header-component title=\"Contatos\" subtitle=\"Registro de contatos realizados\">\n    <div au-slot=\"actions\">\n      <button type=\"button\" click.trigger=\"openInteractionDialog()\" class=\"text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500\">\n        <i class=\"fa-solid fa-plus\"></i>\n      </button>\n    </div>\n  </header-component>\n\n  <div class=\"flex flex-col gap-2 mt-6\">\n\n    <template if.bind=\"interactions.length > 0\" repeat.for=\"item of interactions\">\n      <!-- begin::card -->\n      <div class=\"flex flex-row w-full h-[75px] rounded-lg bg-white dark:bg-gray-800 shadow-soft-xs shadow-gray-300 dark:shadow-current border border-solid border-gray-200 dark:border-gray-700 px-4 py-2.5 gap-4\">\n        <div class=\"w-1 ${item.success ? 'bg-success-600' : 'bg-red-600'}\"></div>\n\n        <div class=\"flex flex-col w-full\">\n          <div class=\"flex justify-between items-center\">\n            <span class=\"text-xs text-gray-900 dark:text-gray-200 font-semibold line-clamp-1\">${getChannelById(item.channel)}</span>\n            <span class=\"text-[10px] text-gray-700 dark:text-gray-400 line-clamp-1\">${item.date | date:'pt-BR'}</span>\n          </div>\n\n          <span class=\"text-[10px] text-gray-700 dark:text-gray-400 line-clamp-2\">${item.observation}</span>\n        </div>\n      </div>\n      <!-- end::card -->\n    </template>\n    <template else>\n      <div class=\"flex flex-col items-center justify-center gap-4 mt-6\">\n        <span class=\"text-gray-700 dark:text-gray-400 text-sm\">Você não possui interações</span>\n      </div>\n    </template>\n\n  </div>\n\n</section>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
