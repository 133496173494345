import { inject } from "aurelia";
import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface ISearchTemplateService {
  newTemplateType(): Promise<Array<ModelTypes['newTemplateType']>>;
  searchNewTemplateType(search: string): Promise<Array<ModelTypes['newTemplateType']>>;
}

@inject(IZeusClient)
class SearchTemplateService implements ISearchTemplateService {

  constructor(private zeusClient: ZeusClient) { }

  public async newTemplateType(): Promise<Array<ModelTypes['newTemplateType']>> {
    const result = await this.zeusClient.chain('query')({
      newTemplateType: [
        {},
        {
          id: true,
          name: true,
          icon: true,
          channel: true,
        }
      ]
    });

    return result?.newTemplateType as Array<ModelTypes['newTemplateType']>
  }

  public async searchNewTemplateType(search: string): Promise<Array<ModelTypes['newTemplateType']>> {
    const result = await this.zeusClient.chain('query')({
      newTemplateType: [
        {
          ...(search && search != '') && { where: { name: { _ilike: `%${search}%` } } },
        },
        {
          id: true,
          name: true,
          icon: true,
          channel: true,
        }
      ]
    });

    return result?.newTemplateType as Array<ModelTypes['newTemplateType']>
  }

}

export const ISearchTemplateService = DI.createInterface<ISearchTemplateService>(x => x.singleton(SearchTemplateService));