import { inject } from "aurelia";
import { ModelTypes, $ } from '@zeus';
import { IRouter } from '@aurelia/router';
import { templateCategoryData } from '@data/index';
import { ICustomElementController } from "@aurelia/runtime-html";
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';

@inject(IRouter, IZeusClient, IDialogDom)
export class EditTemplateDialog implements IDialogCustomElementViewModel {
  private template: ModelTypes['pTemplate'];

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private templateCategoryOptions = templateCategoryData;

  constructor(
    private router: IRouter,
    private zeusClient: ZeusClient,
    private dialogDom: DefaultDialogDom
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: { template: ModelTypes['pTemplate'] }) {
    this.template = model.template;
  }

  async close() {
    this.$dialog.ok();
  }

  async save() {
    if (this.template.id) {
      await this.handleUpdate();
    } else {
      await this.handleSave();
    }
  }

  async handleSave() {
    try {
      const mutation = await this.zeusClient.chain('mutation')({
        insert_pTemplate_one: [
          {
            object: {
              category: this.template.category,
              visibility: 'public',
              previewFileId: this.template.previewFileId,
              titleMaxLength: this.template.titleMaxLength,
              subtitleMaxLength: this.template.subtitleMaxLength,
              descriptionMaxLength: this.template.descriptionMaxLength,
              ...(this.template.json && { json: $('json', 'jsonb') })
            }
          },
          {
            id: true,
            category: true,
            visibility: true,
            previewFileId: true,
            deletedAt: true,
            titleMaxLength: true,
            subtitleMaxLength: true,
            descriptionMaxLength: true,
          }
        ]
      }, {
        variables: { json: this.template.json }
      });

      if (!mutation.insert_pTemplate_one) throw new Error('Failed to save template');

      toastifySuccess({ message: 'Template salvo com sucesso' });

      await this.$dialog.ok(true);

      // await this.router.load(`/template-editor/${mutation.insert_pTemplate_one.id}`);
      await this.router.load(`/gestao-templates`);
    } catch (error) {
      console.error(error);

      toastifyError({ message: 'Erro ao salvar template' });
    }
  }

  async handleUpdate() {
    try {
      const mutation = await this.zeusClient.chain('mutation')({
        update_pTemplate_by_pk: [
          {
            pk_columns: { id: this.template.id },
            _set: {
              category: this.template.category,
              visibility: this.template.visibility,
              previewFileId: this.template.previewFileId,
              titleMaxLength: this.template.titleMaxLength,
              subtitleMaxLength: this.template.subtitleMaxLength,
              descriptionMaxLength: this.template.descriptionMaxLength,
              ...(this.template.json && { json: $('json', 'jsonb') })
            }
          },
          {
            id: true,
            category: true,
            visibility: true,
            previewFileId: true,
            deletedAt: true,
            titleMaxLength: true,
            subtitleMaxLength: true,
            descriptionMaxLength: true,
          }
        ]
      }, {
        variables: { json: this.template.json }
      });

      if (!mutation.update_pTemplate_by_pk) throw new Error('Failed to update template');

      toastifySuccess({ message: 'Template atualizado com sucesso' });

      this.$dialog.ok(true);
    } catch (error) {
      console.error(error);

      toastifyError({ message: 'Erro ao atualizar template' });
    }
  }

  async recover() {
    try {
      const mutation = await this.zeusClient.chain('mutation')({
        update_pTemplate_by_pk: [
          {
            pk_columns: { id: this.template.id },
            _set: {
              deletedAt: null
            }
          },
          {
            id: true,
            category: true,
            visibility: true,
            previewFileId: true,
            deletedAt: true,
            titleMaxLength: true,
            subtitleMaxLength: true,
            descriptionMaxLength: true,
          }
        ]
      });

      if (!mutation.update_pTemplate_by_pk) throw new Error('Failed to recover template');

      toastifySuccess({ message: 'Template recuperado com sucesso' });

      this.$dialog.ok(true);
    } catch (error) {
      console.error(error);

      toastifyError({ message: 'Erro ao recuperar template' });
    }
  }

  async delete() {
    try {
      const mutation = await this.zeusClient.chain('mutation')({
        update_pTemplate_by_pk: [
          {
            pk_columns: { id: this.template.id },
            _set: {
              deletedAt: new Date()
            }
          },
          {
            id: true,
            category: true,
            visibility: true,
            previewFileId: true,
            deletedAt: true,
            titleMaxLength: true,
            subtitleMaxLength: true,
            descriptionMaxLength: true,
          }
        ]
      });

      if (!mutation.update_pTemplate_by_pk) throw new Error('Failed to delete template');

      toastifySuccess({ message: 'Template deletado com sucesso' });

      this.$dialog.ok(true);
    } catch (error) {
      console.error(error);

      toastifyError({ message: 'Erro ao deletar template' });
    }
  }
}