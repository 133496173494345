import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/loading/loading-component";
import * as d1 from "./sections/hero/hero-section.ts";
import * as d2 from "./sections/brand-highlight/brand-highlight-section.ts";
import * as d3 from "./sections/what-we-do/what-we-do-section.ts";
import * as d4 from "./sections/our-differentiators/our-differentiators-section.ts";
export const name = "home-module";
export const template = "\n\n\n\n\n\n\n<div class=\"flex flex-col gap-4 my-6\">\n\n  <template if.bind=\"website\">\n    <hero-section website.bind=\"website\"></hero-section>\n\n    <brand-highlight-section website.bind=\"website\"></brand-highlight-section>\n\n    <what-we-do-section website.bind=\"website\"></what-we-do-section>\n\n    <our-differentiators-section website.bind=\"website\"></our-differentiators-section>\n  </template>\n\n  <template else>\n    <loading-component></loading-component>\n  </template>\n\n</div>";
export default template;
export const dependencies = [ d0, d1, d2, d3, d4 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
