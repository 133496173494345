import { inject } from "aurelia";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient)
export class UserIntegrationComponent {
  constructor(private zeusClient: ZeusClient) { }

  private async getUserAppIntegrationAggregate() {
    const result = await this.zeusClient.getUserAppIntegrationAggregate();

    return result;
  }
}