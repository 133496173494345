import { CustomElement } from '@aurelia/runtime-html';
export const name = "input-component";
export const template = "<div if.bind=\"validate\" validation-errors.from-view=\"errors\">\n  <label if.bind=\"label\" for=\"${id}\" class=\"form-label\">${label}</label>\n  <input ...$attrs id=\"${id}\" class=\"form-input ${errors.length ? '!border-red-600' : ''}\" />\n  <span if.bind=\"errors.length > 0\" style=\"color:red; font-size: 10px;\">${errors[0].result.message}</span>\n</div>\n\n<div else>\n  <label if.bind=\"label\" for=\"${id}\" class=\"form-label\">${label}</label>\n  <input ...$attrs id=\"${id}\" class=\"form-input\" />\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
