import { inject } from 'aurelia';
import YouTubePlayer from 'youtube-player';
import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';


@inject(IDialogDom)
export class YoutubeVideoPlayerDialog implements IDialogCustomElementViewModel {
  private player;
  private videoId: string = null;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(private dialogDom: DefaultDialogDom) {
    dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    dialogDom.contentHost.className = 'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex'
  }

  attached() {
    this.player = YouTubePlayer('video-player', {
      videoId: this.videoId,
      playerVars: {
        autoplay: 1,
        controls: 1,
        modestbranding: 1,
        rel: 0,
        disablekb: 1,
        fs: 1,
        iv_load_policy: 3,
        loop: 0,
        playsinline: 1,
        start: 0,
        end: 0,
        enablejsapi: 1,
        origin: window.location.origin
      }
    });
  }

  async activate(model?: { videoId: string }) {
    this.videoId = model.videoId;
  }

  async close() {
    this.$dialog.ok();
  }
}