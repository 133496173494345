import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/loading/loading-component";
import * as d1 from "./sections/contact-card/contact-card-section.ts";
export const name = "contact-module";
export const template = "\n\n\n\n<div class=\"flex flex-col gap-4 my-6\">\n\n  <template if.bind=\"website\">\n    <contact-card-section website.bind=\"website\"></contact-card-section>\n  </template>\n\n  <template else>\n    <loading-component></loading-component>\n  </template>\n\n</div>";
export default template;
export const dependencies = [ d0, d1 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
