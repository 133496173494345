import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/card-title/card-title-component";
import * as d1 from "@components/spinner-button/spinner-button-component";
import * as d2 from "./components/calendar/calendar-component.ts";
import * as d3 from "./components/courses/courses-component.ts";
import * as d4 from "./components/carousel/carousel-component.ts";
import * as d5 from "./components/products/products-component.ts";
import * as d6 from "./components/user-integration/user-integration-component.ts";
import * as d7 from "./components/projects-created/projects-created-component.ts";
export const name = "home-page";
export const template = "\n\n\n\n\n\n\n\n\n\n<section>\n\n\t<card-title-component title-id=\"dashboard\"></card-title-component>\n\n\t<div class=\"flex flex-wrap mb-6 gap-6\">\n\n\t\t<!-- begin::carousel -->\n\t\t<div class=\"w-full max-w-full\">\n\t\t\t<carousel-component></carousel-component>\n\t\t</div>\n\t\t<!-- end::carousel -->\n\n\t\t<!-- begin:: -->\n\t\t<div class=\"grid grid-cols-1 md:grid-cols-1 xl:grid-cols-3 gap-4 w-full max-w-full\">\n\t\t\t<projects-created-component></projects-created-component>\n\t\t\t<products-component></products-component>\n\t\t\t<user-integration-component></user-integration-component>\n\t\t</div>\n\t\t<!-- end:: -->\n\n\t\t<!-- begin:: -->\n\t\t<div class=\"grid grid-cols-1 lg:grid-cols-6 gap-4 w-full max-w-full\">\n\t\t\t<calendar-component class=\"col-span-6 xl:col-span-4\"></calendar-component>\n\t\t\t<courses-component class=\"col-span-6 h-60 xl:col-span-2\"></courses-component>\n\t\t</div>\n\t\t<!-- end:: -->\n\n\t</div>\n\n</section>";
export default template;
export const dependencies = [ d0, d1, d2, d3, d4, d5, d6, d7 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
