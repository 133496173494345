import { inject } from "aurelia";
import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface ITasksService {
  setToFinish(taskId: string): Promise<ModelTypes["dealTask"]>;
  createTask(command: ModelTypes["dealTask_insert_input"]): Promise<ModelTypes["dealTask"]>;
}

@inject(IZeusClient)
export class TasksService implements ITasksService {
  constructor(private zeusClient: ZeusClient) { }

  async createTask(command: ModelTypes["dealTask_insert_input"]): Promise<ModelTypes["dealTask"]> {
    const result = await this.zeusClient.chain('mutation')({
      insert_dealTask_one: [
        {
          object: command,
        },
        {
          id: true,
        }
      ]
    });

    return result.insert_dealTask_one as ModelTypes["dealTask"];
  }

  async setToFinish(taskId: string): Promise<ModelTypes["dealTask"]> {
    const result = await this.zeusClient.chain('mutation')({
      update_dealTask_by_pk: [
        {
          pk_columns: { id: taskId },
          _set: { finished: true },
        },
        {
          id: true,
        }
      ]
    });

    return result.update_dealTask_by_pk as ModelTypes["dealTask"];
  }

}

export const ITasksService = DI.createInterface<ITasksService>((x) => x.singleton(TasksService));
