import { CustomElement } from '@aurelia/runtime-html';
export const name = "action-component";
export const template = "<button id=\"status-bar-dropdown-button\" type=\"button\" class=\"btn-primary\">Mudar estágio</button>\n\n<div id=\"status-bar-dropdown-menu\"\n  class=\"z-40 hidden text-sm text-neutral-700 dark:text-gray-200 bg-white divide-y divide-neutral-100 dark:bg-neutral-700 dark:divide-neutral-600 rounded-lg shadow w-80\"\n  data-popper-placement=\"bottom\">\n  <ul class=\"py-2\">\n    <span class=\"px-4 py-2.5 text-base font-bold\">Mudar estágio</span>\n  </ul>\n\n  <ul class=\"py-2\" aria-labelledby=\"dropdownUserMenuButton\">\n    <li repeat.for=\"item of dealStatusData\">\n      <a click.trigger=\"handleAdvancedStep(item.id)\"\n        class=\"flex items-center p-4 hover:bg-neutral-100 dark:hover:bg-gray-600 dark:hover:text-white font-normal gap-2 cursor-pointer\"\n        role=\"menuitem\">\n        <i class=\"fa-solid fa-up-right-from-square\"></i>\n        ${item.text}\n      </a>\n    </li>\n  </ul>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
