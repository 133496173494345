import { inject } from "aurelia";
import { nhost } from '@lib/nhost';
import { watch } from "@aurelia/runtime-html";
import { BindingMode, bindable } from 'aurelia';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';

interface IDropzoneFile {
  id: string;
  file: string;
  size: number;
  upload: {
    progress: number;
    bytesSent: number;
  }
}

@inject(IAuthService)
export class UserAvatarComponent {
  @bindable id: string
  @bindable label: string
  @bindable({ mode: BindingMode.twoWay }) currentFile: File
  @bindable({ mode: BindingMode.twoWay }) currentFileId: string

  private dropzoneFile: IDropzoneFile[] = []

  constructor(private auth: IAuthService) { }

  async attached() {
    await this.addFilesToDropzone();
  }

  @watch('currentFileId')
  async currentFileIdChanged() {
    await this.addFilesToDropzone();
  }

  private async addFilesToDropzone(): Promise<void> {
    console.log('addFilesToDropzone', this.currentFileId)

    if (this.currentFileId) {
      const publicUrl = nhost.storage.getPublicUrl({
        fileId: this.currentFileId,
      })

      const fileData: IDropzoneFile = {
        id: this.currentFileId,
        file: publicUrl,
        size: 0,
        upload: {
          progress: 100,
          bytesSent: 1000
        }
      }

      this.dropzoneFile = [fileData];

      const currentFileElement = document.getElementById(fileData.id)
      currentFileElement?.classList.add('dz-complete');
    }
  }

  private filesChanged(event: { target: { files: FileList }; }) {
    if (event.target.files) {

      const files: FileList = event.target.files

      for (let i = 0; i < files.length; i++) {
        const file: File = files[i]

        const fileReader = new FileReader();

        fileReader.onloadend = () => {
          const base64Data = fileReader.result as string;

          const fileId = Math.random().toString(36).substring(2, 9);

          const fileData: IDropzoneFile = {
            id: fileId,
            file: base64Data,
            size: file.size,
            upload: {
              progress: 0,
              bytesSent: 0
            }
          }

          this.dropzoneFile = [fileData];
          this.currentFile = file;

          // Simulating upload progress
          this.handleProgressUpload(fileData);
        }

        if (file) {
          fileReader.readAsDataURL(file);
        }
      }
    }
  }

  private dragOver(event: DragEvent) { event.preventDefault(); }

  private dragLeave(event: DragEvent) { event.preventDefault(); }

  private drop(event: DragEvent) {
    event.preventDefault();

    const files: FileList = event.dataTransfer.files

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i]

      const fileType = file.type.split('/')[0];

      const validTypes = ['image'];

      if (!validTypes.includes(fileType)) {
        alert('Tipo de arquivo inválido');
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = () => {
        const base64Data = fileReader.result as string;

        const fileId = Math.random().toString(36).substring(2, 9);

        const fileData: IDropzoneFile = {
          id: fileId,
          file: base64Data,
          size: file.size,
          upload: {
            progress: 0,
            bytesSent: 0
          }
        }

        this.dropzoneFile = [fileData];
        this.currentFile = file;

        // Simulating upload progress
        this.handleProgressUpload(fileData);
      }

      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
  }

  private async removeFile(fileId: string): Promise<void> {

    // if (this.currentFileId) {
    //   const { error } = await nhost.storage.delete({ fileId: fileId })

    //   if (error) {
    //     console.error(error)
    //     return;
    //   }
    // }

    this.dropzoneFile = [];
    this.currentFile = null;
    this.currentFileId = null;
  }

  private handleProgressUpload(file: IDropzoneFile) {
    var minSteps = 6, maxSteps = 60, timeBetweenSteps = 100, bytesPerStep = 100000;

    const currentFileElement = document.getElementById(file.id)

    const totalSteps = Math.round(Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep)));

    for (let step = 0; step < totalSteps; step++) {
      const duration = timeBetweenSteps * (step + 1);

      setTimeout(() => {
        file.upload.progress = (step + 1) / totalSteps * 100;
        file.upload.bytesSent = (step + 1) * (file.size / totalSteps);

      }, duration);
    }

    setTimeout(() => {
      currentFileElement?.classList.add('dz-complete', 'dz-success');
    }, 1000);

    currentFileElement?.classList.remove('dz-success');
  }

}