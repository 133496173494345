import { inject } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IRouter } from '@aurelia/router';
import { IDialogService } from '@aurelia/dialog';
import { EditTemplateDialog } from './components/edit-template/edit-template-dialog';
import { GenericListComparsionType, GenericListFildInputType, IGenericListConfig } from '@components/generic-list/interfaces/generic-filtered-interface';

@inject(IRouter, IDialogService)
export class TemplateManagementPage {
  private config: IGenericListConfig;

  constructor(
    private router: IRouter,
    private dialogService: IDialogService,
  ) { }

  loading() {
    this.loadData();
  }

  private loadData(): void {
    this.config = {
      total: 0,
      tableName: 'pTemplate',
      streamName: 'pTemplate_stream',
      aggregateName: 'pTemplate_aggregate',
      columns: ['id', 'category', 'visibility', 'previewFileId', 'deletedAt', 'titleMaxLength', 'subtitleMaxLength', 'descriptionMaxLength'],
      fixedFilters: [],
      filters: [{
        field: 'deletedAt',
        comparsionType: GenericListComparsionType.IsNull,
        title: 'Online?',
        value: null,
        type: GenericListFildInputType.Select

      },
      {
        field: 'category',
        comparsionType: GenericListComparsionType.Select,
        title: 'Categoria',
        value: null,
        type: GenericListFildInputType.Select,
        options: [
          { value: 'Designers and Creatives', label: 'Designers and Creatives' },
          { value: 'Travel and Tourism', label: 'Travel and Tourism' },
          { value: 'Business and Consulting', label: 'Business and Consulting' },
          { value: 'Architecture and Decoration', label: 'Architecture and Decoration' },
          { value: 'Health and Wellness', label: 'Health and Wellness' },
          { value: 'Influencers and Content Creators', label: 'Influencers and Content Creators' },
          { value: 'Gyms and Trainers', label: 'Gyms and Trainers' },
          { value: 'Financial Services', label: 'Financial Services' },
          { value: 'Clinics and Medical Practices', label: 'Clinics and Medical Practices' },
          { value: 'Agencies and Marketing', label: 'Agencies and Marketing' },
          { value: 'Legal Services', label: 'Legal Services' },
          { value: 'Mentorship and Specializations', label: 'Mentorship and Specializations' },
          { value: 'Restaurants and Cafes', label: 'Restaurants and Cafes' },
          { value: 'Hotels and Accommodations', label: 'Hotels and Accommodations' },
          { value: 'Blogs and News', label: 'Blogs and News' },
          { value: 'Commercial Representatives', label: 'Commercial Representatives' },
          { value: 'Beauty and Aesthetics', label: 'Beauty and Aesthetics' },
          { value: 'Fashion and Style', label: 'Fashion and Style' },
          { value: 'Real Estate and Brokers', label: 'Real Estate and Brokers' },
          { value: 'Events and Parties', label: 'Events and Parties' },
          { value: 'Education and Courses', label: 'Education and Courses' },
          { value: 'Stores and E-commerce', label: 'Stores and E-commerce' },
          { value: 'Photographers and Videomakers', label: 'Photographers and Videomakers' },
          { value: 'Sports and Recreation', label: 'Sports and Recreation' },
          { value: 'Arts and Crafts', label: 'Arts and Crafts' },
          { value: 'Technology and IT', label: 'Technology and IT' },
          { value: 'Event Planners', label: 'Event Planners' },
          { value: 'Pet Services', label: 'Pet Services' }
        ]
      }

      ],

      data: [],
      perPage: 10,
      currentPage: 1,
    }
  }

  private async handleEdtiTemplate(templateId: string): Promise<void> {
    await this.router.load(`/template-editor/${templateId}`);
  }

  private async handleOpenEdtiTemplateDialog(template: ModelTypes['pTemplate']): Promise<void> {
    const { dialog } = await this.dialogService.open({
      component: () => EditTemplateDialog,
      model: { template },
      lock: true,
      startingZIndex: 100,
    });

    const result = await dialog.closed;

    if (result.status === 'ok') {
      const value = result.value as boolean;

      if (value) { /* empty */ }
    }
  }
}