import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "./components/action/action-component.ts";
export const name = "status-bar-component";
export const template = "\n\n<section class=\"flex flex-row card w-full mb-8\">\n  <div class=\"flex flex-row justify-between px-5 py-3 w-full gap-1\">\n\n    <div class=\"flex flex-col justify-between\">\n      <span class=\"text-base font-bold text-primary-600 uppercase\">${opportunity.name ?? 'N/A'}</span>\n      <span class=\"text-sm font-bold text-gray-400\">${opportunity.dealStatus.displayName}</span>\n    </div>\n\n    <div class=\"flex items-center flex-shrink-0 gap-3\">\n      <a href=\"/opportunity-management\" type=\"button\" class=\"btn-neutral-outline\"><i\n          class=\"fa-solid fa-arrow-left mr-2\"></i>Voltar</a>\n\n      <!-- begin::action-component -->\n      <action-component opportunity.bind=\"opportunity\"></action-component>\n      <!-- end::action-component -->\n    </div>\n\n  </div>\n</section>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
