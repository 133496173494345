/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { inject } from "aurelia";
import { ModelTypes } from "@zeus";
import { ICustomElementController } from "@aurelia/runtime-html";
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';

@inject(IDialogDom, IZeusClient)
export class ViewCalendarEventDialog implements IDialogCustomElementViewModel {
  private project: ModelTypes["project"];

  private previewImage: string;
  private minDate: string;
  private scheduledDate: string;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom,
    private zeusClient: ZeusClient,
  ) {
    dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

    dialogDom.contentHost.className = '!fixed !m-0 overflow-y-auto overflow-x-hidden top-0 right-0 z-50 h-full max-h-full flex'
  }

  async attaching() {
    const animation = this.dialogDom.contentHost.animate([
      { transform: 'translateX(100%)' },
      { transform: 'translateX(0)' }
    ], {
      duration: 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    await animation.finished;
  }

  async detaching() {
    const animation = this.dialogDom.contentHost.animate([
      { transform: 'translateX(0)' },
      { transform: 'translateX(100%)' }
    ], {
      duration: 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    await animation.finished;
  }

  async activate(model?: any) {
    this.minDate = moment().format('YYYY-MM-DDTHH:mm');

    this.project = model.project;

    this.scheduledDate = moment(this.project.scheduledDate).format('YYYY-MM-DDTHH:mm');

    if (this.project.featuredImageFileId) {
      this.previewImage = `https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${this.project.featuredImageFileId}?${new Date().getTime()}`;
    }

    if (this.project.previewImageFileId) {
      this.previewImage = `https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${this.project.previewImageFileId}?${new Date().getTime()}`;
    }
  }

  async deleteProject(project: ModelTypes["project"]): Promise<void> {
    const result = await this.zeusClient.chain('mutation')({
      update_project_by_pk: [
        {
          pk_columns: { id: project.id },
          _set: { deleted: true }
        },
        {
          id: true,
        }
      ]
    });

    if (!result?.update_project_by_pk?.id) {
      toastifyError({ message: 'Erro ao excluir criação' });
      return;
    }

    toastifySuccess({ message: 'Criação excluida com sucesso' });

    this.$dialog.cancel();
  }

  async rescheduleProject(): Promise<void> {
    const result = await this.zeusClient.chain('mutation')({
      rescheduleProject: [
        {
          projectId: this.project.id,
          scheduledDate: this.scheduledDate
        },
        {
          success: true,
          errorMessage: true,
        }
      ]
    });

    if (!result?.rescheduleProject?.success) {
      toastifyError({ message: result?.rescheduleProject?.errorMessage || 'Erro ao reagendar criação' });
      return;
    }

    toastifySuccess({ message: 'Criação reagendada com sucesso' });

    this.$dialog.cancel();
  }

  close() {
    this.$dialog.cancel();
  }
}