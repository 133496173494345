import { CustomElement } from '@aurelia/runtime-html';
import "./my-app.css";
import * as d1 from "@components/topnav/topnav-component";
import * as d2 from "@components/sidebar/sidebar-component";
export const name = "my-app";
export const template = "\n\n<!-- <import from=\"@components/feedback/feedback-component\" /> -->\n\n<div class=\"flex bg-gray-50 dark:bg-gray-900 h-screen\">\n\t<sidebar-component if.bind=\"auth.user\" open.two-way=\"isOpenDrawer\" class=\"hidden md:inline\"></sidebar-component>\n\n\t<div class=\"bg-gray-50 dark:bg-gray-900 flex flex-col overflow-hidden w-full\">\n\t\t<topnav-component if.bind=\"auth.user\" open.two-way=\"isOpenDrawer\"></topnav-component>\n\n\t\t<div class=\"fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90\" id=\"sidebarBackdrop\"></div>\n\n\t\t<main id=\"main-content\" class=\"relative w-full overflow-y-auto scrollbar-thin scrollbar-rounded-md scrollbar-thumb-rounded-md scrollbar-thumb-gray-300 h-full\">\n\t\t\t<div class=\"px-4 pb-4\">\n\t\t\t\t<au-viewport></au-viewport>\n\t\t\t</div>\n\t\t</main>\n\n\t</div>\n</div>";
export default template;
export const dependencies = [ d1, d2 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
