import { inject } from "aurelia";
import { IEventAggregator } from 'aurelia';
import { initAccordions } from "flowbite";

import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient, IEventAggregator)
export class HomePage {

  constructor(
    readonly zeusClient: ZeusClient,
    readonly ea: IEventAggregator
  ) { }

  attached() {
    initAccordions();
  }
}