import { inject } from "aurelia";
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { IEventAggregator } from 'aurelia';
import { IStorageService } from '@interfaces/storage/storage-interface';
import { nhost } from '@lib/nhost';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IConfirmAction } from '@components/confirm-action';

@inject(IAuthService, IZeusClient, IEventAggregator, IStorageService, IValidationRules, newInstanceForScope(IValidationController), IConfirmAction)
export class ChangePasswordComponent {
  private password: string;

  constructor(
    private auth: IAuthService,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private storageService: IStorageService,
    readonly validationRules: IValidationRules,
    readonly validationController: IValidationController,
    private confirmAction: IConfirmAction,
  ) {
    validationRules
      .on(this)
      .ensure('password')
      .minLength(9).withMessage('A senha deve conter no mínimo 9 caracteres')
      .required().withMessage('Informe a senha')
  }

  private async handleChangePassword(): Promise<void> {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    await this.confirmAction.open({
      type: "success",
      title: "Salvar senha",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        await nhost.auth.changePassword({
          newPassword: this.password
        });
      }
    });
  }
}