import { CustomElement } from '@aurelia/runtime-html';
export const name = "wordpress-success-page";
export const template = "<section>\n\n  <template if.bind=\"isSuccess\">\n    <div class=\"flex flex-col justify-center items-center\">\n      <span class=\"text-base font-semibold text-gray-400 dark:text-gray-400 pt-6\">Integração realizada com sucesso 🎉🎊</span>\n      <a href=\"/social-media\" class=\"mt-auto mb-0 text-base font-semibold leading-normal text-gray-400 dark:text-gray-400 pt-1 pb-3 group\">\n        Voltar para integrações\n        <i class=\"fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200\"></i>\n      </a>\n\n      <img src=\"assets/images/undraw_integration-success.svg\" alt=\"undraw\" class=\"h-80 mt-6\">\n    </div>\n  </template>\n  <template else>\n    <div class=\"flex flex-col justify-center items-center\">\n      <span class=\"text-base font-semibold text-gray-400 dark:text-gray-400 pt-6\">Erro ao realizar integração 🤕👎</span>\n      <a href=\"/social-media\" class=\"mt-auto mb-0 text-base font-semibold leading-normal text-gray-400 dark:text-gray-400 pt-1 pb-3 group\">\n        Voltar para integrações\n        <i class=\"fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200\"></i>\n      </a>\n\n      <img src=\"assets/images/undraw_integration-success.svg\" alt=\"undraw\" class=\"h-80 mt-6\">\n    </div>\n  </template>\n\n</section>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
