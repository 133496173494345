import { inject } from "aurelia";
import { newInstanceForScope } from '@aurelia/kernel';
import { IRouter } from '@aurelia/router';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes } from '@zeus';
import { IEventAggregator } from 'aurelia';
import type { ModalInterface, ModalOptions } from 'flowbite';
import { Modal } from 'flowbite';
import { ICreateModalService } from '../services/create-modal-service';

@inject(IRouter, IZeusClient, IEventAggregator, ICreateModalService, IValidationRules, newInstanceForScope(IValidationController))
export class CreateProjectModalComponent {
  private channel: string;

  private modal: ModalInterface;
  private modalId: string = crypto.randomUUID();

  private brands: ModelTypes['brands'][];
  private products: ModelTypes['products'][];
  private audiences: ModelTypes['audiences'][];

  private prompt: string;
  private selectedBrandId: string;
  private selectedProductId: string;
  private selectedAudienceId: string;

  constructor(
    private router: IRouter,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private service: ICreateModalService,
    private validationRules: IValidationRules,
    private validationController: IValidationController,
  ) {
    validationRules
      .on(this)
      .ensure('selectedBrandId')
      .required().withMessage('Selecionar uma marca é obrigatório')
  }

  async attached() {
    await this.handleGetBrands();

    const $modalElement: HTMLElement = document.querySelector(`#modal-${this.modalId}`);

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
      onShow: () => {
        this.prompt = '';
        this.selectedBrandId = '';
        this.selectedProductId = '';
        this.selectedAudienceId = '';
      }
    }

    this.modal = new Modal($modalElement, modalOptions);
  }

  public showModal(channel: string): void {
    this.channel = channel;

    this.modal.show();
  }

  private hideModal(): void {
    this.modal.hide();
  }

  private async handleGetBrands(): Promise<void> {
    this.brands = await this.service.brands();
    this.products = await this.service.products();
    this.audiences = await this.service.audiences();
  }

  private async handleRedirectToGeneration(): Promise<void> {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    this.hideModal();

    switch (this.channel) {
      case 'images':
        await this.router.load('/image', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'texts':
        await this.router.load('/text', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'posts':
        await this.router.load('/editor', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'videos':
        await this.router.load('/editor', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'prints':
        await this.router.load('/editor', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'presentations':
        await this.router.load('/editor', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'adverts':
        await this.router.load('/editor', {
          data: {
            brandId: this.selectedBrandId,
            productId: this.selectedProductId,
            audienceId: this.selectedAudienceId,
            prompt: this.prompt,
          },
        });
        break;
      case 'courses':
        alert('courses')
        break;
      default:
        break;
    }
  }
}