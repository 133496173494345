import { inject } from "aurelia";
import { bindable } from 'aurelia';
import { I18N } from '@aurelia/i18n';

interface BreadcrumbsProps {
  [key: string]: BreadcrumbProps[]
}

interface BreadcrumbProps {
  route?: string;
  translations: {
    [key: string]: string;
  }
}

@inject(I18N)
export class breadcrumbsComponent {
  @bindable breadcrumbId: string
  @bindable breadcrumbTitle: string | null = null

  constructor(private I18N: I18N) { }

  private breadcrumbs: BreadcrumbsProps = {
    projects: [
      {
        translations: {
          pt_BR: 'Projetos',
          en_US: 'Projects',
          es_ES: 'Proyectos',
        }
      }
    ],
    project: [
      {
        route: '/projects',
        translations: {
          pt_BR: 'Projetos',
          en_US: 'Projects',
          es_ES: 'Proyectos',
        },
      },
      {
        translations: {
          pt_BR: 'Projeto',
          en_US: 'Project',
          es_ES: 'Proyecto',
        },
      }
    ],
    calendar: [
      {
        translations: {
          pt_BR: 'Calendário',
          en_US: 'Calendar',
          es_ES: 'Calendario',
        }
      }
    ],
    audiences: [
      {
        translations: {
          pt_BR: 'Audiências',
          en_US: 'Audiences',
          es_ES: 'Audiencias',
        }
      }
    ],
    audience: [
      {
        route: '/audiences',
        translations: {
          pt_BR: 'Audiências',
          en_US: 'Audiences',
          es_ES: 'Audiencias',
        },
      },
      {
        translations: {
          pt_BR: 'Audiência',
          en_US: 'Audience',
          es_ES: 'Audiencia',
        },
      }
    ],
    brands: [
      {
        translations: {
          pt_BR: 'Marcas',
          en_US: 'Brands',
          es_ES: 'Marcas',
        }
      }
    ],
    brand: [
      {
        route: '/brands',
        translations: {
          pt_BR: 'Marcas',
          en_US: 'Brands',
          es_ES: 'Marcas',
        },
      },
      {
        translations: {
          pt_BR: 'Marca',
          en_US: 'Brand',
          es_ES: 'Marca',
        },
      }
    ],
    products: [
      {
        translations: {
          pt_BR: 'Produtos',
          en_US: 'Products',
          es_ES: 'Productos',
        }
      }
    ],
    product: [
      {
        route: '/products',
        translations: {
          pt_BR: 'Produtos',
          en_US: 'Products',
          es_ES: 'Productos',
        },
      },
      {
        translations: {
          pt_BR: 'Produto',
          en_US: 'Product',
          es_ES: 'Producto',
        },
      }
    ],
    instagram: [
      {
        translations: {
          pt_BR: 'Instagram',
          en_US: 'Instagram',
          es_ES: 'Instagram',
        }
      }
    ],
    facebook: [
      {
        translations: {
          pt_BR: 'Facebook',
          en_US: 'Facebook',
          es_ES: 'Facebook',
        }
      }
    ],
    linkedin: [
      {
        translations: {
          pt_BR: 'LinkedIn',
          en_US: 'LinkedIn',
          es_ES: 'LinkedIn',
        }
      }
    ],
    blog: [
      {
        translations: {
          pt_BR: 'Blog',
          en_US: 'Blog',
          es_ES: 'Blog',
        }
      }
    ],
    integrations: [
      {
        translations: {
          pt_BR: 'Integrações',
          en_US: 'Integrations',
          es_ES: 'Integraciones',
        }
      }
    ],
    partners: [
      {
        translations: {
          pt_BR: 'Parceiros',
          en_US: 'Partners',
          es_ES: 'Socios',
        }
      }
    ],
    tutorials: [
      {
        translations: {
          pt_BR: 'Tutoriais',
          en_US: 'Tutorials',
          es_ES: 'Tutoriales',
        }
      }
    ],
    faqs: [
      {
        translations: {
          pt_BR: 'Outras dúvidas',
          en_US: 'FAQs',
          es_ES: 'Preguntas frecuentes',
        }
      }
    ],
    myAccount: [
      {
        translations: {
          pt_BR: 'Minha conta',
          en_US: 'My account',
          es_ES: 'Mi cuenta',
        }
      }
    ],
    affiliated: [
      {
        translations: {
          pt_BR: 'Afiliado',
          en_US: 'Affiliated',
          es_ES: 'Asociado',
        }
      }
    ],
  }
}