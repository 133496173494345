import { bindable, capture } from "aurelia";
import { uuid4 } from '@helpers/crypto/crypto';
import { IFormInputOption } from "../interfaces/form-interface";

@capture((attrs) => attrs !== "id" && attrs !== "label" && attrs !== "validate")
export class SelectComponent {

  private id: string = uuid4();
  @bindable public label: string;
  @bindable public validate: boolean = false;
  @bindable public options: IFormInputOption[];
}
