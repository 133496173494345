import { inject } from "aurelia";
import { I18N } from '@aurelia/i18n';
import { IModelsData, modelsData } from './data'
import { BindingMode, bindable } from 'aurelia';
import { Dropdown, Popover } from 'flowbite';
import type { DropdownOptions, DropdownInterface, PopoverOptions, PopoverInterface, InstanceOptions } from 'flowbite';

@inject(I18N)
export class ModelsComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  private $popoverTargetEl: HTMLElement
  private $popoverTriggerEl: HTMLElement
  private popoverList: PopoverInterface[] = []

  private modelsData = modelsData;

  @bindable({ mode: BindingMode.twoWay }) selectedModel: IModelsData;

  constructor(private I18N: I18N) { }

  attached() {
    this.$targetEl = document.getElementById('dropdown-models');

    this.$triggerEl = document.getElementById('dropdown-models-button');

    const options: DropdownOptions = {
      placement: 'auto-start',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 25,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);

    this.initPopover();
  }

  detaching() {
    this.dropdown.destroy();
    this.popoverList.forEach(popover => popover.destroy());
  }

  private showDropdown(): void {
    this.dropdown.show();
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  private initPopover(): void {
    for (const model of this.modelsData) {
      this.$popoverTargetEl = document.getElementById(`popover-models-${model.id}`);
      this.$popoverTriggerEl = document.getElementById(`popover-models-button-${model.id}`);

      const options: PopoverOptions = {
        placement: 'top',
        triggerType: 'hover',
        offset: 10,
      };

      const instanceOptions: InstanceOptions = {
        id: `popover-models-${model.id}`,
        override: true,
      };

      if (this.$popoverTargetEl) {

        const popover: PopoverInterface = new Popover(
          this.$popoverTargetEl,
          this.$popoverTriggerEl,
          options,
          instanceOptions
        );

        this.popoverList.push(popover);

        popover.show();
      }
    }
  }

  private handleSelectedModel(model: IModelsData): void {
    this.selectedModel = model;
    this.hideDropdown();
  }
}