import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';
import { IWebsiteService } from '../../services/website-service';

@inject(IWebsiteService)
export class HomeModule {
  private website: ModelTypes['website']

  constructor(private websiteService: IWebsiteService) { }

  loading() {
    this.website = this.websiteService.websiteSubscription
  }

  @watch('websiteService.websiteSubscription')
  async handleDealSubscription(newData: ModelTypes['website'], oldData: ModelTypes['website']) {
    this.website = newData;
  }
}