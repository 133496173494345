import { DI } from 'aurelia';
import { inject } from "aurelia";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes, project_constraint, project_update_column } from '@zeus';

interface IblogPostGeneratorEnhancedForAmp {
  brief: string
  brandName: string
  productName: string
  format: string
  title: string
  voiceTone: string
  category: string
  textStyle: string
}

type projectInsertInput = ModelTypes['project_insert_input'] & {
  slug: string
}

type publishOrScheduleBlogType = {
  projectId: string
  scheduledDate?: string,
}

export interface ITextModuleService {
  brands(): Promise<Array<ModelTypes['brands']>>
  getProjectById(id: string): Promise<ModelTypes["project"]>
  products(): Promise<Array<ModelTypes['products']>>
  audiences(): Promise<Array<ModelTypes['audiences']>>
  insertProject(command: projectInsertInput): Promise<ModelTypes["project"]>
  blogPostGeneratorEnhancedForCopy(command: IblogPostGeneratorEnhancedForAmp): Promise<ModelTypes["blogPostGeneratorEnhancedForCopyOutput"]>
  publishOrScheduleBlog(command: publishOrScheduleBlogType): Promise<void>

  atualizar(): Promise<void>
}

@inject(IZeusClient)
class TextModuleService implements ITextModuleService {

  constructor(private zeusClient: ZeusClient) { }

  async getProjectById(id: string): Promise<ModelTypes["project"]> {
    const result = await this.zeusClient.chain('query')({
      project_by_pk: [
        { id: id },
        {
          id: true,
          alias: true,
          content: true,
          productId: true,
          featuredImageFileId: true,
          previewImageFileId: true,
        }
      ]
    });
    return result?.project_by_pk as ModelTypes["project"];
  }

  async brands(): Promise<Array<ModelTypes['brands']>> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.brands as Array<ModelTypes['brands']>;
  }

  async products(): Promise<Array<ModelTypes['products']>> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {},
        {
          id: true,
          alias: true,
        },
      ]
    });

    return result?.products as Array<ModelTypes['products']>;
  }

  async audiences(): Promise<Array<ModelTypes['audiences']>> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.audiences as Array<ModelTypes['audiences']>;
  }

  async insertProject(command: projectInsertInput): Promise<ModelTypes["project"]> {
    const result = await this.zeusClient.chain('mutation')({
      insert_project_one: [
        {
          object: {
            id: command.id,
            alias: command.alias,
            rawText: command.rawText,
            content: command.content,
            previewImageFileId: command.featuredImageFileId,
            featuredImageFileId: command.featuredImageFileId,
            brandId: command.brandId,
            blogCategoryId: '386b5c6a-8a10-4d78-aba0-1b6002768d72',
            slug: command.slug,
            caption: command.content.substring(0, 160),
            metaDescription: command.content.substring(0, 160),
            type: 'blog',
            creditPrice: 0,
            productId: command.productId,
          },
          on_conflict: {
            constraint: project_constraint.project_pkey,
            update_columns: [
              project_update_column.id,
              project_update_column.alias,
              project_update_column.rawText,
              project_update_column.alias,
              project_update_column.content,
              project_update_column.previewImageFileId,
              project_update_column.featuredImageFileId,
              project_update_column.brandId,
              project_update_column.blogCategoryId,
              project_update_column.slug,
              project_update_column.caption,
              project_update_column.metaDescription,
              project_update_column.type,
              project_update_column.creditPrice,
              project_update_column.productId,
            ]
          }
        },
        {
          id: true
        }
      ]
    });

    return result?.insert_project_one as ModelTypes["project"];
  }

  async publishOrScheduleBlog(command: publishOrScheduleBlogType): Promise<void> {
    try {
      const result = await this.zeusClient.chain('mutation')({
        publishOrScheduleBlog: [
          {
            projectId: command.projectId,
            ...command.scheduledDate && { scheduledDate: command.scheduledDate },
          },
          {
            success: true,
            errorMessage: true,
          }
        ]
      });

      if (!result?.publishOrScheduleBlog?.success) throw new Error(result.publishOrScheduleBlog.errorMessage);
    } catch (error) {
      throw new Error(error);
    }
  }

  async blogPostGeneratorEnhancedForCopy(command: IblogPostGeneratorEnhancedForAmp): Promise<ModelTypes["blogPostGeneratorEnhancedForCopyOutput"]> {
    const mutation = await this.zeusClient.chain('mutation')({
      blogPostGeneratorEnhancedForCopy: [
        {
          brief: command.brief,
          brandName: command.brandName,
          productName: command.productName,
          format: command.format,
          title: command.title,
          voiceTone: command.voiceTone,
          category: command.category,
          textStyle: command.textStyle
        },
        true
      ]
    });

    const result: { output: ModelTypes['blogPostGeneratorEnhancedForCopyOutput'] } = await new Promise((resolve) => {
      const sub = this.zeusClient.subscription('subscription')({
        blogPostGeneratorEnhancedForCopy: [
          {
            id: mutation?.blogPostGeneratorEnhancedForCopy
          },
          {
            output: {
              html: true
            }
          }
        ]
      });

      sub.error((err) => console.error(err));

      sub.on(({ blogPostGeneratorEnhancedForCopy }: { blogPostGeneratorEnhancedForCopy: { output: ModelTypes['blogPostGeneratorEnhancedForCopyOutput'] } }) => {
        if (blogPostGeneratorEnhancedForCopy?.output) {
          resolve(blogPostGeneratorEnhancedForCopy);
        }
      });
    });

    return result.output as ModelTypes["blogPostGeneratorEnhancedForCopyOutput"];
  }

  async atualizar(): Promise<void> {
    const blogQuery = await this.zeusClient.chain('query')({
      blog: [
        {
          where: {
            atualizado: { _eq: false }
          }
        },
        {
          id: true,
          title: true,
          slug: true,
          content: true,
          brandId: true,
          categoryId: true,
          metaDescription: true,
          projectId: true,
          publishDate: true,
          scheduledDate: true,
        }
      ]
    });

    const blog = blogQuery?.blog as Array<ModelTypes["blog"]>;

    let count: number = 0;

    for (const item of blog) {
      await this.zeusClient.chain('mutation')({
        update_project_by_pk: [
          {
            pk_columns: { id: item.projectId },
            _set: {
              alias: item.title,
              caption: item.metaDescription,
              brandId: item.brandId,
              blogCategoryId: item.categoryId,
              slug: item.slug,
              metaDescription: item.metaDescription,
              scheduledDate: item.scheduledDate,
              publishDate: item.publishDate,
              content: item.content,
            }
          },
          {
            id: true
          }
        ],

        update_blog_by_pk: [
          {
            pk_columns: { id: item.id },
            _set: {
              atualizado: true
            }
          },
          {
            id: true
          }
        ]
      });

      count++;

      console.log(`Atualizado ${count} de ${blog.length}`);
    }
  }
}

export const ITextModuleService = DI.createInterface<ITextModuleService>(x => x.singleton(TextModuleService));
