import { inject } from "aurelia";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes } from '@zeus';
import { DI } from 'aurelia';

export interface IImagineService {
  brands(): Promise<Array<ModelTypes['brands']>>
  products(): Promise<Array<ModelTypes['products']>>
  audiences(): Promise<Array<ModelTypes['audiences']>>
  imagine(command: { prompt: string, aspectRatio: string }): Promise<ModelTypes["CreateImageUsingImagineOutput"]>
}

@inject(IZeusClient)
export class ImagineService implements IImagineService {

  constructor(private zeusClient: ZeusClient) { }

  async brands(): Promise<Array<ModelTypes['brands']>> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.brands as Array<ModelTypes['brands']>;
  }

  async products(): Promise<Array<ModelTypes['products']>> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {},
        {
          id: true,
          alias: true,
        },
      ]
    });

    return result?.products as Array<ModelTypes['products']>;
  }

  async audiences(): Promise<Array<ModelTypes['audiences']>> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.audiences as Array<ModelTypes['audiences']>;
  }

  async imagine(command: { prompt: string, aspectRatio: string }): Promise<ModelTypes["CreateImageUsingImagineOutput"]> {
    const mutation = await this.zeusClient.chain('mutation')({
      createImageUsingImagine: [
        {
          prompt: command.prompt,
          aspectRatio: command.aspectRatio,
        },
        true
      ]
    });

    const result: { output: ModelTypes["CreateImageUsingImagineOutput"] } = await new Promise((resolve) => {
      const subscription = this.zeusClient.subscription('subscription')({
        createImageUsingImagine: [
          {
            id: mutation?.createImageUsingImagine
          },
          {
            output: {
              fileId: true,
              success: true,
              errorMessage: true,
            }
          }
        ]
      });

      subscription.error((error) => console.log('createImageUsingImagine  error', error));

      subscription.on(({ createImageUsingImagine }: { createImageUsingImagine: { output: ModelTypes['CreateImageUsingImagineOutput'] } }) => {
        if (createImageUsingImagine?.output) {
          resolve(createImageUsingImagine);
        }
      });
    });

    return result.output as ModelTypes["CreateImageUsingImagineOutput"];
  }

}

export const IImagineService = DI.createInterface<IImagineService>(x => x.singleton(ImagineService));