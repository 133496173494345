import { CustomElement } from '@aurelia/runtime-html';
export const name = "integration-card-component";
export const template = "<div class=\"h-full rounded-2xl border border-gray-100 dark:border-gray-600\">\n  <div class=\"flex flex-col h-full\">\n\n    <div class=\"flex items-center gap-2 p-4\">\n      <i class=\"${integration.icon} text-xl text-gray-500 dark:text-gray-400\"></i>\n      <h3 class=\"text-sm font-bold tracking-tight text-gray-900 dark:text-white\">${integration.name}</h3>\n    </div>\n\n    <div class=\"border-y dark:border-gray-700 p-4 grow\">\n      <p class=\"text-xs font-normal text-gray-700 dark:text-gray-400 \">${integration.description}</p>\n    </div>\n\n    <div class=\"flex justify-end items-center p-4\">\n      <button type=\"button\" click.trigger=\"configureIntegration()\" class=\"btn-primary\">\n        Configurar\n        <i class=\"fa-solid fa-arrow-right ml-2\"></i>\n      </button>\n    </div>\n\n  </div>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
