import { IAspectRatioData, aspectRatioData } from './data'
import { BindingMode, bindable } from 'aurelia';
import { Dropdown, type DropdownOptions, type DropdownInterface } from 'flowbite';

export class AspectRatioComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  private aspectRatioData = aspectRatioData;

  @bindable({ mode: BindingMode.twoWay }) selectedAspectRatio: IAspectRatioData;

  attached() {
    this.$targetEl = document.getElementById('dropdown-aspect-ratio');

    this.$triggerEl = document.getElementById('dropdown-aspect-ratio-button');

    const options: DropdownOptions = {
      placement: 'auto-start',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 25,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);
  }

  private showDropdown(): void {
    this.dropdown.show();
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  private handleSelectedAspectRatio(aspectRatio: IAspectRatioData): void {
    this.selectedAspectRatio = aspectRatio;
    this.hideDropdown();
  }
}