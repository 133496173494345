import { inject } from 'aurelia';
import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';

export type ConfirmDialogModel = {
  title: string;
  message: string;
  confirmText: string;
  type: 'success' | 'danger' | 'warning';

  confirmCallback: () => Promise<void>;
}

@inject(IDialogDom)
export class ConfirmActionDialog implements IDialogCustomElementViewModel {
  private title: string;
  private message: string;
  private confirmText: string;

  private btn: string;
  private icon: string;

  private confirmCallback: () => Promise<void>;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom
  ) {
    dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    dialogDom.contentHost.className = 'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex'
  }

  async activate(model: ConfirmDialogModel) {
    this.title = model.title;
    this.message = model.message;
    this.confirmText = model.confirmText;

    this.confirmCallback = model.confirmCallback;

    const { btn, icon } = this.handleBtnClass(model.type);

    this.btn = btn;
    this.icon = icon;
  }

  handleBtnClass(type: string): { btn: string, icon: string } {
    switch (type) {
      case 'success':
        return { btn: 'btn-success', icon: 'mdi mdi-check-circle-outline' };
      case 'danger':
        return { btn: 'btn-danger', icon: 'mdi mdi-close-octagon' };
      case 'warning':
        return { btn: 'btn-warning', icon: 'mdi mdi-alert' };
      default:
        return { btn: 'btn-success', icon: 'mdi mdi-check-circle-outline' };
    }
  }

  async handleConfirm(): Promise<void> {
    await this.confirmCallback();

    await this.$dialog.ok();
  }
}