import { valueConverter } from 'aurelia';

@valueConverter('creditToBRL')
export class CreditToBRLFormatConverter {
  toView(value: number, discaunt: number = 0) {

    if (!value) {
      return value;
    }

    return (value * ((1 - discaunt) * 0.1));
  }
}