import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "./../../components/header/header-component.ts";
export const name = "contact-section";
export const template = "\n\n<section class=\"p-4 flex flex-col justify-between h-[100px]\">\n\n  <div class=\"flex flex-col\">\n    <header-component title=\"Dados do Lead\" subtitle=\"Dados de contato do potencial cliente\"></header-component>\n\n    <div class=\"flex flex-row justify-center my-3 gap-2\">\n      <button type=\"button\" click.trigger=\"sendEmail()\"\n        class=\"btn-neutral flex justify-center items-center gap-2 w-6/12\">\n        <i class=\"fa-regular fa-envelope\"></i>\n        E-mail\n      </button>\n\n      <button type=\"button\" click.trigger=\"showWhatsApp()\"\n        class=\"btn-neutral flex justify-center items-center gap-2 w-6/12\">\n        <i class=\"fa-brands fa-whatsapp\"></i>\n        Whatsapp\n      </button>\n    </div>\n\n    <div class=\"flex flex-col gap-3 mt-1\">\n      <!-- begin::name -->\n      <div class=\"flex-1\">\n        <label for=\"name\" class=\"form-label\">Nome</label>\n        <input type=\"text\" id=\"name\" value.bind=\"opportunity.name\" class=\"form-input\" readonly>\n      </div>\n      <!-- end::name -->\n\n      <!-- begin::email -->\n      <div class=\"flex-1\">\n        <label for=\"email\" class=\"form-label\">E-mail</label>\n        <input type=\"text\" id=\"email\" value.bind=\"opportunity.email\" class=\"form-input\" readonly>\n      </div>\n      <!-- end::email -->\n\n      <!-- begin::phone -->\n      <div class=\"flex-1\">\n        <label for=\"phone\" class=\"form-label\">Telefone</label>\n        <input type=\"text\" id=\"phone\" value.bind=\"opportunity.phone | phone\" class=\"form-input\" readonly>\n      </div>\n      <!-- end::phone -->\n    </div>\n  </div>\n\n</section>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
