import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from "@aurelia/dialog";
import { IRouter } from "@aurelia/router";
import { ICustomElementController } from "@aurelia/runtime-html";
import { inject } from "aurelia";

@inject(IDialogDom, IRouter)
export class GoPremiumDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom,
    private router: IRouter
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  navigateToSubscriptionAndPlans() {
    this.router.load('subscription-and-plans');
    this.$dialog.ok();
  }
}