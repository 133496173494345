import { inject } from "aurelia";
import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { actionsFromPublish } from '@data/index';
import moment from 'moment';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ITextModuleService } from '../../services/text-module-services';
import { toastifyError, toastifySuccess, toastifyWarning } from '@helpers/toastify';
import { GoogleAnalyticsEventAction, GoogleAnalyticsEventCategory, GoogleAnalyticsHelper } from "@helpers/google-analytics/google-analytics-helper";

@inject(IZeusClient, IDialogDom, ITextModuleService,)
export class SaveTextDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private projectId: string;
  private selectedAction: string;
  private scheduleDate: string;

  private minDate: string;
  private actionsFromPublishData = actionsFromPublish;

  constructor(
    private zeusClient: ZeusClient,
    private dialogDom: DefaultDialogDom,
    private service: ITextModuleService,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: { projectId: string }) {
    this.projectId = model.projectId;

    this.minDate = moment().format('YYYY-MM-DDTHH:mm');
  }

  async close() {
    this.$dialog.ok();
  }

  private validateForm() {
    if (!this.selectedAction) return false;

    if (this.selectedAction === 'schedule' && !this.scheduleDate) return false;

    return true;
  }

  private async handleSaveTemplate(): Promise<void> {
    if (!this.validateForm()) {
      toastifyWarning({ message: 'Preencha todos os campos' });
      return;
    }

    try {
      await this.service.publishOrScheduleBlog({
        projectId: this.projectId,
        ...this.selectedAction === 'schedule' && { scheduledDate: moment(this.scheduleDate).toISOString() },
      });

      toastifySuccess({ message: this.selectedAction === 'schedule' ? 'Post agendado com sucesso' : 'Post publicado com sucesso' });
      GoogleAnalyticsHelper.trackEvent(GoogleAnalyticsEventAction.Click, GoogleAnalyticsEventCategory.Article, this.selectedAction === 'schedule' ? 'scheduled-post' : 'published-post');

      this.$dialog.ok();
    } catch (error) {
      toastifyError({ message: error });
    }
  }
}