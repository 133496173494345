export interface IAspectRatioData {
  id: string;
  value: string;
  text: string;
}

export const aspectRatioData: IAspectRatioData[] = [
  { id: '1', value: '1:1', text: '1:1' },
  { id: '2', value: '9:16', text: '9:16' },
  { id: '3', value: '16:9', text: '16:9' },
  { id: '4', value: '4:3', text: '4:3' },
  { id: '5', value: '3:2', text: '3:2' },
  { id: '6', value: '3:4', text: '3:4' },
  { id: '7', value: '2:1', text: '2:1' },
]