import { BindingMode, bindable } from "aurelia";

export class BackgroundImageComponent {
  @bindable productId: string;
  @bindable displayDimensionId: string;

  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;
  // @bindable({ mode: BindingMode.twoWay }) currentImageTabIndex: number = 0;
  private tab: number = 0;

  constructor() { }

  attached() {

  }

  detaching() {
    this.productId = null;
    this.selectedImage = null;
    this.tab = 0;
  }

  setTab(index: number) {
    this.selectedImage = null;

    this.tab = index;
  }
}