import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';

@inject(IDialogDom)
export class FileViewerDialog implements IDialogCustomElementViewModel {
  private index: number;
  private files: ModelTypes['files'][];

  private deleteFileCallback: (id: string) => void;

  private storageUrl = import.meta.env.VITE_STORAGE_URL;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  detached(): void {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  async activate(model?: { files: ModelTypes['files'][], index: number, deleteFile: (id: string) => void }) {
    this.files = model.files;
    this.index = model.index;
    this.deleteFileCallback = model.deleteFile;
  }

  async closeAndSubmit() {
    this.$dialog.ok();
  }

  private deleteFile() {
    const id = this.files[this.index].id;

    this.deleteFileCallback(id);

    this.$dialog.ok();
  }

  private prevButton(): void {
    if (this.index === 0) return;

    this.index = (this.index - 1 + this.files.length) % this.files.length;
  }

  private nextButton(): void {
    if (this.index === this.files.length - 1) return;

    this.index = (this.index + 1) % this.files.length;
  }

  private handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowLeft') {
      this.prevButton();
    }

    if (event.key === 'ArrowRight') {
      this.nextButton();
    }

    // Close dialog
    if (event.key === 'Escape') {
      this.$dialog.ok();
    }
  }
}