import { inject } from "aurelia";

import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { nhost } from '@lib/nhost';

@valueConverter('signedFileFromId')
@inject(I18N)
export class SignedFileFromIdConverter {
  constructor(private I18N: I18N) { }

  async toView(fileId: string) {
    if (!fileId) return 'https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80';

    const { presignedUrl } = await nhost.storage.getPresignedUrl({ fileId })
    return presignedUrl.url;
  }
}