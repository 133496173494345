import { inject } from "aurelia";
import { I18N } from "@aurelia/i18n";
import { BindingMode, bindable } from 'aurelia';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(I18N, IZeusClient)
export class PexelsComponent {
  @bindable query: string;
  @bindable displayDimensionId: string;
  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;

  private selectedImageId: string;
  private pexelImages: any[] = [];

  private currentPage: number = 1;
  private loading: boolean = false;

  constructor(
    private I18N: I18N,
    private zeusClient: ZeusClient
  ) { }

  queryChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {

      this.pexelImages = [];

      this.searchImagesFromPexels(newValue, this.currentPage);
    }
  }

  private async searchImagesFromPexels(query: string, currentPage: number) {
    if (!query) return;

    this.loading = true;
    const result = await this.zeusClient.searchImagesFromPexels({ query, locale: this.I18N.getLocale(), orientationType: this.setOrientationType(), page: this.currentPage });

    this.selectedImageId = null;
    this.pexelImages = [...this.pexelImages, ...result.images];

    this.loading = false;
  }

  private async loadMore(scrollContext: { target: { scrollTop: number; scrollHeight: number; offsetHeight: number; }; }) {
    if (scrollContext.target.scrollTop > (scrollContext.target.scrollHeight - scrollContext.target.offsetHeight) - 2) {
      await this.searchImagesFromPexels(this.query, ++this.currentPage);
    }
  }

  private selectPexelsImage(id: string) {
    this.selectedImage = this.pexelImages.find(i => i.id === id).url;
    this.selectedImageId = id;
  }

  private setOrientationType(): string {
    switch (this.displayDimensionId) {
      case '1c0ed9b4-c2b3-4f8f-b0bb-d66f2cd1db4c':
        return 'square';
      case 'b4a03232-bbc6-466b-8c4d-11c7db66ab50':
        return 'portrait';
      case '3fc9c426-ecfb-48aa-84f8-5e55deaeb2e8':
        return 'landscape';

      default:
        return 'landscape'
    }
  }

  detaching() {
    this.selectedImage = null;
    this.pexelImages = [];
    this.query = null;
  }
}