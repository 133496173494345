import { inject } from "aurelia";
import { lifecycleHooks } from 'aurelia';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { Parameters, Navigation, RoutingInstruction } from '@aurelia/router';

@lifecycleHooks()
@inject(IAuthService)
export class AuthorizationHook {

  constructor(private auth: IAuthService) { }

  canLoad(viewModel, params: Parameters, instruction: RoutingInstruction, navigation: Navigation) {
    const requiredRoles = instruction.route.match.data.requiredRoles;
    if (!requiredRoles) {
      return true;
    }
    if (requiredRoles?.includes(this.auth.user.defaultRole)) {
      return true;
    } else {
      return '/';
    }

  }
}