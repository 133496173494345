import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/card-title/card-title-component";
import * as d1 from "@components/card-header/card-header-component";
import * as d2 from "@components/promise-bind/error/error-component";
import * as d3 from "@components/promise-bind/pending/pending-component";
import * as d4 from "./../../components/card-component.ts";
export const name = "image-templates-page";
export const template = "\n\n\n\n\n\n\n<section>\n\n  <card-title-component title-id=\"images\"></card-title-component>\n\n  <div class=\"flex gap-4\">\n\n    <div class=\"grow\">\n\n      <template promise.bind=\"getNewTemplateType(channel)\">\n\n        <template pending>\n          <pending-component></pending-component>\n        </template>\n\n        <template then.from-view=\"data\">\n          <div class=\"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6\">\n            <template repeat.for=\"item of data\">\n              <card-component template.bind=\"item\" action.bind=\"() => handleRedirect()\"></card-component>\n            </template>\n          </div>\n        </template>\n\n        <template catch.from-view=\"error\">\n          <error-component></error-component>\n        </template>\n\n      </template>\n\n    </div>\n  </div>\n\n</section>";
export default template;
export const dependencies = [ d0, d1, d2, d3, d4 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
