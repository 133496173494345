import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/spinner-button/spinner-button-component";
export const name = "advanced-step-component";
export const template = "\n\n<div\n\tclass=\"overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full\">\n\t<div class=\"relative p-4 w-full max-w-2xl max-h-full\">\n\t\t<div class=\"relative bg-white shadow dark:bg-gray-700\">\n\n\t\t\t<div class=\"p-6 text-center\">\n\t\t\t\t<!-- icon -->\n\t\t\t\t<svg aria-hidden=\"true\" class=\"mx-auto mb-4 text-gray-500 w-14 h-14 dark:text-gray-200\" fill=\"none\"\n\t\t\t\t\tstroke=\"currentColor\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n\t\t\t\t\t<path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"\n\t\t\t\t\t\td=\"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z\"></path>\n\t\t\t\t</svg>\n\n\t\t\t\t<!-- message -->\n\t\t\t\t<h3 class=\"mb-5 text-lg font-normal text-gray-500 dark:text-gray-400\">Gostaria de avançar etapa?</h3>\n\n\t\t\t\t<!-- beggin::actions -->\n\t\t\t\t<div class=\"flex flex-row justify-center gap-2\">\n\t\t\t\t\t<button click.trigger=\"closeModal()\" data-modal-hide=\"alert-modal\" type=\"button\"\n\t\t\t\t\t\tclass=\"btn-neutral\">Cancelar</button>\n\t\t\t\t\t<spinner-button-component label=\"Avançar\"\n\t\t\t\t\t\ttrigger-function.bind=\"() => handleAdvanceStep()\"></spinner-button-component>\n\t\t\t\t</div>\n\t\t\t\t<!-- end::actions -->\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
