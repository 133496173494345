import { inject } from "aurelia";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient)
export class ProjectsCreatedComponent {
  constructor(private zeusClient: ZeusClient) { }

  private async getBrandAggregate() {
    const result = await this.zeusClient.getProductsAggregate();

    return result;
  }
}