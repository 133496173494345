import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/card-title/card-title-component";
import * as d1 from "@components/accordion/accordion-component";
export const name = "faq-page";
export const template = "\n\n\n<section>\n  <card-title-component title-id=\"faq\"></card-title-component>\n\n  <div class=\"flex flex-col lg:flex-row w-full gap-4 mt-8 min-h-[calc(100vh-14rem)] max-h-[calc(100vh-14rem)] h-full overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300\">\n    <div class=\"w-full xl:w-[40%] 2xl:w-[25%] overflow-x-auto lg:overflow-hidden card p-4\">\n\n      <ul class=\"flex flex-row lg:flex-col text-sm font-medium mb-4\">\n        <template repeat.for=\"item of faqs\">\n          <li click.trigger=\"changeCategory($index)\" class=\"mr-2 shrink-0\">\n            <span class=\"flex w-full px-4 py-2 gap-4 cursor-pointer rounded-md ${$index === currentCategoryIndex ? 'text-white bg-secondary-600 font-bold' : 'hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white text-gray-500 dark:text-gray-400'}\">\n              <i class=\"${item.icon}\"></i>\n              ${item.name}\n            </span>\n          </li>\n        </template>\n      </ul>\n\n    </div>\n\n    <div class=\"w-full xl:w-[60%] 2xl:w-[75%]\">\n      <div class=\"flex flex-col gap-4\">\n\n        <template repeat.for=\"item of faqs[currentCategoryIndex].questions\">\n          <accordion-component title.bind=\"item.question\">\n            <div au-slot=\"body\">\n              <span class=\"text-sm text-[#808488]\">${item.answer}</span>\n            </div>\n          </accordion-component>\n        </template>\n\n      </div>\n    </div>\n\n  </div>\n</section>";
export default template;
export const dependencies = [ d0, d1 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
