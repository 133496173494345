import { IRoute } from '@aurelia/router';
import { BlogPage } from '../../app/private/pages/blog/blog-page';
import { BrandGuidePage } from '../../app/private/pages/brand-guide/brand-guide-page';
import { CalendarPage } from '../../app/private/pages/calendar/calendar-page';
import { ImagineModule } from '../../app/private/pages/creations/modules/imagine/imagine-module';
import { PolotnoModule } from '../../app/private/pages/creations/modules/polotno/polotno-module';
import { TextModule } from '../../app/private/pages/creations/modules/text/text-module';
import { FaqPage } from '../../app/private/pages/faq/faq-page';
import { HomePage } from '../../app/private/pages/home/home-page';
import { OpportunityManagementKanbanPage } from '../../app/private/pages/kanbans/pages/opportunity-management/opportunity-management-kanban-page';
import { MediaPage } from '../../app/private/pages/media/media-page';
import { MyAccount } from '../../app/private/pages/my-account/my-account';
import { OpportunityPage } from '../../app/private/pages/opportunity/opportunity-page';
import { PaymentSuccessPage } from '../../app/private/pages/payment-success/payment-success-page';
import { ProductPage } from '../../app/private/pages/products/pages/product-page';
import { ProductsPage } from '../../app/private/pages/products/products-page';
import { ProjectsPage } from '../../app/private/pages/projects/projects-page';
import { FacebookSuccessPage } from '../../app/private/pages/social-media/pages/facebook/facebook-success-page';
import { InstagramSuccessPage } from '../../app/private/pages/social-media/pages/instagram/instagram-success-page';
import { LinkedInSuccessPage } from '../../app/private/pages/social-media/pages/linkedIn/linkedIn-success-page';
import { WordpressSuccessPage } from '../../app/private/pages/social-media/pages/wordpress/wordpress-success-page';
import { SocialMediaPage } from '../../app/private/pages/social-media/social-media-page';
import { AdvertTemplatesPage } from '../../app/private/pages/templates/pages/advert-templates/advert-templates-page';
import { ImageTemplatesPage } from '../../app/private/pages/templates/pages/image-templates/image-templates-page';
import { PostTemplatesPage } from '../../app/private/pages/templates/pages/post-templates/post-templates-page';
import { TextTemplatesPage } from '../../app/private/pages/templates/pages/text-templates/text-templates-page';
import { VideoTemplatesPage } from '../../app/private/pages/templates/pages/video-templates/video-templates-page';
import { TutorialsPage } from '../../app/private/pages/tutorials/tutorials-page';
import { WebsiteConfig } from '../../app/private/pages/website-config/website-config';
import { TemplateManagementPage } from '../../app/private/pages/template-management/template-management-page';
import { TemplateEditorPage } from '../../app/private/pages/template-management/pages/template-editor/template-editor-page';
import { UserSubscriptionComponent } from '../../app/private/pages/subscription-and-plans/components/user-subscription/user-subscription-component';
import { OtpPage } from '../../app/public/pages/otp/otp-page';
import { LoginOtpPage } from '../../app/public/pages/login-otp/login-otp-page';

const publicRoutes: IRoute[] = [
  {
    path: 'login',
    title: 'Login',
    component: LoginOtpPage,
    data: {
      private: false
    }
  },
  {
    path: 'otp',
    title: 'OTP',
    component: OtpPage,
    data: {
      private: false
    }
  }
]

const workspaceRoutes: IRoute[] = [
  {
    path: ['', '/'],
    title: 'Home',
    component: HomePage,
    data: {
      id: '455c4f52-8803-43f0-8318-ec83cf621614',
      private: true,
    }
  },
  {
    path: 'calendar',
    title: 'Calendar',
    component: CalendarPage,
    data: {
      id: '413604b7-b7ce-4bf8-b423-d4959ec05922',
      private: true,
    }
  },
  {
    path: 'projects',
    title: 'Projects',
    component: ProjectsPage,
    data: {
      id: '2626615c-18f4-4ea4-98ff-9274f949842c',
      private: true,
    }
  },
  {
    path: 'blog',
    title: 'Blog',
    component: BlogPage,
    data: {
      id: '623e0798-9184-4cae-a67a-4fbdaab6c85d',
      private: true,
    }
  },
  {
    path: 'media',
    title: 'Media',
    component: MediaPage,
    data: {
      id: '2590c54a-7003-47de-8212-a4f5f3fb2fd2',
      private: true,
    }
  },
  {
    path: 'social-media',
    title: 'Social media',
    component: SocialMediaPage,
    data: {
      id: 'fa01a9da-f605-42f3-a475-53db1c0bc0a6',
      private: true,
    }
  },
  {
    path: 'wordpress-success',
    title: 'Wordpress Success',
    component: WordpressSuccessPage,
    data: {
      id: '5a8da7d0-97be-443d-8f0a-f28ab9909eeb',
      private: true,
    }
  },
  {
    path: 'facebook-success',
    title: 'Facebook Success',
    component: FacebookSuccessPage,
    data: {
      id: 'b936ce3d-2ba1-4243-9de9-dc905bc29dfe',
      private: true,
    }
  },
  {
    path: 'instagram-success',
    title: 'Instagram Success',
    component: InstagramSuccessPage,
    data: {
      id: 'eea03919-6162-42d7-b041-10761503ba2b',
      private: true,
    }
  },
  {
    path: 'linkedin-success',
    title: 'linkedIn Success',
    component: LinkedInSuccessPage,
    data: {
      id: '28169949-4903-4ac6-98bd-36037424a9ff',
      private: true,
    }
  },
  {
    path: 'brand-guide',
    title: 'Brand Guide',
    component: BrandGuidePage,
    data: {
      id: '577485d9-d9bd-4baf-9261-d0ff848ccc12',
      private: true,
    }
  }
]

const salesToolsRoutes: IRoute[] = [
  {
    path: 'products',
    title: 'Products',
    component: ProductsPage,
    data: {
      id: 'b18962b1-30ab-418a-8c47-2cbd4aa17c92',
      private: true,
    }
  },
  {
    path: 'product/:id?',
    title: 'Products',
    component: ProductPage,
    data: {
      id: '847979ed-118b-4ff7-a912-112e24b5ee4b',
      private: true,
    }
  },
  {
    path: 'opportunity-management',
    title: 'Opportunity Management',
    component: OpportunityManagementKanbanPage,
    data: {
      id: 'c5287143-67e9-48f5-a49d-af64b1a4aac9',
      private: true,
    }
  },
  {
    path: 'oportunidade/:id',
    title: 'Oportunidade',
    component: OpportunityPage,
    data: {
      id: 'd6cd5d46-d68e-48e4-9c9d-cc0ca739973b',
      private: true
    }
  },
  {
    path: 'text-templates',
    title: 'Text Templates',
    component: TextTemplatesPage,
    data: {
      id: '441f1095-0eed-4629-97cb-4557feb4eb01',
      private: true,
      requiredRoles: ['copymagico-admin', 'user-freetrial', 'user-premium']

    }
  },
  {
    path: 'post-templates',
    title: 'Post Templates',
    component: PostTemplatesPage,
    data: {
      id: 'c73706b5-dbc9-4559-b64e-1897283025da',
      private: true
    }
  },
  {
    path: 'editor/:id?',
    title: 'Editor',
    component: PolotnoModule,
    data: {
      id: '9e569e49-57d8-41d5-8c8f-7a1a9fc73a00',
      private: true,
      requiredRoles: ['copymagico-admin', 'user-freetrial', 'user-premium']
    }
  },
  {
    path: 'text/:id?',
    title: 'Text',
    component: TextModule,
    data: {
      id: '57225091-f273-4af3-be2a-253d0f46a1c9',
      private: true,
      requiredRoles: ['copymagico-admin', 'user-freetrial', 'user-premium']
    }
  },
  {
    path: 'meu-site',
    title: 'Meu Site',
    component: WebsiteConfig,
    data: {
      id: '30786b6c-d274-4ca5-88e1-2698ab8fc7ae',
      private: true,
    }
  }
]

const creationsRoutes: IRoute[] = [
  {
    path: 'image-templates',
    title: 'Image Templates',
    component: ImageTemplatesPage,
    data: {
      id: '19c62f81-f5eb-4ebf-8547-54dc7b834dde',
      private: true,
    }
  },
  {
    path: 'video-templates',
    title: 'Video Templates',
    component: VideoTemplatesPage,
    data: {
      id: '25e6c4b8-2470-4ee9-9606-067a3f3a758c',
      private: true,
    }
  },
  {
    path: 'advert-templates',
    title: 'Advert Templates',
    component: AdvertTemplatesPage,
    data: {
      id: '9c412438-bd8b-486a-bd03-5e8cd8a58b0a',
      private: true,
    }
  },
  {
    path: 'image',
    title: 'Image',
    component: ImagineModule,
    data: {
      id: 'fad08527-6cff-4970-9b8f-da9a9e1c03a5',
      private: true,
      requiredRoles: ['copymagico-admin', 'user-freetrial', 'user-premium']
    }
  }
]

const educationCenterRoutes: IRoute[] = [
  {
    path: 'faq',
    title: 'FAQ',
    component: FaqPage,
    data: {
      id: '529597a8-1c0d-47bf-9a78-e41a8c5ff663',
      private: true,
    }
  },
  {
    path: 'tutorials',
    title: 'Tutorials',
    component: TutorialsPage,
    data: {
      id: '19236484-aed9-40e2-8782-2d45c5567fc5',
      private: true,
    }
  },
]

const othersRoutes: IRoute[] = [
  {
    path: 'payment-success',
    title: 'Payment success',
    component: PaymentSuccessPage,
    data: {
      id: '25de3f68-6b73-45ab-8b9d-6e0f41a68b84',
      private: true,
    }
  },
  {
    path: 'my-account',
    title: 'My Account',
    component: MyAccount,
    data: {
      id: '922cfec2-3012-4d5e-9202-efd5256f3719',
      private: true,
    }
  },
  {
    path: 'subscription-and-plans',
    title: 'Subscription and Plans',
    component: UserSubscriptionComponent,
    data: {
      id: '3d3f441a-c49e-4291-98cd-1f066f8e412c',
      private: true,
    }
  }
]

const superAdminRoutes: IRoute[] = [
  {
    path: 'gestao-templates',
    title: 'Gestão de Templates',
    component: TemplateManagementPage,
    data: {
      id: 'e10ff062-886f-4165-a547-151fd7920541',
      private: true,
    }
  },
  {
    path: 'template-editor/:id?',
    title: 'Template Editor',
    component: TemplateEditorPage,
    data: {
      id: '49acb292-97c9-4873-b653-83e0a1fb8319',
      private: true,
      requiredRoles: ['copymagico-admin', 'user-freetrial', 'user-premium']
    }
  },
]

export const appRoutes: IRoute[] = [
  ...publicRoutes,
  ...workspaceRoutes,
  ...salesToolsRoutes,
  ...creationsRoutes,
  ...educationCenterRoutes,
  ...othersRoutes,
  ...superAdminRoutes,
  {
    path: '*',
    redirectTo: '/'
  }
]