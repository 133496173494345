import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { I18N } from '@aurelia/i18n';
import { IEventAggregator } from 'aurelia';
import type { ModalInterface } from 'flowbite'
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IGenericListConfig } from '@components/generic-list/interfaces/generic-filtered-interface';

@inject(I18N, IZeusClient, IEventAggregator)
export class SocialMediaPage {

  private modal: ModalInterface;
  private addIntegration: { openAddIntegrationModal: () => void; };

  private config: IGenericListConfig;
  private webSite: ModelTypes['website']

  constructor(
    private I18N: I18N,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator
  ) { }

  bound() {
    this.getWebSite();

    this.config = {
      total: 0,
      tableName: 'userAppIntegration',
      streamName: 'userAppIntegration_stream',
      aggregateName: 'userAppIntegration_aggregate',
      columns: ['id', 'alias', 'integrationType', 'created_at', 'updated_at', 'deleted'],
      filters: [],
      orderBy: [],
      data: [],
      perPage: 12,
      currentPage: 1,
    }
  }

  private async getWebSite() {
    const query = await this.zeusClient.chain('query')({
      website: [
        {
          limit: 1,
        },
        {
          id: true,
          created_at: true,
          brand: {
            brandName: true,
          }
        }
      ]
    })

    if (!query.website.length) return;

    this.webSite = query.website[0] as ModelTypes['website'];
  }

  private async deleteUserAppIntegration(integrationId: string) {
    const { id } = await this.zeusClient.deleteUserAppIntegrationById(integrationId);

    if (!id) {
      toastifyError({ message: 'Erro ao excluir integração' });
      return;
    }

    toastifySuccess({ message: 'Integração excluida com sucesso' });
  }

  openWordpressModal() {
    this.modal.show();
  }

  openAddIntegrationModal() {
    this.addIntegration.openAddIntegrationModal();
  }

  private setIconByType(channel: string): string {
    switch (channel) {
      case 'instagram':
        return 'fa-brands fa-instagram';
      case 'facebook':
        return 'fa-brands fa-facebook-f';
      case 'linkedin':
        return 'fa-brands fa-linkedin-in';
      case 'tiktok':
        return 'fa-brands fa-tiktok';
      case 'blog':
        return 'fas fa-rss';
      case 'site':
        return 'fas fa-globe';
      case 'email':
        return 'fas fa-envelope';
      case 'wordpress':
        return 'fa-brands fa-wordpress';
      default:
        return 'fas fa-question';
    }
  }
}