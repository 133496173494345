import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { ITemplateService } from '../../services/templates-service';
import { IRouter } from '@aurelia/router';
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { IDialogService } from "@aurelia/dialog";
import { GoPremiumDialog } from "@components/calendar/components/go-premium-dialog/go-premium-dialog";

@inject(IRouter, ITemplateService, IAuthService, IDialogService)
export class ImageTemplatesPage {
  private channel: string = 'images';

  constructor(
    private router: IRouter,
    private service: ITemplateService,
    private authService: IAuthService,
    private dialogService: IDialogService
  ) { }

  private async getNewTemplateType(channel: string): Promise<ModelTypes['newTemplateType'][]> {
    const result = await this.service.getNewTemplateType(channel);

    return result;
  }

  private async handleRedirect(): Promise<void> {
    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }
    await this.router.load('/image');
  }
}