import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/card-title/card-title-component";
import * as d1 from "./components/user-data/user-data-component.ts";
import * as d2 from "./components/change-password/change-password-component.ts";
export const name = "my-account";
export const template = "\n\n\n\n\n<section>\n  <card-title-component title-id=\"minhaConta\"></card-title-component>\n\n  <!-- beggin::user-data -->\n  <user-data-component></user-data-component>\n  <!-- end::user-data -->\n\n  <!-- beggin::change-password -->\n  <change-password-component></change-password-component>\n  <!-- end::change-password -->\n\n</section>";
export default template;
export const dependencies = [ d0, d1, d2 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
