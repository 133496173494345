import { bindable } from 'aurelia';
import { Dropdown } from "flowbite";
import type { DropdownOptions, DropdownInterface } from "flowbite";
import { IGenericListOrderBy } from '@components/generic-list/interfaces/generic-filtered-interface';

export class OrderByComponent {
  private id: string = crypto.randomUUID();

  private dropdown: DropdownInterface
  private isShowDropdown: boolean = false;

  private listOrderBySelected: IGenericListOrderBy | null = null;

  @bindable orderBy: IGenericListOrderBy[];
  @bindable handleOrderByCallback: Function;


  async attached() {
    const $targetEl: HTMLElement = document.getElementById(`dropdownFilterOrderByMenu-${this.id}`);

    const $triggerEl: HTMLElement = document.getElementById(`dropdownFilterOrderByButton-${this.id}`);

    const options: DropdownOptions = {
      placement: 'bottom',
      triggerType: 'click',
      offsetSkidding: 10,
      offsetDistance: 0,
      delay: 300,
      onShow: () => {
        this.isShowDropdown = true;
      },
      onHide: () => {
        this.isShowDropdown = false;
      }
    };

    this.dropdown = new Dropdown($targetEl, $triggerEl, options);
  }

  public hideDropdown() {
    this.dropdown.hide();
  }

  private createListOrderBy(): IGenericListOrderBy[] {
    const listOrderBy: IGenericListOrderBy[] = [];

    this.orderBy.forEach((orderBy: IGenericListOrderBy) => {
      const orderByDesc: IGenericListOrderBy = {
        title: orderBy.title + ' (Z-A)',
        field: orderBy.field,
        value: 'desc',
      };

      const orderByAsc: IGenericListOrderBy = {
        title: orderBy.title + ' (A-Z)',
        field: orderBy.field,
        value: 'asc',
      };

      listOrderBy.push(orderByDesc);
      listOrderBy.push(orderByAsc);
    });

    return listOrderBy;
  }

  public async handleOrdering(): Promise<void> {
    if (!this.listOrderBySelected) {
      this.handleClearOrdering();

      return;
    }

    this.orderBy = this.orderBy.map((orderBy: IGenericListOrderBy) => {
      orderBy.value = null;

      if (orderBy.field === this.listOrderBySelected.field) {
        orderBy.value = this.listOrderBySelected.value;
      }

      return orderBy;
    });

    this.handleOrderByCallback();

    this.hideDropdown();
  }

  private async handleClearOrdering(): Promise<void> {
    this.orderBy.forEach((orderBy: IGenericListOrderBy) => {
      orderBy.value = null;
    });

    this.handleOrderByCallback();

    this.hideDropdown();
  }
}