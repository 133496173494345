import { CustomElement } from '@aurelia/runtime-html';
export const name = "undraw-component";
export const template = "<div class=\"flex flex-col justify-center items-center\">\n  <span class=\"text-base font-semibold text-gray-400 dark:text-gray-400 pt-6\">${message}</span>\n  <img src=\"assets/images/undraw.svg\" alt=\"undraw\" class=\"h-80 mt-6\">\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
