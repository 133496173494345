import { CustomElement } from '@aurelia/runtime-html';
export const name = "card-status-component";
export const template = "<div class=\"block w-full mb-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700\">\n  <div class=\"h-2 bg-gradient-to-r from-primary-500 rounded-t-lg\"></div>\n\n  <div class=\"px-4 py-2\">\n    <div class=\"flex flex-row items-center justify-between\">\n      <au-slot name=\"status\"></au-slot>\n      <au-slot name=\"actions\"></au-slot>\n    </div>\n  </div>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
