import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/spinner-button/spinner-button-component";
export const name = "confirm-action-dialog";
export const template = "\n\n<div class=\"relative p-4 w-full max-w-md max-h-full\">\n  <!-- begin::content -->\n  <div class=\"relative bg-white rounded-lg shadow-sm dark:bg-gray-700 p-4 md:p-5\">\n\n    <!-- begin::body -->\n    <div class=\"space-y-4 flex flex-col items-center justify-center mb-8\">\n      <div class=\"text-5xl text-gray-500 dark:text-gray-200\">\n        <i class.bind=\"icon\"></i>\n      </div>\n\n      <div class=\"flex flex-col items-center justify-center\">\n        <h3 class=\"text-xl font-medium text-gray-700 dark:text-gray-300\">${title}</h3>\n        <p class=\"text-sm font-normal text-center text-gray-500 dark:text-gray-400\">${message}</p>\n      </div>\n    </div>\n    <!-- end::body -->\n\n    <!-- begin::footer -->\n    <div class=\"flex items-center justify-center rounded-b gap-2\">\n      <button type=\"button\" click.trigger=\"$dialog.cancel()\" class=\"btn-neutral\">Cancelar</button>\n      <spinner-button-component label.bind=\"confirmText\" btn-class.bind=\"btn\" trigger-function.bind=\"() => handleConfirm()\"></spinner-button-component>\n    </div>\n    <!-- end::footer -->\n  </div>\n  <!-- end::content -->\n</div>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
