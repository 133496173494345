import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@converters/currency-format-converter";
export const name = "flow-card-component";
export const template = "\n\n<section>\n\n  <a load=\"/oportunidade/${deal.id}\">\n\n    <div class=\"flex flex-row\">\n      <!-- <div class=\"w-2 bg-secondary-600 rounded-l-lg\"></div> -->\n\n      <div class=\"flex flex-col text-ocean-600 bg-white dark:bg-gray-800 max-w-xs w-80 gap-4\">\n        <div class=\"flex flex-col w-full p-4 gap-4\">\n\n          <div class=\"flex flex-row items-center gap-4\">\n            <span class=\"text-value flex items-center gap-2.5\"><i class=\"fa-solid fa-square text-blue-700\"></i> Em andamento</span>\n            <span class=\"text-value flex items-center gap-2.5\"><i class=\"fa-solid fa-square text-yellow-300\"></i>Criado há ${deal.created_at | timeago & signal:'kanbanTimeago'}</span>\n          </div>\n\n          <div class=\"flex flex-row gap-1\">\n            <div class=\"flex flex-col flex-1\">\n              <span class=\"text-value !text-base font-bold line-clamp-1 mb-1\">${deal.name ?? 'N/A'}</span>\n              <span class=\"text-value line-clamp-1\">${deal.email ?? 'N/A'}</span>\n            </div>\n          </div>\n\n          <div class=\"flex items-center gap-6\">\n            <span class=\"text-value flex items-center gap-2.5\"><i class=\"fa-solid fa-star\"></i> ${deal.rating ?? '0'}</span>\n            <i class=\"fa-solid fa-user text-value\"></i>\n            <span if.bind=\"deal.productId\" class=\"text-value flex items-center gap-2.5\"><i class=\"fa-solid fa-credit-card\"></i> ${deal.product.salePrice | currency}</span>\n            <span else class=\"text-value flex items-center gap-2.5\"><i class=\"fa-solid fa-credit-card\"></i> Nenhum produto</span>\n          </div>\n\n          <div class=\"flex justify-center items-center rounded-sm bg-gray-100 dark:bg-gray-900 py-1.5\">\n            <span class=\"text-xs text-gray-500 font-bold\">Sem tarefa</span>\n          </div>\n\n        </div>\n\n      </div>\n    </div>\n\n  </a>\n\n</section>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
