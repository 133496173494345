import { DI, inject } from 'aurelia';
import { IDialogService } from '@aurelia/dialog';
import { ConfirmActionDialog, ConfirmDialogModel } from './components/confirm-action-dialog';

export interface IConfirmAction {
  open(model: ConfirmDialogModel): Promise<void>;
}

@inject(IDialogService)
class ConfirmAction implements IConfirmAction {
  constructor(private dialogService: IDialogService) { }

  async open(model: ConfirmDialogModel): Promise<void> {
    await this.dialogService.open({
      component: () => ConfirmActionDialog,
      model,
      lock: false,
      keyboard: ['Escape'],
      startingZIndex: 100,
    });
  }
}

export const IConfirmAction = DI.createInterface<IConfirmAction>(x => x.singleton(ConfirmAction));