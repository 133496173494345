import { inject } from "aurelia";
import { I18N } from '@aurelia/i18n';
import { createEditor } from './editor.js';
import { IDialogService } from '@aurelia/dialog';
import { setTranslations } from 'polotno/config';
import { IDisposable, IEventAggregator } from 'aurelia';
import { IPolotnoService } from './services/polotno-service';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { SaveTemplateDialog } from './components/save-template-dialog/save-template-dialog';

import US from './I18n/en_US.json';
import ES from './I18n/es_ES.json';
import BR from './I18n/pt_BR.json';

// import './polotno-module.scss'

import { ModelTypes } from '@zeus';
import { EditCaptionDialog } from './components/edit-caption-dialog/edit-caption-dialog';
import { PublishSocialMediaComponent } from "../../components/publish/publish-social-media/publish-social-media-component.js";

@inject(I18N, IAuthService, IEventAggregator, IPolotnoService, IDialogService)
export class PolotnoModule {
  private id: string;
  private project: ModelTypes['project'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private store: any = null;
  private localeSubscription: IDisposable;
  private colorThemeSubscription: IDisposable;
  private publishSocialMediaComponentRef: PublishSocialMediaComponent;

  // Variables for handling the custom createElement override and error listeners
  private originalCreateElement: typeof document.createElement;
  private addedErrorListeners: Array<{ element: HTMLImageElement, handler: EventListener }> = [];

  constructor(
    private I18N: I18N,
    private auth: IAuthService,
    readonly ea: IEventAggregator,
    private service: IPolotnoService,
    private dialogService: IDialogService
  ) { }

  loading(parameters: { id: string; }) {
    this.id = parameters.id;
  }

  imageErrorHandler() {
    console.log('Image failed to load');
  }

  async attached() {
    // Save the original createElement method
    this.originalCreateElement = document.createElement;

    // Override document.createElement
    document.createElement = (tagName, ...args) => {
      const element = this.originalCreateElement.call(document, tagName, ...args);

      // Check if the created element is an <img>
      if (tagName.toLowerCase() === "img") {
        // Prevent duplicate event listeners by checking a custom flag
        if (!element.hasAttribute('data-error-listener-added')) {
          element.setAttribute('data-error-listener-added', 'true'); // Mark as handled

          let hasRetried = false; // Track if retry has been attempted

          // Define the error handler and store its reference
          const errorHandler = () => {
            if (!hasRetried) {
              hasRetried = true; // Mark that retry is in progress
              console.warn(`Image failed to load, retrying: ${element.src}`);

              // Append cache-busting parameter correctly
              const separator = element.src.includes("?") ? "&" : "?";
              element.src = `${element.src}${separator}ts=${Date.now()}`;
            } else {
              console.error(`Image failed even after retry: ${element.src}`);
            }
          };

          element.addEventListener("error", errorHandler);
          // Keep track of the element and its error handler so we can remove it later
          this.addedErrorListeners.push({ element, handler: errorHandler });
        }
      }

      return element; // Return the created element
    };

    this.handleColorTheme();

    setTimeout(() => {
      this.handleCreateEditor();
      this.handleLoadGetProject();
    }, 100);
  }

  private async saveAndPublishSocialMediaComponentRef(id: string) {
    await this.handleSave();
    this.publishSocialMediaComponentRef.showModal(id);
  }

  private async handleLoadGetProject(): Promise<void> {
    if (!this.id) return;

    const project = await this.service.getProject(this.id);

    if (!project.template) return;
    this.project = project;

    if (typeof project.template === 'string') {
      this.store.loadJSON(JSON.parse(project.template));
    } else {
      this.store.loadJSON(project.template);
    }
  }

  private handleCreateEditor(): void {
    if (this.store) {
      this.store.clear({ keepHistory: true });
      this.store.addPage();
      this.store.setSize(1080, 1080);
      return;
    }

    this.handleSetTranslations();

    const user = this.auth.getUser();

    this.store = createEditor({
      showCredit: true,
      key: 'ZRySQwqaiM3YAu3mXiY_',
      width: "1080",
      height: "1080",
      userId: user.id,
      token: this.auth.getAccessToken(),
      container: document.getElementById('container'),
    });
  }

  private async handleSave(): Promise<void> {
    const storeJson = this.store.toJSON();

    const response = await this.service.upsertProject({
      id: this.id,
      alias: this.project?.alias,
      caption: this.project?.caption,
      creditPrice: 0,
      rawText: this.project?.rawText,
      type: this.project?.type,
      template: storeJson,
    });

    if (response.id && !this.id) {
      window.history.replaceState({}, 'Projeto', `/editor/${response.id}`);
    }
  }

  private handleColorTheme(): void {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    const div = document.querySelector("#polotno");

    if (colorTheme === 'light') {
      div.classList.remove('bp5-dark');
    } else {
      div.classList.add('bp5-dark');
    }

    this.colorThemeSubscription = this.ea.subscribe('color-theme', (colorTheme: { theme: string }) => {
      if (colorTheme.theme === 'light') {
        div.classList.remove('bp5-dark');
      } else {
        div.classList.add('bp5-dark');
      }
    });
  }

  private handleSetTranslations(): void {
    let locale = this.I18N.getLocale();

    this.localeSubscription = this.ea.subscribe('language-changed', (payload: { language: string, elementId: string }) => {
      locale = payload.language;
      this.handleTranslate(locale);
    });

    this.handleTranslate(locale);
  }

  private handleTranslate(locale: string): void {
    switch (locale) {
      case 'pt_BR':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setTranslations(BR as any);
        break;
      case 'en_US':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setTranslations(US as any);
        break;
      case 'es_ES':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setTranslations(ES as any);
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setTranslations(US as any);
        break;
    }
  }

  detaching() {
    // Dispose subscriptions
    this.localeSubscription.dispose();
    this.colorThemeSubscription.dispose();

    // Remove all error event listeners that were added
    this.addedErrorListeners.forEach(({ element, handler }) => {
      element.removeEventListener("error", handler);
    });
    // Clear the array of added listeners
    this.addedErrorListeners = [];

    // Restore the original document.createElement method
    document.createElement = this.originalCreateElement;

    // Reset the store as needed
    this.store.clear({ keepHistory: true });
    this.store.addPage();
    this.store.setSize(1080, 1080);
  }

  private handleSelectedTemplate(event: { target: { files: Blob[]; }; }): void {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const template = JSON.parse(fileReader.result as string);
      this.store.loadJSON(template);
    };

    fileReader.readAsText(event.target.files[0]);
  }

  private async openSaveTemplateDialog() {
    const template = this.store.toJSON();

    await this.dialogService.open({
      component: () => SaveTemplateDialog,
      model: { template },
      lock: false,
      startingZIndex: 100,
    });
  }

  async openEditCaption() {
    await this.dialogService.open({
      component: () => EditCaptionDialog,
      model: { project: this.project },
      lock: false,
      startingZIndex: 100,
    });
  }
}
