export enum GoogleAnalyticsEventCategory {
  Product = 'product',
  Image = 'image',
  Article = 'article',
  Post = 'post',
  NewUserCreated = 'newusercreated',

}
export enum GoogleAnalyticsEventAction {
  Click = 'click',
  View = 'view',
  Share = 'share',
  Download = 'download',
  NewUserCreated = 'newusercreated',
}

export enum GoogleAnalyticsUserPlan {
  Free = 'free',
  Premium = 'premium',
  FreeTrial = 'free_trial',
}
export class GoogleAnalyticsHelper {
  public static trackEvent(action: GoogleAnalyticsEventAction, category: GoogleAnalyticsEventCategory, label?: string, value?: number) {
    if (typeof window !== 'undefined' && typeof (window as any).gtag === 'function') {
      (window as any).gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
      });
    } else {
      console.warn('Google Analytics (gtag) is not available');
    }
  }
  public static setUserId(userId: string) {
    if (typeof window !== 'undefined' && typeof (window as any).gtag === 'function') {
      (window as any).gtag('set', 'user_id', userId);
    } else {
      console.warn('Google Analytics (gtag) is not available');
    }
  }
  public static setUserPlan(plan: GoogleAnalyticsUserPlan) {
    if (typeof window !== 'undefined' && typeof (window as any).gtag === 'function') {
      (window as any).gtag('set', 'user_plan', plan);
    } else {
      console.warn('Google Analytics (gtag) is not available');
    }
  }
  public static reportConversion() {



    if (typeof window !== 'undefined' && typeof (window as any).gtag === 'function') {
      (window as any).
        gtag('event', 'conversion', {
          'send_to': 'AW-11070579912/c_I6CIS9jYkYEMjJ7p4p',
        });

    } else {
      console.warn('Google Analytics (gtag) is not available');
    }
  }
}