import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { ICustomElementController } from "@aurelia/runtime-html";
import { inject } from 'aurelia';

@inject(IDialogDom)
export class FullScreenSpinnerDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }


}