import { ModelTypes } from '@zeus'
import { nhost } from '@lib/nhost'
import { IEventAggregator, bindable, inject } from 'aurelia'
import { IConfirmAction } from '@components/confirm-action';
import { type DropdownOptions, type DropdownInterface, Dropdown } from 'flowbite'
import { GoogleAnalyticsEventAction, GoogleAnalyticsEventCategory, GoogleAnalyticsHelper } from "@helpers/google-analytics/google-analytics-helper";

@inject(IEventAggregator, IConfirmAction)
export class TableActionsComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  @bindable deleteCallback: () => Promise<void>
  @bindable product: ModelTypes["products"] | undefined

  constructor(
    readonly ea: IEventAggregator,
    private confirmAction: IConfirmAction,
  ) { }

  attached() {
    this.$targetEl = document.getElementById(`dropdown-${this.product.id}`);

    this.$triggerEl = document.getElementById(`dropdown-${this.product.id}-button`);

    const options: DropdownOptions = {
      placement: 'left',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  detaching() {
    if (!this.$targetEl || !this.$triggerEl) {
      return;
    }

    this.$targetEl.remove();
    this.$triggerEl.remove();

    this.$targetEl = null;
    this.$triggerEl = null;
    this.dropdown = null;
  }

  async handleDeleteProduct(): Promise<void> {
    this.dropdown.hide();

    await this.confirmAction.open({
      type: "danger",
      title: "Deletar produto",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        await this.deleteCallback()
      }
    });
  }

  private async getFileURL(fileId: string): Promise<string> {
    const { presignedUrl } = await nhost.storage.getPresignedUrl({ fileId, width: 100, height: 100 })

    return presignedUrl.url;
  }

  async handleSharingProduct(): Promise<void> {
    if (navigator.share) {

      if (!navigator.canShare) alert('This browser does not support sharing files');

      const url = this.product.linkConversion;

      await navigator.share({ url });
    } else {
      alert('This browser does not support sharing files');
    }
  }
  async download(fileId) {
    const url = nhost.storage.getPublicUrl({ fileId });
    GoogleAnalyticsHelper.trackEvent(GoogleAnalyticsEventAction.Download, GoogleAnalyticsEventCategory.Product, 'download-product');

    window.open(url, '_blank');
  }

}