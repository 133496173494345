import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';
import { IWebsiteService } from '../../services/website-service';
import { IZeusClient, ZeusClient } from "@services/graphql-service/zeus-client";
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";

@inject(IWebsiteService, IZeusClient, IAuthService)
export class DomainModule {
  private website: ModelTypes['website']
  private createWww = false
  private domain: string
  private ipAddress = '34.54.50.34'
  constructor(private websiteService: IWebsiteService, private zeusClient: ZeusClient, private auth: IAuthService) { }

  loading() {
    this.website = this.websiteService.websiteSubscription
    this.domain = this.website?.domain
  }

  @watch('websiteService.websiteSubscription')
  async handleDealSubscription(newData: ModelTypes['website'], oldData: ModelTypes['website']) {
    this.website = newData;
    this.domain = newData.domain
  }

  async handleSave() {
    const result = await this.zeusClient.chain('mutation')({
      changeDomain: [
        {
          domains: [this.domain, this.createWww ? `www.${this.domain}` : this.domain],
          websiteId: this.website.id,
        },
        {
          success: true,
        }
      ]
    })

    console.log(result)
  }
}