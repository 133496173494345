import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/calendar/calendar-component-widget";
import * as d1 from "@components/card-title/card-title-component";
export const name = "calendar-page";
export const template = "\n\n\n<section>\n  <card-title-component title-id=\"calendario\"></card-title-component>\n\n  <div\n    class=\"card p-4 mt-8 min-h-[calc(100vh-14rem)] max-h-[calc(100vh-14rem)] h-full overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300\">\n    <h5 class=\"font-bold text-base text-gray-900 dark:text-white\">Editorial de Marketing</h5>\n    <h6 class=\"text-sm text-gray-700 dark:text-gray-400 mb-4\">Clique no evento para visualizar ou realizar edições</h6>\n\n    <calendar-component-widget></calendar-component-widget>\n  </div>\n\n</section>";
export default template;
export const dependencies = [ d0, d1 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
