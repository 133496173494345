import { inject } from "aurelia";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes } from '@zeus';
import { DI } from 'aurelia';

export interface ICreateModalService {
  brands(): Promise<Array<ModelTypes['brands']>>
  products(): Promise<Array<ModelTypes['products']>>
  audiences(): Promise<Array<ModelTypes['audiences']>>
  generateCalendarEx({ brandId, productIds, audiencesIds, campaignObjective }: { brandId: string, productIds: string[], audiencesIds: string[], campaignObjective: string }): Promise<ModelTypes["GenerateCalendarExOutput"] | undefined>
}

@inject(IZeusClient)
export class CreateModalService implements ICreateModalService {

  constructor(private zeusClient: ZeusClient) { }

  async brands(): Promise<Array<ModelTypes['brands']>> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.brands as Array<ModelTypes['brands']>;
  }

  async products(): Promise<Array<ModelTypes['products']>> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {},
        {
          id: true,
          alias: true,
        },
      ]
    });

    return result?.products as Array<ModelTypes['products']>;
  }

  async audiences(): Promise<Array<ModelTypes['audiences']>> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.audiences as Array<ModelTypes['audiences']>;
  }

  async generateCalendarEx({ brandId, productIds, audiencesIds, campaignObjective }: { brandId: string, productIds: string[], audiencesIds: string[], campaignObjective: string }): Promise<ModelTypes["GenerateCalendarExOutput"] | undefined> {
    const result = await this.zeusClient.chain('mutation')({
      generateCalendarEx: [
        {
          brandId: brandId,
          productIds: productIds,
          audiencesIds: audiencesIds,
          campaignObjective: campaignObjective,
        },
        {
          success: true,
        }
      ]
    });

    return result.generateCalendarEx as ModelTypes["GenerateCalendarExOutput"];
  }

}

export const ICreateModalService = DI.createInterface<ICreateModalService>(x => x.singleton(CreateModalService));