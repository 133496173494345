import Inputmask from 'inputmask';
import { INode, bindable, customAttribute, inject } from 'aurelia';

enum MaskType {
  CURRENCY = 'currency',
  DATE = 'date',
  PHONE = 'phone',
  CPF = 'cpf',
  CNPJ = 'cnpj',
  CPFCNPJ = 'cpfcnpj',
  CEP = 'cep',
  DOCUMENT = 'document',
  PERCENTAGE = 'percentage',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  SLUG = 'slug',
}

@inject(INode)
@customAttribute({ name: 'input-mask' })
export class InputMaskAttribute {
  @bindable({ primary: true }) mask: MaskType;

  private inputElement: HTMLElement;

  constructor(private element: HTMLElement) {
    this.inputElement = element;
  }

  attached() {
    switch (this.mask) {
      case MaskType.CURRENCY:
        this.applyCurrencyMask();
        break;
      case MaskType.DATE:
        this.applyDateMask();
        break;
      case MaskType.PHONE:
        this.applyPhoneMask();
        break;
      case MaskType.CPF:
        this.applyCpfMask();
        break;
      case MaskType.CNPJ:
        this.applyCnpjMask();
        break;
      case MaskType.CPFCNPJ:
        this.applyCpfCnpjMask();
        break;
      case MaskType.CEP:
        this.applyCepMask();
        break;
      case MaskType.DOCUMENT:
        this.applyDocumentMask();
        break;
      case MaskType.PERCENTAGE:
        this.applyPercentageMask();
        break;
      case MaskType.INTEGER:
        this.applyIntegerMask();
        break;
      case MaskType.DECIMAL:
        this.applyDecimalMask();
        break;
      case MaskType.SLUG:
        this.applySlugMask();
        break;
      default:
        break;
    }
  }

  private applySlugMask(): void {
    Inputmask({
      alias: 'url',
      placeholder: ' ',
      clearMaskOnLostFocus: false,
      removeMaskOnSubmit: true,
      rightAlign: false,
      onBeforePaste: function (pastedValue) {
        // Process pasted values to match the desired format
        return pastedValue.replace(/[^a-z0-9]/gi, '-').toLowerCase();
      },
    }).mask(this.inputElement);
  }

  private applyPhoneMask(): void {
    Inputmask({
      mask: ['(99) 9999-9999', '(99) 99999-9999'],
      greedy: false,
      autoUnmask: true,
      definitions: {
        '#': {
          validator: '[0-9]',
          cardinality: 1,
        },
      },
    }).mask(this.inputElement);
  }

  private applyCpfMask(): void {
    Inputmask({
      mask: '999.999.999-99',
      greedy: false,
      autoUnmask: true,
      definitions: {
        '#': {
          validator: '[0-9]',
          cardinality: 1,
        },
      }
    }).mask(this.inputElement);
  }

  private applyCnpjMask(): void {
    Inputmask({
      mask: '99.999.999/9999-99',
      greedy: false,
      autoUnmask: true,
      definitions: {
        '#': {
          validator: '[0-9]',
          cardinality: 1,
        },
      }
    }).mask(this.inputElement);
  }

  private applyCpfCnpjMask(): void {
    Inputmask({
      mask: ['999.999.999-99', '99.999.999/9999-99'],
      greedy: false,
      autoUnmask: true,
      definitions: {
        '#': {
          validator: '[0-9]',
          cardinality: 1,
        },
      }
    }).mask(this.inputElement);
  }

  private applyCepMask(): void {
    Inputmask({
      mask: '99999-999',
      greedy: false,
      autoUnmask: true,
      definitions: {
        '#': {
          validator: '[0-9]',
          cardinality: 1,
        },
      }
    }).mask(this.inputElement);
  }

  private applyDocumentMask(): void {
    Inputmask({
      mask: '999.999.999-999',
      greedy: false,
      autoUnmask: true,
      definitions: {
        '#': {
          validator: '[0-9]',
          cardinality: 1,
        },
      }
    }).mask(this.inputElement);
  }

  private applyPercentageMask(): void {
    Inputmask({
      alias: 'numeric',
      groupSeparator: '.', // Thousand separator
      radixPoint: ',', // Decimal separator for Brazilian currency format
      digits: 2, // Assume up to 2 decimal places
      digitsOptional: true, // Decimal part is optional
      suffix: '%', // Percentage suffix
      placeholder: '0',
      clearMaskOnLostFocus: false,
      rightAlign: false, // Typically, currency inputs are right-aligned
      unmaskAsNumber: true, // Unmask to number for easy retrieval of numeric value
      removeMaskOnSubmit: true, // Useful for forms to get unmasked value on submit
      autoUnmask: true,
      onBeforePaste: function (pastedValue) {
        // Process pasted values to match the desired format
        const processedValue = pastedValue.replace(/\./g, '').replace(',', '.');
        return processedValue;
      },
      onUnMask: function (maskedValue, unmaskedValue) {
        // Process value when unmasking, useful for conversions or adjustments
        return unmaskedValue.replace(',', '.');
      },
      onBeforeMask: function (initialValue) {
        if (typeof initialValue === 'string') {
          // Process initial value before applying the mask
          if (initialValue.includes(',')) {
            return initialValue.replace(/\./g, '').replace(',', '.');
          } else {
            return initialValue
          }
        }
      },
    }).mask(this.inputElement);
  }

  private applyIntegerMask(): void {
    Inputmask({
      alias: 'integer',
      placeholder: '0',
      clearMaskOnLostFocus: false,
      removeMaskOnSubmit: true,
      rightAlign: false,
    }).mask(this.inputElement);
  }

  private applyDecimalMask(): void {
    Inputmask({
      alias: 'decimal',
      placeholder: '0',
      clearMaskOnLostFocus: false,
      removeMaskOnSubmit: true,
      rightAlign: false,
    }).mask(this.inputElement);
  }

  private applyCurrencyMask(): void {
    Inputmask({
      alias: 'numeric',
      groupSeparator: '.', // Thousand separator
      radixPoint: ',', // Decimal separator for Brazilian currency format
      digits: 2, // Assume up to 2 decimal places
      digitsOptional: true, // Decimal part is optional
      prefix: 'R$ ', // Currency prefix
      placeholder: '0',
      clearMaskOnLostFocus: false,
      rightAlign: false, // Typically, currency inputs are right-aligned
      unmaskAsNumber: true, // Unmask to number for easy retrieval of numeric value
      removeMaskOnSubmit: true, // Useful for forms to get unmasked value on submit
      allowMinus: false, // Do not allow negative values
      autoUnmask: true,
      onBeforePaste: function (pastedValue) {
        // Process pasted values to match the desired format
        const processedValue = pastedValue.replace(/\./g, '').replace(',', '.');
        return processedValue;
      },
      onUnMask: function (maskedValue, unmaskedValue) {
        // Process value when unmasking, useful for conversions or adjustments
        return unmaskedValue.replace(',', '.');
      },
      onBeforeMask: function (initialValue) {
        if (typeof initialValue === 'string') {
          // Process initial value before applying the mask
          if (initialValue.includes(',')) {
            return initialValue.replace(/\./g, '').replace(',', '.');
          } else {
            return initialValue
          }
        }
      },
    }).mask(this.inputElement);
  }

  private applyDateMask(): void {
    Inputmask({
      alias: 'datetime',
      inputFormat: 'dd/mm/yyyy',
      placeholder: 'dd/mm/yyyy',
      clearMaskOnLostFocus: false,
      removeMaskOnSubmit: true,
    }).mask(this.inputElement);
  }
}