import { inject } from "aurelia";
import { IEventAggregator, bindable } from 'aurelia';

import { IDialogService } from '@aurelia/dialog';
import { toastifyError, toastifySuccess } from '@helpers/toastify';
// import { FullscreenSpinnerDialog } from '@components/fullscreen-spinner/fullscreen-spinner-dialog';

@inject(IEventAggregator, IDialogService)
export class SpinnerButtonComponent {
  @bindable label: string;
  @bindable disabled: boolean;
  @bindable btnClass: string;
  @bindable triggerFunction: Function;
  @bindable toastMessage: string | null = null;

  isLoading: boolean;

  constructor(readonly ea: IEventAggregator, private dialogService: IDialogService) { }

  async executeTriggerFunction() {
    this.isLoading = true;

    // const { dialog } = await this.dialogService.open({
    //   component: () => FullscreenSpinnerDialog,
    //   model: {},
    //   lock: true,
    //   startingZIndex: 100,
    // });

    try {
      await this.triggerFunction();

      if (this.toastMessage) {

        toastifySuccess({ message: this.toastMessage });
      }

    } catch (error) {
      console.log(error);

      toastifyError({ message: 'Ocorreu um erro ao executar a ação' });
    } finally {
      this.isLoading = false;
      // await dialog.ok();
    }

  }
}