import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { IEventAggregator } from 'aurelia';
import { watch } from '@aurelia/runtime-html';
import { IDialogService } from '@aurelia/dialog';
import { TasksDialog } from './components/tasks-dialog';
import { ITasksService } from './services/tasks-service';
import { IOpportunityService } from '../../services/opportunity-service';
import { toastifyInfo, toastifySuccess, toastifyError } from '@helpers/toastify';
import { IConfirmAction } from '@components/confirm-action';

@inject(ITasksService, IEventAggregator, IDialogService, IOpportunityService, IConfirmAction)
export class TasksSection {
  private opportunity: ModelTypes["deal"];
  private tasks: ModelTypes["dealTask"][] = [];

  constructor(
    private service: ITasksService,
    readonly ea: IEventAggregator,
    private dialogService: IDialogService,
    private opportunityService: IOpportunityService,
    private confirmAction: IConfirmAction,
  ) { }

  attached() {
    this.opportunity = this.opportunityService.opportunitySubscription;
    this.tasks = this.opportunity?.tasks;
  }

  @watch('opportunityService.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal']) {
    this.opportunity = newData;
    this.tasks = this.opportunity?.tasks;
  }

  private async resolveTask(task: ModelTypes['dealTask']): Promise<void> {
    if (task.finished) {
      toastifyInfo({ message: 'Tarefa ja resolvida' });

      return;
    }

    await this.confirmAction.open({
      type: "success",
      title: "Resolver tarefa",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        try {
          const response = await this.service.setToFinish(task.id);

          if (!response) throw new Error('Erro ao resolver tarefa');

          toastifySuccess({ message: 'Dados salvo com sucesso' });
        } catch (error) {
          if (error instanceof Error) {
            toastifyError({ message: error.message });
          } else {
            toastifyError({ message: 'Algo deu errado, tente novamente mais tarde' });
          }
        }
      }
    });
  }

  private async openTaskDialog() {
    await this.dialogService.open({
      component: () => TasksDialog,
      model: { opportunityId: this.opportunity.id },
      lock: false,
      startingZIndex: 100,
    });
  }
}