import { inject } from "aurelia";
import { IProductsService } from './services/products-service';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IGenericListConfig } from '@components/generic-list/interfaces/generic-filtered-interface';
import { toastifyError, toastifySuccess } from '@helpers/toastify';

@inject(IAuthService, IProductsService)
export class ProductsPage {

  private config: IGenericListConfig = {
    total: 0,
    tableName: 'products',
    streamName: 'products_stream',
    aggregateName: 'products_aggregate',
    columns: ['id', 'alias', 'type', 'category', 'price', 'salePrice', 'description', 'imageOneId', 'imageTwoId', 'imageThreeId', 'imageFourId', 'created_by', 'created_at', 'updated_at', 'deleted', 'ebookFileId', 'slidesFileId', 'productSquareDisplayFileId', 'productVerticalDisplayFileId'],
    filters: [],
    data: [],
    perPage: 12,
    currentPage: 1,
  }

  constructor(
    private auth: IAuthService,
    private service: IProductsService
  ) { }

  private async deleteProduct(productId: string): Promise<void> {
    const { id } = await this.service.deleteProductById(productId);

    if (!id) {
      toastifyError({ message: 'Erro ao excluir produto' });
      return;
    }

    toastifySuccess({ message: 'Produto excluido com sucesso' });
  }
}