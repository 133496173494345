import { CustomElement } from '@aurelia/runtime-html';
export const name = "courses-component";
export const template = "<div class=\"flex flex-col lg:flex-row h-full w-full max-w-full shrink-0\">\n  <!-- begin::card -->\n  <div class=\"card p-4\">\n    <div class=\"relative h-full overflow-hidden bg-cover rounded-xl bg-dash-courses\">\n      <span class=\"absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-80\"></span>\n      <div class=\"relative z-10 flex flex-col flex-auto h-full p-4\">\n        <h5 t=\"banner.coursecomponenttitle\" class=\"pt-2 mb-4 font-bold text-white text-lg\">Nossos cursos</h5>\n        <p t=\"banner.coursecomponensubttitle\" class=\"text-white leading-6 font-normal text-sm mb-4\">A criação de riqueza\n          é um jogo de soma positiva evolutivamente recente. É tudo sobre quem aproveita a oportunidade primeiro.</p>\n        <!-- <a href=\"/courses\" class=\"mt-auto mb-0 font-semibold leading-normal text-white group text-sm\">\n          Leia mais\n          <i class=\"fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200\"></i>\n        </a> -->\n      </div>\n    </div>\n  </div>\n  <!-- end::card -->\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
