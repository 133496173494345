import { inject, DI } from "aurelia";
import { ModelTypes, order_by } from '@zeus';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IOpportunityService {
  opportunitySubscription: ModelTypes['deal'];

  getDomain(): Promise<string | null>
  getOpportunityByIdSubscription(dealId: string): void
  advanceStep(command: { dealId: string, statusId: string }): Promise<ModelTypes["deal"]>
}

@inject(IZeusClient, IAuthService)
export class OpportunityService implements IOpportunityService {
  public opportunitySubscription: ModelTypes['deal'];

  constructor(
    private zeusClient: ZeusClient,
    private auth: IAuthService,
  ) { }

  getOpportunityByIdSubscription(dealId: string): void {
    const result = this.zeusClient.subscription('subscription')({
      deal_by_pk: [
        { id: dealId },
        {
          id: true,
          name: true,
          phone: true,
          email: true,
          rating: true,
          created_at: true,
          statusId: true,
          dealStatus: {
            id: true,
            displayName: true,
          },
          product: {
            id: true,
            alias: true,
            category: true,
            type: true,
            linkConversion: true,
            description: true,
            price: true,
            salePrice: true,
            imageOneId: true,
            imageTwoId: true,
            imageThreeId: true,
            imageFourId: true,
          },
          interactions: [
            {},
            {
              id: true,
              success: true,
              channel: true,
              observation: true,
              date: true,
              created_at: true,
            }
          ],
          tasks: [
            {},
            {
              id: true,
              title: true,
              description: true,
              date: true,
              category: true,
              finished: true,
              created_at: true,
            }
          ],
          attachments: [
            {
              order_by: [{ created_at: order_by.desc }]
            },
            {
              id: true,
              fileId: true,
              file: {
                id: true,
                name: true,
                mimeType: true,
                isUploaded: true,
              }
            }
          ]
        },
      ]
    });

    result.error((error) => { console.error('opportunitySubscription', error); });

    result.on(({ deal_by_pk }: { deal_by_pk: ModelTypes["deal"] }) => {
      this.opportunitySubscription = deal_by_pk;
    })
  }

  async advanceStep(command: { dealId: string, statusId: string }): Promise<ModelTypes["deal"]> {
    const result = await this.zeusClient.chain('mutation')({
      update_deal_by_pk: [
        {
          pk_columns: { id: command.dealId },
          _set: { statusId: command.statusId },
        },
        {
          id: true,
        }
      ]
    });

    return result.update_deal_by_pk as ModelTypes["deal"];
  }

  async getDomain(): Promise<string | null> {
    const user = this.auth.user;

    const query = await this.zeusClient.chain('query')({
      website: [
        {
          where: {
            brand: {
              created_by: { _eq: user.id }
            }
          }
        },
        {
          domain: true
        }
      ]
    });

    if (!query.website.length) return null;

    return 'https://' + query.website[0].domain;
  }
}

export const IOpportunityService = DI.createInterface<IOpportunityService>((x) => x.singleton(OpportunityService));
