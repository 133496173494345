import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/card-header/card-header-component";
import * as d1 from "@components/spinner-button/spinner-button-component";
export const name = "change-password-component";
export const template = "\n\n\n<section>\n  <div class=\"flex flex-row gap-4 mb-6 mt-8\">\n\n    <div class=\"card w-full h-fit\">\n      <card-header-component header-id=\"myAccountChangePassword\"></card-header-component>\n\n      <form class=\"p-4\" novalidate=\"novalidate\">\n\n        <div class=\"flex flex-col gap-4 mb-6\">\n          <!-- beggin::password -->\n          <div class=\"flex-1\">\n            <label for=\"siteUrl\" class=\"form-label\">Senha</label>\n            <validation-container>\n              <input type=\"password\" id=\"siteUrl\" value.bind=\"password & validate\" autocomplete=\"off\"\n                class=\"form-input\">\n            </validation-container>\n          </div>\n          <!-- end::password -->\n        </div>\n\n        <!-- beggin::button -->\n        <div class=\"flex justify-end\">\n          <button type=\"button\" click.trigger=\"handleChangePassword()\" class=\"btn-primary\">Salvar senha</button>\n        </div>\n        <!-- end::button -->\n      </form>\n    </div>\n\n  </div>\n</section>";
export default template;
export const dependencies = [ d0, d1 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
