import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';

import { IOpportunityService } from '../../services/opportunity-service';
import { IRouter } from '@aurelia/router';

@inject(IRouter, IOpportunityService)
export class ProductsSection {
  private productWebPage: string;
  private product: ModelTypes["products"];

  constructor(
    private router: IRouter,
    private service: IOpportunityService,
  ) { }

  attached() {
    this.product = this.service.opportunitySubscription?.product;

    if (this.product) {
      this.handleProductWebPage(this.product.id);
    }
  }

  @watch('service.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal']) {
    this.product = newData?.product;

    if (this.product) {
      await this.handleProductWebPage(this.product.id);
    }
  }

  private async handleRedirectProductPage(): Promise<void> {
    this.router.load(`/product/${this.product.id}`)
  }

  private async handleProductWebPage(productId: string): Promise<void> {
    const domain = await this.service.getDomain()

    if (domain) {
      this.productWebPage = `${domain}/produto/${productId}`
    }
  }
}