import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

interface ISlidesProps {
  image: string;
  title?: string;
  subtitle?: string;
  opacity?: number;
}

export class CarouselComponent {

  private slides: ISlidesProps[] = [];

  attached() {
    this.slides = [
      {
        image: 'assets/images/carousel/13fb8bcf-854a-4dec-9107-0baa24ce2ded.png',
        title: 'Transforme Ideias em Conteúdo Incrível!',
        subtitle: 'Explore o poder da Copymagico para dar vida às suas ideias com facilidade.',
        opacity: 0.5
      },
      {
        image: 'assets/images/carousel/462c3dc6-2053-4c4b-9bf0-75340ebc466d.png',
        title: 'Agilize Sua Estratégia de Conteúdo!',
        subtitle: 'Ganhe tempo e eficiência com nossa plataforma de criação de conteúdo.',
        opacity: 0.5
      },
      {
        image: 'assets/images/carousel/6427c3ce-c740-4d78-b64f-0e67264bd7ab.png',
        title: 'Conquiste a Audiência dos Seus Sonhos!',
        subtitle: 'Crie conteúdo envolvente que cativa e converte seus seguidores.',
        opacity: 0.5
      },
      {
        image: 'assets/images/carousel/bf634d4f-7280-4332-aedd-65e268610c22.png',
        title: 'Marketing Simplificado, Resultados Amplificados!',
        subtitle: 'Descubra como a Copymagico pode impulsionar sua estratégia de marketing.',
        opacity: 0.5
      },
      {
        image: 'assets/images/carousel/c09af1bb-4c1c-4adb-a605-516782c62a21.png',
        title: 'Faça Parte da Revolução do Conteúdo!',
        subtitle: 'Junte-se a milhares de criadores de conteúdo e transforme sua presença online.',
        opacity: 0.5
      }
    ]

    this.initSwiper();
  }

  initSwiper() {
    new Swiper('.swiper-communication', {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 1,
      freeMode: false,
      speed: 1000,
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true
      },
      navigation: {
        prevEl: '.swiper-button-prev-custom',
        nextEl: '.swiper-button-next-custom',
      },
    });
  }

}