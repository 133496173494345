import Toastify from 'toastify-js';

// criar toastyfy de success
export const toastifySuccess = (props: { message: string }) => {
  Toastify({
    text: props.message,
    duration: 3000,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    backgroundColor: '#4CAF50',
    stopOnFocus: true,
  }).showToast();
};

// criar toastyfy de error
export const toastifyError = (props: { message: string }) => {
  Toastify({
    text: props.message,
    duration: 3000,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    backgroundColor: '#F44336',
    stopOnFocus: true,
  }).showToast();
};

// criar toastyfy de warning
export const toastifyWarning = (props: { message: string }) => {
  Toastify({
    text: props.message,
    duration: 3000,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    backgroundColor: '#FFC107',
    stopOnFocus: true,
  }).showToast();
};

// criar toastyfy de info
export const toastifyInfo = (props: { message: string }) => {
  Toastify({
    text: props.message,
    duration: 3000,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    backgroundColor: '#2196F3',
    stopOnFocus: true,
  }).showToast();
};