import { CustomElement } from '@aurelia/runtime-html';
export const name = "language-picker-component";
export const template = "<button type=\"button\" id=\"${elementId}\" class=\"w-full md:w-auto md:bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none md:focus:ring-4 md:focus:ring-gray-200 md:dark:focus:ring-gray-700 md:rounded-lg text-sm md:px-3 md:py-1.5 px-4 py-2 items-center flex gap-2 md:justify-center cursor-pointer hover:text-gray-900  dark:hover:text-white\">\n\t<img class=\"h-5 w-5 rounded-full mt-0.5\" src=\"assets/images/flags/${selectedLanguageFlag}.svg\" alt=\"\" />\n\t<span class=\"md:hidden\">${selectedLanguageFriendlyName}</span>\n</button>\n\n<div class=\"z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700\" id=\"language-dropdown-${elementId}\" style=\"position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(184px, 316px);\">\n\t<ul class=\"py-1\" role=\"none\">\n\t\t<li repeat.for=\"language of availableLanguages\">\n\t\t\t<a click.trigger=\"setLocale(language.code)\" class=\"block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer\" role=\"menuitem\">\n\t\t\t\t<div class=\"inline-flex items-center\">\n\t\t\t\t\t<img class=\"h-5 w-5 rounded-full mt-0.5 me-1\" src=\"assets/images/flags/${language.flag}.svg\" alt=\"\" />\n\t\t\t\t\t<span class=\"${!isOpenDrawer && 'hidden'}\">${language.name}</span>\n\t\t\t\t</div>\n\t\t\t</a>\n\t\t</li>\n\t</ul>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
