import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/form/input/input-component";
import * as d1 from "@components/accordion/accordion-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
import * as d3 from "@components/upload/single-file-upload/single-file-upload-component";
export const name = "hero-section";
export const template = "\n\n\n\n\n<accordion-component title=\"Principal\">\n  <div au-slot=\"body\">\n    <form class=\"flex flex-col gap-4\" autocomplete=\"off\">\n      <div class=\"grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4\">\n\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Pré-Título\" value.bind=\"website.homeHeroLabel\" />\n        </div>\n\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Título Principal\" value.bind=\"website.homeHeroTitle\" />\n        </div>\n\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Descrição Principal\" value.bind=\"website.homeHeroDescription\" />\n        </div>\n      </div>\n\n\n      <!-- <div class=\"flex-1\">\n        <input-component type=\"text\" label=\"homeHeroCallToActionText\" value.bind=\"website.homeHeroCallToActionText\" />\n      </div>\n\n      <div class=\"flex-1\">\n        <input-component type=\"text\" label=\"homeHeroCallToActionTargetUrl\" value.bind=\"website.homeHeroCallToActionTargetUrl\" />\n      </div> -->\n\n      <div class=\"flex-1\">\n        <single-file-upload-component label=\"Banner Principal\"\n          selected-file-id.bind=\"website.homeHeroBannerImageFileId\"></single-file-upload-component>\n      </div>\n\n      <div class=\"flex flex-row justify-end mt-4\">\n        <spinner-button-component label=\"Salvar\" btn-class=\"btn-primary min-w-20\"\n          trigger-function.bind=\"() => handleSave()\"></spinner-button-component>\n      </div>\n\n    </form>\n  </div>\n</accordion-component>";
export default template;
export const dependencies = [ d0, d1, d2, d3 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
