import { CustomElement } from '@aurelia/runtime-html';
export const name = "tagify-suggestions-list-component";
export const template = "<div>\n  <label for=\"taglify-${id}\" class=\"form-label\">${label}</label>\n  <input id=\"taglify-${id}\" type=\"text\" name=\"taglify-${id}\" class=\"form-input custom__look\" />\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
