import { CustomElement } from '@aurelia/runtime-html';
export const name = "select-component";
export const template = "<div if.bind=\"validate\" validation-errors.from-view=\"errors\">\n  <label for=\"${id}\" class=\"form-label\">${label}</label>\n  <select ...$attrs id=\"${id}\" class=\"form-input ${errors.length ? '!border-red-600' : ''}\">\n    <option model.bind=\"null\">Selecione</option>\n    <option repeat.for=\"option of options\" model.bind=\"option.value\">${option.label}</option>\n  </select>\n\n  <span style=\"color:red; font-size: 10px;\" repeat.for=\"error of errors\">${error.result.message}</span>\n</div>\n\n<div else>\n  <label for=\"${id}\" class=\"form-label\">${label}</label>\n  <select ...$attrs id=\"${id}\" class=\"form-input\">\n    <option model.bind=\"null\">Selecione</option>\n    <option repeat.for=\"option of options\" model.bind=\"option.value\">${option.label}</option>\n  </select>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
