import { CustomElement } from '@aurelia/runtime-html';
export const name = "card-title-component";
export const template = "<!-- beggin::title -->\n<div class=\"block w-full px-4 py-4 mb-6 card\">\n\n  <div class=\"flex flex-col md:flex-row items-start md:items-center justify-between gap-3 md:gap-0\">\n\n    <!-- begin::title -->\n    <div class=\"flex flex-col\">\n      <div class=\"flex flex-row items-center justify-start text-gray-700 dark:text-gray-300 gap-2.5\">\n        <h5 class=\"text-xl font-bold\">${(titleId ? titles[titleId][I18N.getLocale()].title : title) & signal:'parameterChanged'}</h5>\n      </div>\n\n      <p class=\"font-normal text-sm text-gray-700 dark:text-gray-400\">${(titleId ? titles[titleId][I18N.getLocale()].subtitle : subtitle) & signal:'parameterChanged'}</p>\n    </div>\n    <!-- end::title -->\n\n    <!-- begin::action -->\n    <div class=\"ml-auto\">\n      <au-slot name=\"actions\"></au-slot>\n    </div>\n    <!-- and::action -->\n  </div>\n</div>\n<!-- end::title -->";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
