import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/loading/loading-component";
export const name = "projects-created-component";
export const template = "\n\n<div class=\"card p-4 h-full\">\n  <div class=\"relative h-full\">\n    <h5 t=\"dashboardcards.projects\" class=\"font-bold text-base text-gray-900 dark:text-white\">Post Criados</h5>\n    <div class=\"h-12\">\n      <h6 t=\"dashboardcards.projectssubtitle\" class=\"text-sm text-gray-700 dark:text-gray-400 mb-4 line-clamp-2\">Os\n        projetos podem ser editadas no menu <span class=\"font-bold\">Gerenciar Postagens</span></h6>\n    </div>\n\n    <template promise.bind=\"getBrandAggregate()\">\n      <template pending>\n        <loading-component></loading-component>\n      </template>\n\n      <template then.from-view=\"data\">\n        <div role=\"status\" class=\"absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 z-30 w-[230px]\">\n          <a if.bind=\"data == 0\" href=\"/projects\"\n            class=\"btn-secondary-small motion-safe:animate-pulse flex items-center justify-center\">Criar Post</a>\n        </div>\n\n        <div class=\"${data == 0 ? 'blur-sm pointer-events-none' : ''} pt-2\">\n          <div>\n            <h5 class=\"mb-0 font-bold dark:text-white\">\n              Projetos\n              <span class=\"font-bold leading-normal text-lime-500\" style=\"font-size: xx-large;\"><i\n                  class=\"fa-solid fa-arrow-up text-xs\"></i>${data}</span>\n            </h5>\n          </div>\n        </div>\n      </template>\n\n      <template catch.from-view=\"error\">\n        <div class=\"flex flex-col justify-center items-center\">\n          <span class=\"text-sm font-semibold text-red-600 dark:text-red-600\">Ocorreu um erro 🤕</span>\n          <span class=\"text-sm font-medium italic text-red-600 dark:text-red-600 pt-2\">${error}</span>\n        </div>\n      </template>\n    </template>\n\n  </div>\n</div>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
