export const yesOrNoData = [
  { value: "Sim", text: "Sim" },
  { value: "Não", text: "Não" }
]

export const socialNetworksData = [
  { value: "Facebook", text: "Facebook" },
  { value: "Instagram", text: "Instagram" },
  { value: "TikTok", text: "TikTok" },
  { value: "X", text: "X" },
  { value: "Youtube", text: "Youtube" },
  { value: "Pinterest", text: "Pinterest" },
  { value: "LinkedIn", text: "LinkedIn" },
  { value: "Blog", text: "Blog" },
  { value: "Outro", text: "Outro" }
]

export const staffCountData = [
  { value: "Apenas Eu", text: "Apenas Eu" },
  { value: "2-10 funcionários", text: "2-10 funcionários" },
  { value: "10-30 funcionários", text: "10-30 funcionários" },
  { value: "30-50 funcionários", text: "30-50 funcionários" },
  { value: "50-100 funcionários", text: "50-100 funcionários" },
  { value: "Mais de 100 funcionários", text: "Mais de 100 funcionários" }
]

export const companySegmentData = [
  { value: 'Travel', text: 'Agências de Viagem e Turismo' },
  { value: 'Business', text: 'Negócios em geral' },
  { value: 'Architecture', text: 'Arquitetura e Design de Interiores' },
  { value: 'Health', text: 'Saúde e Bem estar' },
  { value: 'Influencer', text: 'Influenciadores' },
  { value: 'Gym', text: 'Academia e Personal Trainer' },
  { value: 'Finance', text: 'Serviços Financeiros' },
  { value: 'Medic', text: 'Clínicas e Consultórios' },
  { value: 'Agency', text: 'Agências e Consultorias' },
  { value: 'Legal', text: 'Serviços Jurídicos' },
  { value: 'Mentorship', text: 'Mentorias e Especializações' },
  { value: 'Food', text: 'Restaurantes e Cafés' },
  { value: 'ShortStay', text: 'Hotéis e Pousadas' },
  { value: 'Blog', text: 'Canal de noticias' },
  { value: 'Portfolio', text: 'Representações Comerciais' },
  { value: 'Beauty', text: 'Beleza e Estética' },
  { value: 'Fashion', text: 'Moda' },
  { value: 'RealEstate', text: 'Imobiliárias' },
  { value: 'Events', text: 'Eventos' },
  { value: 'Education', text: 'Educação' },
  { value: 'Shopping', text: 'Ecommerce' },
]

export const productOrServiceData = [
  { value: "teste", text: "Meu negócio está em fase de testes" },
  { value: "lancamento", text: "Meu negócio ja conta com alguns clientes" },
  { value: "crescimento", text: "Meu negócio está em fase de crescimento acelerado" },
  { value: "estabilidade", text: "Meu negócio já alcançou estabilidade" },
  { value: "outro", text: "Este é um projeto ou negócio sem fins lucrativos" },
]

export const expectationData = [
  { value: "criar_um_site", text: "Criar um site moderno para o meu negócio" },
  { value: "economia_criacao", text: "Economizar tempo na criação de conteúdo" },
  { value: "qualidade_visual", text: "Melhorar a qualidade visual do meu conteúdo" },
  { value: "estrategia_de_marketing", text: "Aprimorar a estratégia de marketing de conteúdo" },
  { value: "automacao_redes_sociais", text: "Automatizar o agendamento e publicação nas redes sociais" },
  { value: "seo", text: "Gerar mais leads orgânicamente" },
  { value: "growth", text: "Impulsionar as vendas e o crescimento do negócio" },
]

export const marketingObjectiveData = [
  { value: "criacoes", text: "Gerador de postagens em segundos" },
  { value: "site", text: "Criador de sites com IA" },
  { value: "calendario", text: "Calendário de marketing" },
  { value: "integracoes", text: "Integração para postagens automáticas em redes sociais" },
  { value: "inteligencia_artificial", text: "Gerador de imagens e artigos com IA" },
  { value: "crm", text: "CRM para gestão de leads e clientes" },
  { value: "whatsapp", text: "WhatsApp integrado com Site" },
]

export const voiceToneData = [
  { value: "Profissional e sério", text: "Profissional e sério" },
  { value: "Amigável e descontraído", text: "Amigável e descontraído" },
  { value: "Sofisticado e elegante", text: "Sofisticado e elegante" },
  { value: "Divertido e brincalhão", text: "Divertido e brincalhão" },
  { value: "Acolhedor e empático", text: "Acolhedor e empático" },
  { value: "Autoritário e confiante", text: "Autoritário e confiante" },
  { value: "Inspirador e motivador", text: "Inspirador e motivador" },
  { value: "Direto e objetivo", text: "Direto e objetivo" },
  { value: "Técnico e especializado", text: "Técnico e especializado" },
  { value: "Moderno e inovador", text: "Moderno e inovador" }
]

export const personalityData = [
  { value: "Jovem e descolada", text: "Jovem e descolada" },
  { value: "Tradicional e clássica", text: "Tradicional e clássica" },
  { value: "Aventureira e exploradora", text: "Aventureira e exploradora" },
  { value: "Ambiciosa e determinada", text: "Ambiciosa e determinada" },
  { value: "Criativa e inovadora", text: "Criativa e inovadora" },
  { value: "Sofisticada e refinada", text: "Sofisticada e refinada" },
  { value: "Humilde e autêntica", text: "Humilde e autêntica" },
  { value: "Inteligente e perspicaz", text: "Inteligente e perspicaz" },
  { value: "Confiável e honesta", text: "Confiável e honesta" },
  { value: "Encantadora e cativante", text: "Encantadora e cativante" }
]

export const fontFamilyData = [
  { value: "Roboto", text: "Roboto" },
  { value: "Open Sans", text: "Open Sans" },
  { value: "Lato", text: "Lato" },
  { value: "Montserrat", text: "Montserrat" },
  { value: "Raleway", text: "Raleway" },
  { value: "Oswald", text: "Oswald" },
  { value: "Source Sans Pro", text: "Source Sans Pro" }
]

export const isBrandData = [
  { value: false, text: "Marca pessoal" },
  { value: true, text: "Marca corporativa" }
]

export const socioeconomicProfileData = [
  { value: 'preco', text: 'Preço (prioriza o custo mais baixo)' },
  { value: 'qualidade', text: 'Qualidade (valoriza a excelência)' },
  { value: 'quantidade', text: 'Quantidade (busca por maior volume)' },
  { value: 'praticidade', text: 'Praticidade (procura facilidade e conveniência)' },
  { value: 'velocidade', text: 'Velocidade (espera entrega ou resposta rápidas)' },
]


export const behaviorProfileData = [
  { value: '18_24', text: '18 a 24 anos' },
  { value: '25_34', text: '25 a 34 anos' },
  { value: '35_44', text: '35 a 44 anos' },
  { value: '45_54', text: '45 a 54 anos' },
  { value: '55_mais', text: '55 anos ou mais' },
]

export const clientPreferenceData = [
  { value: 'whatsApp', text: 'WhatsApp' },
  { value: 'telefone', text: 'Telefone' },
  { value: 'email', text: 'Email Marketing' },
  { value: 'redes_sociais', text: 'Redes Sociais' },
  { value: 'offline', text: 'Visitas à Loja' },
]

export const purchaseBehaviorData = [
  { value: 'compra_impulsiva', text: 'Compra impulsiva (decisão rápida)' },
  { value: 'compra_planejada', text: 'Compra planejada (pesquisa antes da compra)' },
  { value: 'compra_recorrente', text: 'Compra recorrente (assinaturas por exemplo)' },
  { value: 'compra_sazonal', text: 'Compra sazonal (relacionada a uma estação ou evento)' },
  { value: 'compra_emergencia', text: 'Compra de emergência (necessidade imediata)' },
]


export const purchaseChallengesData = [
  { value: 'sim_completamente', text: 'Ele entende claramente os benefícios do meu produto/serviço' },
  { value: 'sim_parcialmente', text: 'Ele ten uma noção básica mas não compreende os meus diferenciais' },
  { value: 'nao_entende', text: 'Ele não entende como o meu produto/serviço atende suas necessidades' },
  { value: 'sem_feedbacks', text: 'Não sei dizer pois nunca pedi feedback para meus clientes' },
  { value: 'sem_clientes', text: 'Ainda não tenho clientes' },
]

export const purchaseInfluencersData = [
  { value: 'reviews', text: 'Ter mais avaliações e recomendações' },
  { value: 'autoridade', text: 'Aumentar o alcance da minha marca' },
  { value: 'garantias', text: 'Oferecer garantia e suporte' },
  { value: 'indicacoes', text: 'Receber indicações de outros clientes' },
  { value: 'informativos', text: 'Conteúdo educativo sobre o meu produto/serviço' }
]

export const natureOfProduct = [
  { value: "Produtos Físicos", text: "Produtos Físicos" },
  { value: "Produtos Digitais", text: "Produtos Digitais" },
  { value: "Serviços", text: "Serviços" },
  { value: "Produtos Artesanais", text: "Produtos Artesanais" },
  { value: "Materiais de Construção", text: "Materiais de Construção" },
  { value: "Produtos Farmacêuticos", text: "Produtos Farmacêuticos" },
  { value: "Produtos Agrícolas", text: "Produtos Agrícolas" },
  { value: "Produtos de Entretenimento", text: "Produtos de Entretenimento" },
  { value: "Produtos Financeiros", text: "Produtos Financeiros" },
  { value: "Produtos de Viagem", text: "Produtos de Viagem" },
]

export const categoryOfProduct = {
  'Produtos Físicos': [
    { value: "Eletrônicos", text: "Eletrônicos" },
    { value: "Vestuário", text: "Vestuário" },
    { value: "Alimentos", text: "Alimentos" }
  ],
  'Produtos Digitais': [
    { value: "Software", text: "Software" },
    { value: "E-books", text: "E-books" },
    { value: "Cursos online", text: "Cursos online" }
  ],
  'Serviços': [
    { value: "Consultoria", text: "Consultoria" },
    { value: "Reparos", text: "Reparos" },
    { value: "Saúde e bem-estar", text: "Saúde e bem-estar" }
  ],
  'Produtos Artesanais': [
    { value: "Joias artesanais", text: "Joias artesanais" },
    { value: "Decoração", text: "Decoração" },
    { value: "Roupas e acessórios", text: "Roupas e acessórios" }
  ],
  'Materiais de Construção': [
    { value: "Madeira", text: "Madeira" },
    { value: "Ferramentas", text: "Ferramentas" },
    { value: "Acabamentos", text: "Acabamentos" }
  ],
  'Imobiliário': [
    { value: "Venda", text: "Venda" },
    { value: "Aluguel", text: "Aluguel" },
    { value: "Investimento", text: "Investimento" }
  ],
  'Produtos Farmacêuticos': [
    { value: "Medicamentos", text: "Medicamentos" },
    { value: "Suplementos", text: "Suplementos" },
    { value: "Cosméticos", text: "Cosméticos" }
  ],
  'Produtos Agrícolas': [
    { value: "Cereais", text: "Cereais" },
    { value: "Frutas", text: "Frutas" },
    { value: "Animais", text: "Animais" }
  ],
  'Produtos de Entretenimento': [
    { value: "Brinquedos", text: "Brinquedos" },
    { value: "Música", text: "Música" },
    { value: "Filmes e séries", text: "Filmes e séries" }
  ],
  'Produtos Financeiros': [
    { value: "Seguros", text: "Seguros" },
    { value: "Investimentos", text: "Investimentos" },
    { value: "Empréstimos", text: "Empréstimos" }
  ],
  'Produtos de Viagem': [
    { value: "Alojamento", text: "Alojamento" },
    { value: "Transporte", text: "Transporte" },
    { value: "Experiências", text: "Experiências" }
  ],
}

export const actionsFromPublish = [
  { value: "schedule", text: "Agendar" },
  { value: "now", text: "Publicar agora" },
]

export const availableLanguages = [
  { code: 'en_US', name: 'English', flag: 'united-states' },
  { code: 'pt_BR', name: 'Português', flag: 'brazil' },
  { code: 'es_ES', name: 'Español', flag: 'spain' },
];

export const socialMediaData = [
  { value: 'facebook', text: 'Facebook' },
  { value: 'instagram', text: 'Instagram' },
  { value: 'linkedin', text: 'LinkedIn' }
];

export const subTypeData = [
  { value: 'feed', text: 'Feed' },
  { value: 'story', text: 'Story' }
];

export const interactionChannel = [
  { value: 'f7d82e08-ddb0-4bd7-9465-95356a8f1257', text: 'Email' },
  { value: '69f932d6-d249-4d68-84fc-6edc83d0c0f5', text: 'Ligação' },
  { value: '366583b2-8b96-4d02-8c6c-42d61e99eb5b', text: 'WhatsApp' },
  { value: '724a84b4-6960-45db-a2a9-5ffec9f66741', text: 'Outro' }
];

export const taskCategory = [
  { value: '894faef0-d85e-4c65-b9de-dee90310be8d', text: 'Completar a Venda' },
  { value: 'b4d73cbe-a32f-45e2-97a5-9897efd17082', text: 'Cobrar Cliente' },
  { value: '0bdd7b90-ab4d-41c7-b52e-1d4dd1226ce2', text: 'Enviar Material Adicional' },
  { value: '73d7a716-a1cd-4d24-80db-c8d2b16d1b2e', text: 'Retomar Contato' },
  { value: 'ab794229-a686-4954-91fa-a13504e68a63', text: 'Tentar Primeiro Contato' }
];

export const templateCategoryData = [
  { value: 'Designers and Creatives', text: 'Designers and Creatives' },
  { value: 'Travel and Tourism', text: 'Travel and Tourism' },
  { value: 'Business and Consulting', text: 'Business and Consulting' },
  { value: 'Architecture and Decoration', text: 'Architecture and Decoration' },
  { value: 'Health and Wellness', text: 'Health and Wellness' },
  { value: 'Influencers and Content Creators', text: 'Influencers and Content Creators' },
  { value: 'Gyms and Trainers', text: 'Gyms and Trainers' },
  { value: 'Financial Services', text: 'Financial Services' },
  { value: 'Clinics and Medical Practices', text: 'Clinics and Medical Practices' },
  { value: 'Agencies and Marketing', text: 'Agencies and Marketing' },
  { value: 'Legal Services', text: 'Legal Services' },
  { value: 'Mentorship and Specializations', text: 'Mentorship and Specializations' },
  { value: 'Restaurants and Cafes', text: 'Restaurants and Cafes' },
  { value: 'Hotels and Accommodations', text: 'Hotels and Accommodations' },
  { value: 'Blogs and News', text: 'Blogs and News' },
  { value: 'Commercial Representatives', text: 'Commercial Representatives' },
  { value: 'Beauty and Aesthetics', text: 'Beauty and Aesthetics' },
  { value: 'Fashion and Style', text: 'Fashion and Style' },
  { value: 'Real Estate and Brokers', text: 'Real Estate and Brokers' },
  { value: 'Events and Parties', text: 'Events and Parties' },
  { value: 'Education and Courses', text: 'Education and Courses' },
  { value: 'Stores and E-commerce', text: 'Stores and E-commerce' },
  { value: 'Photographers and Videomakers', text: 'Photographers and Videomakers' },
  { value: 'Sports and Recreation', text: 'Sports and Recreation' },
  { value: 'Arts and Crafts', text: 'Arts and Crafts' },
  { value: 'Technology and IT', text: 'Technology and IT' },
  { value: 'Event Planners', text: 'Event Planners' },
  { value: 'Pet Services', text: 'Pet Services' }
];