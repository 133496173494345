import { inject } from "aurelia";
import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";

@valueConverter('filterLocalePrompt')
@inject(I18N)
export class FilterLocalePrompt {

  constructor(private I18N: I18N) { }

  toView(variations) {
    if (!variations) return [];

    const locale = this.I18N.getLocale();
    const value = variations.filter((v: { language: string; }) => v.language === locale);

    if (!value) return [];

    return value.prompt;
  }
}