import { nhost } from '@lib/nhost';
import { ModelTypes } from '@zeus';
import { IDialogService } from "@aurelia/dialog";
import { bindable, BindingMode, inject } from 'aurelia';
import { FileViewerDialog } from './components/file-viewer/file-viewer-dialog';

@inject(IDialogService)
export class ListFileUploadComponent {
  @bindable label: string;
  @bindable accept: string = 'image/*';
  @bindable deleteFileCallback: (id: string) => void;

  @bindable({ mode: BindingMode.twoWay }) files: ModelTypes['files'][];

  private elementid: string = crypto.randomUUID();

  constructor(
    private dialogService: IDialogService
  ) { }

  async changeFile(event: { target: { files: FileList; }; }) {
    const file = event.target.files[0]

    if (!file) return

    const { fileMetadata, error } = await nhost.storage.upload({ file: file, bucketId: 'public' });

    if (error) {
      console.error(error)

      return
    }

    if (fileMetadata.id) {
      this.files.unshift({ id: fileMetadata.id, name: fileMetadata.name, isUploaded: false } as ModelTypes['files'])
    }
  }

  private async handleDeleteFile(id: string): Promise<void> {
    this.deleteFileCallback(id);
  }

  async openViewImage(files: ModelTypes['files'][], index: number) {
    await this.dialogService.open({
      component: () => FileViewerDialog,
      model: { files, index, deleteFile: (id: string) => this.handleDeleteFile(id) },
      lock: true,
      startingZIndex: 100,
    });
  }
}