import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';
import { IDialogService } from '@aurelia/dialog';
import { InteractionDialog } from './components/interaction-dialog';
import { IOpportunityService } from '../../services/opportunity-service';
import { IInteractionsService } from './services/interactions-service';

@inject(IDialogService, IInteractionsService, IOpportunityService)
export class InteractionsSection {
  private opportunity: ModelTypes["deal"];
  private interactions: ModelTypes["dealInteraction"][] = [];

  constructor(
    private dialogService: IDialogService,
    private service: IInteractionsService,
    private opportunityService: IOpportunityService
  ) { }

  attached() {
    this.opportunity = this.opportunityService.opportunitySubscription;
    this.interactions = this.opportunity?.interactions;
  }

  @watch('opportunityService.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal']) {
    this.opportunity = newData;
    this.interactions = this.opportunity?.interactions;
  }

  private getChannelById(channelId: string) {
    return this.service.getChannelById(channelId);
  }

  private async openInteractionDialog() {
    await this.dialogService.open({
      component: () => InteractionDialog,
      model: { opportunityId: this.opportunity.id },
      lock: false,
      startingZIndex: 100,
    });
  }
}