import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/generic-kanban/generic-kanban-component";
import * as d1 from "./../../components/flow-card/flow-card-component.ts";
export const name = "opportunity-management-kanban-page";
export const template = "\n\n\n\n<section>\n\n  <generic-kanban-component config.bind=\"config\">\n    <div au-slot=\"body\">\n      <div repeat.for=\"deal of $host.deals\" style=\"min-width: 18rem; margin-bottom: 1rem;\" class=\"overflow-y-auto shadow-lg rounded-2xl\">\n        <!-- begin::flow-card-component -->\n        <flow-card-component deal.bind=\"deal\"></flow-card-component>\n        <!-- end::flow-card-component -->\n      </div>\n    </div>\n  </generic-kanban-component>\n\n</section>";
export default template;
export const dependencies = [ d0, d1 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
