import { inject } from "aurelia";
import { I18N } from '@aurelia/i18n';
import { IEventAggregator } from 'aurelia';
import { IDialogService } from '@aurelia/dialog';
import { toastifyError, toastifySuccess } from '@helpers/toastify';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IGenericListConfig, GenericListComparsionType, GenericListFildInputType } from '@components/generic-list/interfaces/generic-filtered-interface';
import { CreateProjectDialog } from '@components/create-project-dialog/create-project-dialog';

@inject(I18N, IAuthService, IZeusClient, IEventAggregator, IDialogService)
export class ProjectsPage {

  private brandId: string;
  private config: IGenericListConfig;

  constructor(
    private I18N: I18N,
    private auth: IAuthService,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private dialogService: IDialogService,
  ) { }

  loading(params: { id: string; }) {
    this.brandId = params.id;

    this.config = {
      total: 0,
      tableName: 'project',
      streamName: 'project_stream',
      aggregateName: 'project_aggregate',
      columns: ['id', 'alias', 'status', 'type', 'previewImageFileId', 'publishDate', 'scheduledDate', 'deleted'],
      fixedFilters: [{
        comparsionType: GenericListComparsionType.Equals,
        title: 'brandId',
        field: 'brandId',
        type: GenericListFildInputType.Text,
        value: this.brandId,
      }, {
        comparsionType: GenericListComparsionType.In,
        title: 'type',
        field: 'type',
        type: GenericListFildInputType.Text,
        value: ['post'],
      }],
      filters: [],
      data: [],
      perPage: 12,
      currentPage: 1,
    }
  }

  private async deleteProject(projectId: string) {
    const { id } = await this.zeusClient.deleteProjectById(projectId);

    if (!id) {
      toastifyError({ message: 'Erro ao excluir criação' });
      return;
    }

    toastifySuccess({ message: 'Criação excluida com sucesso' });
  }

  private setIconByType(type: string): string {
    switch (type) {
      case 'image':
        return 'fa-regular fa-image';
      case 'advert':
        return 'fa-solid fa-rectangle-ad';
      case 'post':
        return 'fa-solid fa-calendar-days';
      case 'text':
        return 'fa-solid fa-blog';
      case 'video':
        return 'fa-solid fa-video';
      case 'print':
        return 'fa-solid fa-print';
      case 'presentation':
        return 'fa-solid fa-person-chalkboard';
      default:
        return 'fas fa-question';
    }
  }

  private colorStatus(status: string): string {
    switch (status) {
      case 'draft':
        return 'red';
      case 'scheduled':
        return 'amber';
      case 'posted':
        return 'emerald';
      default:
        return 'blue';
    }
  }

  private async openCreateProjectDialog() {
    await this.dialogService.open({
      component: () => CreateProjectDialog,
      model: {},
      lock: false,
      startingZIndex: 100,
    });
  }
}