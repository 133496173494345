import { inject } from "aurelia";
import { I18N } from '@aurelia/i18n';
import { IEventAggregator, ISignaler, bindable } from 'aurelia';
import { Dropdown, DropdownOptions, DropdownInterface } from 'flowbite';
import { availableLanguages } from '@data/index';

@inject(I18N, ISignaler, IEventAggregator)
export class LanguagePickerComponent {

	@bindable isOpenDrawer: boolean;

	private elementId: string = Math.random().toString(36).substring(7);
	private selectedLanguage = localStorage.getItem('locale') || 'en_US';
	private selectedLanguageFriendlyName = availableLanguages.find(l => l.code === this.selectedLanguage).name;
	private selectedLanguageFlag = availableLanguages.find(l => l.code === this.selectedLanguage).flag;

	private availableLanguages = availableLanguages

	private dropdown: DropdownInterface


	constructor(
		private I18N: I18N,
		readonly signaler: ISignaler,
		readonly ea: IEventAggregator
	) { }

	attached() {
		this.selectedLanguage = this.I18N.getLocale()
		// this.bodyClickEventListener= window.document.body.addEventListener('click', this.closeDropDownMenu.bind(this));
		const $targetEl: HTMLElement = document.getElementById(`language-dropdown-${this.elementId}`);
		const $triggerEl: HTMLElement = document.getElementById(this.elementId);
		const options: DropdownOptions = {
			placement: 'bottom-start',
			triggerType: 'click',
			offsetSkidding: 10,
			offsetDistance: 20,
			delay: 300,
		};
		this.dropdown = new Dropdown($targetEl, $triggerEl, options);
		this.ea.subscribe('language-changed', (payload) => {
			if (payload['elementId'] !== this.elementId) {
				this.selectedLanguage = payload['language'];

				this.selectedLanguageFlag = this.availableLanguages.find(l => l.code === payload['language']).flag;
				this.selectedLanguageFriendlyName = this.availableLanguages.find(l => l.code === payload['language']).name;
			}
		});
	}

	showDropdown() {
		this.dropdown.show();
	}
	setLocale(loc: string) {
		this.selectedLanguage = loc;
		this.selectedLanguageFlag = this.availableLanguages.find(l => l.code === loc).flag;
		this.selectedLanguageFriendlyName = this.availableLanguages.find(l => l.code === loc).name;
		this.I18N.setLocale(loc);
		this.signaler.dispatchSignal('parameterChanged');
		localStorage.setItem('locale', loc);
		this.ea.publish('language-changed', { language: loc, elementId: this.elementId });
		this.dropdown.hide();
	}

}