import Tagify from '@yaireo/tagify'
import { watch } from "@aurelia/runtime-html";
import { BindingMode, bindable } from 'aurelia';

import '@yaireo/tagify/dist/tagify.css'

export class TagifySuggestionsListComponent {
  private id: string = crypto.randomUUID();

  @bindable label: string;
  @bindable maxTags: number;
  @bindable({ mode: BindingMode.twoWay }) whitelist: string[] | Tagify.BaseTagData[];

  @bindable({ mode: BindingMode.twoWay }) tagify: Tagify;

  attached() {
    this.tagify = new Tagify(document.querySelector(`#taglify-${this.id}`), {
      whitelist: this.whitelist ?? [],
      maxTags: this.maxTags || Infinity,
      placeholder: 'Enter tags',
      enforceWhitelist: true,
      dropdown: {
        enabled: 0,
        maxItems: 5,
        closeOnSelect: true,
        highlightFirst: true,
        classname: "tags-look",
      },
      templates: {
        dropdownItemNoMatch: function (data) {
          return `<div class='' value="noMatch" tabindex="0" role="option">
                No suggestion found for: <strong>${data.value}</strong>
            </div>`
        }
      }
    })
  }

  @watch('whitelist')
  handleWhitelistChange(newValue: string[] | Tagify.BaseTagData[], oldValue: string[] | Tagify.BaseTagData[]) {
    this.tagify.settings.whitelist = newValue;
    this.tagify.update();
  }

  detached() {
    this.tagify.destroy();
  }
}