import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/loading/loading-component";
import * as d1 from "./sections/our-history/our-history-section.ts";
import * as d2 from "./sections/what-move-us/what-move-us-section.ts";
import * as d3 from "./sections/strong-statement/strong-statement-section.ts";
import * as d4 from "./sections/we-are-ready/we-are-ready-section.ts";
import * as d5 from "./sections/contact-form/contact-form-section.ts";
export const name = "about-module";
export const template = "\n\n\n\n\n\n\n\n\n<div class=\"flex flex-col gap-4 my-6\">\n\n  <template if.bind=\"website\">\n    <our-history-section website.bind=\"website\"></our-history-section>\n\n    <what-move-us-section website.bind=\"website\"></what-move-us-section>\n\n    <strong-statement-section website.bind=\"website\"></strong-statement-section>\n\n    <we-are-ready-section website.bind=\"website\"></we-are-ready-section>\n\n    <contact-form-section website.bind=\"website\"></contact-form-section>\n  </template>\n\n  <template else>\n    <loading-component></loading-component>\n  </template>\n\n</div>";
export default template;
export const dependencies = [ d0, d1, d2, d3, d4, d5 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
