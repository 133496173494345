import { valueConverter } from 'aurelia';

@valueConverter('currency')
export class CurrencyFormatConverter {
  toView(value: number | bigint, locale = 'en-US', showSymbol = true) {

    if (!value) {
      return value;
    }

    let formatter: Intl.NumberFormat

    if (showSymbol) {
      formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'BRL'
      });
    } else {
      formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    return formatter.format(value);
  }
}