import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { ITemplateService } from '../../services/templates-service';
import { IRouter } from '@aurelia/router';

@inject(IRouter,ITemplateService)
export class AdvertTemplatesPage {
  private channel: string = 'adverts';

  constructor(    private router: IRouter,    private service: ITemplateService  ) { }

  private async getNewTemplateType(channel: string): Promise<ModelTypes['newTemplateType'][]> {
    const result = await this.service.getNewTemplateType(channel);

    return result;
  }

  private async handleRedirect(): Promise<void> {
    await this.router.load('/editor');
  }
}