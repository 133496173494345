import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { bindable } from 'aurelia';
import { watch } from '@aurelia/runtime-html';

import { IOpportunityService } from '../../services/opportunity-service';

@inject(IOpportunityService,)
export class StatusBarComponent {
  @bindable opportunity: ModelTypes["deal"];

  constructor(    private service: IOpportunityService,  ) { }

  @watch('opportunity')
  async handleDealSubscription(newData: ModelTypes['deal'], oldData: ModelTypes['deal']) {
    this.opportunity = newData;
  }
}