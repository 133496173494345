import { AccordionItem } from 'flowbite';

import { IFaq } from './interfaces/faq-interface'
import { faqData } from './data'

export class FaqPage {

  private currentCategoryIndex: number = 0;

  faqs: IFaq[] = [];
  accordionItems: AccordionItem[][] = [];

  attached() {
    this.faqs = faqData;
  }

  private changeCategory(index: number): void {
    this.currentCategoryIndex = index;
  }
}