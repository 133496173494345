import { CustomElement } from '@aurelia/runtime-html';
export const name = "table-actions-component";
export const template = "<button id=\"dropdown-${project.id}-button\" type=\"button\"\n  class=\"inline-flex items-center p-2 text-xs font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600\">\n  <svg class=\"w-5 h-5\" aria-hidden=\"true\" fill=\"currentColor\" viewBox=\"0 0 20 20\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z\"></path>\n  </svg>\n</button>\n\n<div id=\"dropdown-${project.id}\"\n  class=\"z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-md w-44 dark:bg-gray-700 dark:divide-gray-600\">\n  <ul class=\"py-2 text-sm text-gray-700 dark:text-gray-200\" aria-labelledby=\"dropdown-${project.id}-button\">\n    <li class=\"py-2\"\n      if.bind=\"['user-premium','user-freetrial','copymagico-admin'].includes(authService.user.defaultRole)\">\n      <span click.trigger=\"handleRedirectProject()\"\n        class=\"block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer\"><i\n          class=\"fa fa-pen-to-square pr-2\"></i> Editar</span>\n    </li>\n    <div class=\"py-2\">\n      <span click.trigger=\"handleDeleteProject()\"\n        class=\"block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer\"><i\n          class=\"fa fa-trash pr-2\"></i> Excluir</span>\n    </div>\n  </ul>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
