import { inject } from "aurelia";
import { ModelTypes, order_by } from '@zeus';
import { toastifyError } from '@helpers/toastify';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { AddCardDialog } from "./add-card-dialog/add-card-dialog";
import { IDialogService } from "@aurelia/dialog";

@inject(IAuthService, IZeusClient, IDialogService)
export class UserSubscriptionComponent {

  constructor(
    private auth: IAuthService,
    private zeusClient: ZeusClient,
    private dialogService: IDialogService,
  ) { }

  async getPricingPlans(): Promise<ModelTypes["pricingPlan"][]> {
    const result = await this.zeusClient.chain('query')({
      pricingPlan: [
        {
          order_by: [{ order: order_by.asc }]
        },
        {
          id: true,
          stripeProductId: true,
          stripePriceId: true,
          created_at: true,
          updated_at: true,
          alias: true,
          order: true,
          htmlFeatureItems: true,
          monthlyPrice: true,
        }
      ]
    });

    return result.pricingPlan as ModelTypes["pricingPlan"][];
  }

  async getManageSubscription() {
    const query = await this.zeusClient.chain('query')({
      manageSubscription: {
        url: true,
      }
    });

    const result = query.manageSubscription;

    window.location.href = result.url;
  }

  async subscribe(priceId: string) {
    try {
      const user = this.auth.getUser();

      if (!user) throw new Error('User not found');

      const urlResponse = await this.zeusClient.chain('query')({
        createCheckoutSession: [
          {
            priceId: 'priceId',
            email: user.email,
          },
          {
            url: true
          }
        ]
      })

      if (!urlResponse?.createCheckoutSession?.url) throw new Error('Error getting payment URL')

      window.location.href = urlResponse.createCheckoutSession.url;

      window.dataLayer.push({ event: 'purchaseIntent_' + priceId });
    } catch (error) {
      console.error(error);
      toastifyError({ message: 'Erro ao assinar plano' });
    }
  }


  async getInvoices() {
    const result = await this.zeusClient.chain('query')({
      stripeInvoices:
      {
        id: true,
        amount_paid: true,
        created: true,
        status: true,
        invoice_pdf: true,

      }

    });

    return result.stripeInvoices as ModelTypes["StripeInvoicesOutput"][];
  }
  async getPaymentMethods() {
    const result = await this.zeusClient.chain('query')({
      stripePaymentMethods: {
        id: true,
        card: {
          expMonth: true,
          expYear: true,
          brand: true,
          last4: true,
        }
      }
    });

    return result.stripePaymentMethods as ModelTypes["StripePaymentMethodsOutput"][];
  }

  async deletePaymentMethod(paymentMethodId: string) {
    // await this.zeusClient.chain('mutation')({
    //   deletePaymentMethod: {
    //     paymentMethodId: paymentMethodId
    //   }
    // });
    console.log('deletePaymentMethod', paymentMethodId);
  }
  async openAddPaymentMethodDialog() {
    await this.dialogService.open({
      component: () => AddCardDialog,
      model: {},
      lock: true,
      startingZIndex: 100,
    });

  }
}