import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { IImagineService } from './services/imagine-service';
import { IAspectRatioData } from './components/aspect-ratio/data';
import { toastifyError, toastifySuccess, toastifyWarning } from '@helpers/toastify';
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { IDialogService } from "@aurelia/dialog";
import { GoPremiumDialog } from "@components/calendar/components/go-premium-dialog/go-premium-dialog";
import { FullScreenSpinnerDialog } from "@components/full-screen-spinner-dialog/full-screen-spinner-dialog";
import { GoogleAnalyticsEventAction, GoogleAnalyticsEventCategory, GoogleAnalyticsHelper } from "@helpers/google-analytics/google-analytics-helper";

@inject(IImagineService, IAuthService, IDialogService)
export class ImagineModule {
  private ilustration: string = null;

  private brandId: string = null;
  // private productId: string = null;
  // private audienceId: string = null;

  private prompt: string;
  private selectedBrandId: string;
  // private selectedProductId: string;
  // private selectedAudienceId: string;

  // private seed: number = null;
  // private cfgScale: number = 3;
  // private stepScale: number = 30;
  // private negativePrompt: string = null;
  // private selectedModel: IModelsData = null;
  // private selectedAtrStyle: IAtrStyleData = null;
  private selectedAspectRatio: IAspectRatioData = null;

  private brands: ModelTypes['brands'][];
  // private products: ModelTypes['products'][];
  // private audiences: ModelTypes['audiences'][];

  constructor(private service: IImagineService,
    private authService: IAuthService,
    private dialogService: IDialogService,
  ) { }

  async attached() {
    await this.handleGetBrands();

    this.selectedBrandId = this.brandId;
    // this.selectedProductId = this.productId;
    // this.selectedAudienceId = this.audienceId;

    if (!this.selectedBrandId) {
      this.selectedBrandId = this.brands[0].id;
    }
  }

  private async handleGetBrands(): Promise<void> {
    this.brands = await this.service.brands();
    // this.products = await this.service.products();
    // this.audiences = await this.service.audiences();
  }

  private async handleGenerate(): Promise<void> {
    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }

    if (!this.prompt) {
      toastifyWarning({ message: 'Informe um texto para gerar a ilustração' });
      return;
    }

    if (!this.selectedAspectRatio) {
      toastifyWarning({ message: 'Selecione um aspecto para gerar a ilustração' });
      return;
    }
    const spinnerDialog = await this.dialogService.open({
      component: () => FullScreenSpinnerDialog,
      model: {},
      lock: true,
      startingZIndex: 100,
    });
    try {
      const { fileId, success } = await this.service.imagine({
        prompt: this.prompt,
        aspectRatio: this.selectedAspectRatio?.value,
      });

      if (!success) {
        toastifyError({ message: 'Erro ao tentar gerar ilustração' });
        return;
      }
      GoogleAnalyticsHelper.trackEvent(GoogleAnalyticsEventAction.Click, GoogleAnalyticsEventCategory.Image, 'generate-illustration');

      toastifySuccess({ message: 'Ilustração gerada com sucesso' });

      this.ilustration = `https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${fileId}`
    } catch (error) {
      toastifyError({ message: error });
    } finally {
      spinnerDialog.dialog.ok();
    }
  }
}