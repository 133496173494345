import { valueConverter } from 'aurelia';
import moment from 'moment';

@valueConverter('timeago')
export class TimeagoConverter {
  toView(value: string) {

    const date = new Date(value);

    if (Number.isNaN(date.valueOf())) {
      return '';
    }

    moment.locale('pt-br');

    return moment(date).fromNow(true);
  }
}