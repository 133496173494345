/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from "aurelia";
import { bindable } from 'aurelia';
import { ModelTypes } from '@zeus';
import { Calendar } from '@fullcalendar/core';
import { IDialogService } from '@aurelia/dialog';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ICalendarService } from './services/calendar-service';
import { ViewCalendarEventDialog } from './components/view-calendar-event-dialog/view-calendar-event-dialog';

import '@fullcalendar/core/locales/pt-br';

@inject(ICalendarService, IDialogService)
export class CalendarComponentWidget {
  @bindable hideEvents = false;

  calendarHtmlElement
  calendar: Calendar;
  projects: ModelTypes["project"][] = [];

  constructor(
    private service: ICalendarService,
    private dialogService: IDialogService
  ) { }

  attached() {
    this.calendar = new Calendar(this.calendarHtmlElement, {
      plugins: [dayGridPlugin],
      themeSystem: 'united',
      locale: 'pt-br',
      moreLinkText: (num) => `+${num} mais`,
      buttonText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'Lista'
      },
      initialView: 'dayGridMonth',
      events: this.getEvents.bind(this),
      dayMaxEvents: 4,
      eventClick: (async function (event: { event: { extendedProps: any; }; }) {
        const extendedProps = event.event.extendedProps;

        await this.dialogService.open({
          component: () => ViewCalendarEventDialog,
          model: { project: extendedProps },
          lock: false,
          keyboard: ['Escape'],
          startingZIndex: 100,
        });

      }).bind(this)
    });

    this.calendar.render();
  }


  private async getEvents(info, successCallback) {
    const start = info.startStr;
    const end = info.endStr;

    const projects = await this.service.getProjects(start, end);

    const events = projects.map(project => {
      return {
        title: project.alias || '...',
        start: project.calendarDate,
        end: project.calendarDate,
        allDay: true,
        extendedProps: project
      }
    });

    successCallback(events);
  }

}