import { CustomElement } from '@aurelia/runtime-html';
export const name = "icons-component";
export const template = "<!-- <div innerhtml.bind=\"icons[icon]\"></div> -->\n\n<div>\n  <template promise.bind=\"getIcon(icon)\">\n    <template then.bind=\"data\">\n      <div innerhtml.bind=\"data\"></div>\n    </template>\n  </template>\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
