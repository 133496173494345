import { inject } from "aurelia";
import { products_select_column } from '@zeus';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IAuthService, IZeusClient)
export class ProductsComponent {
  constructor(
    private auth: IAuthService,
    private zeusClient: ZeusClient,
  ) { }

  private async getProductAggregate(): Promise<number> {
    const result = await this.zeusClient.chain('query')({
      products_aggregate: [
        {
          where: {
            deleted: { _eq: false },
            created_by: { _eq: this.auth.getUser().id }
          }
        },
        {
          aggregate: {
            count: [
              { columns: [products_select_column.id] },
              true
            ]
          }
        }
      ]
    });

    return result.products_aggregate.aggregate.count as number;
  }
}