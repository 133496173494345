import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "./sections/tasks/tasks-section.ts";
import * as d1 from "./sections/contact/contact-section.ts";
import * as d2 from "./sections/products/products-section.ts";
import * as d3 from "./sections/attachments/attachments-section.ts";
import * as d4 from "./sections/interactions/interactions-section.ts";
import * as d5 from "./components/status-bar/status-bar-component.ts";
export const name = "opportunity-page";
export const template = "\n\n\n\n\n\n\n\n<section>\n\n  <!-- begin:status-bar-component -->\n  <status-bar-component opportunity.bind=\"service.opportunitySubscription\"></status-bar-component>\n  <!-- end:status-bar-component -->\n\n  <div class=\"flex w-full items-center justify-center\">\n    <div class=\"h-full w-full grid-cols-3 grid md:grid-cols-12 grid-rows-12 gap-4\">\n\n      <!-- begin:contact-section -->\n      <div class=\"col-span-4 row-span-7 card overflow-auto hide-scrollbar\">\n        <contact-section></contact-section>\n      </div>\n      <!-- end:contact-section -->\n\n      <!-- begin::tasks-section -->\n      <div class=\"col-span-4 row-span-6 card overflow-auto hide-scrollbar\">\n        <tasks-section></tasks-section>\n      </div>\n      <!-- end::tasks-section -->\n\n      <!-- begin::products-section -->\n      <div class=\"col-span-4 row-span-12 card overflow-auto hide-scrollbar\">\n        <products-section></products-section>\n      </div>\n      <!-- end::products-section -->\n\n      <!-- begin::interactions-section -->\n      <div class=\"col-span-4 row-span-6 card overflow-auto hide-scrollbar\">\n        <interactions-section></interactions-section>\n      </div>\n      <!-- end::interactions-section -->\n\n      <!-- begin::attachments-section -->\n      <div class=\"col-span-4 row-span-5 card overflow-auto hide-scrollbar\">\n        <attachments-section></attachments-section>\n      </div>\n      <!-- end::attachments-section -->\n\n    </div>\n  </div>\n\n</section>";
export default template;
export const dependencies = [ d0, d1, d2, d3, d4, d5 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
