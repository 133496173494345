import { inject } from "aurelia";
import { bindable } from "aurelia";
import { I18N } from '@aurelia/i18n';
import { initPopovers } from "flowbite";

interface HeaderProps {
  [key: string]: {
    [key: string]: {
      title: string;
      subtitle: string;
      popoverTitle: string;
      popoverDescription: string;
    }
  }
}

@inject(I18N)
export class CardHeaderComponent {
  @bindable title: string
  @bindable subtitle: string
  @bindable headerId: string
  @bindable showPopover: boolean = true

  elementId: string = Math.random().toString(36).substring(7);

  constructor(private I18N: I18N) { }

  attached() {
    initPopovers();
  }

  private headers: HeaderProps = {
    projetos_left: {
      pt_BR: {
        title: 'Parâmetros da Arte Criada',
        subtitle: 'Essas foram as variáveis selecionadas pela nossa inteligência artificial',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Created Art Parameters',
        subtitle: 'These were the variables selected by our artificial intelligence',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Parámetros del Arte Creado',
        subtitle: 'Estas fueron las variables seleccionadas por nuestra inteligencia artificial',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    projetos_right: {
      pt_BR: {
        title: 'Pronto Para Postar!',
        subtitle: 'Ao clicar em Salvar ou Publicar, a marca copymagico será automaticamente removida',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Ready to Post!',
        subtitle: 'By clicking Save or Publish, the Copymagic watermark will be automatically removed',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: '¡Listo para Publicar!',
        subtitle: 'Al hacer clic en Guardar o Publicar, la marca de agua de copymagico se eliminará automáticamente',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    projetos_left_color: {
      pt_BR: {
        title: 'Revise as Cores da Arte',
        subtitle: 'Alterações serão automaticamente salvas',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Review Art Colors',
        subtitle: 'Changes will be automatically saved',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Revisa los Colores del Arte',
        subtitle: 'Los cambios se guardarán automáticamente',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    projetos_left_texts: {
      pt_BR: {
        title: 'Revise Textos e Descrições',
        subtitle: 'Alterações serão automaticamente salvas',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Review Texts and Descriptions',
        subtitle: 'Changes will be automatically saved',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Revisa Textos y Descripciones',
        subtitle: 'Los cambios se guardarán automáticamente',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    projetos_left_images: {
      pt_BR: {
        title: 'Revise Imagens, Logos ou Avatar',
        subtitle: 'Alterações serão automaticamente salvas',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Review Images, Logos or Avatar',
        subtitle: 'Changes will be automatically saved',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Revisa Imágenes, Logos o Avatar',
        subtitle: 'Los cambios se guardarán automáticamente',
        popoverTitle: '',
        popoverDescription: '',
      },

    },


    calendario: {
      pt_BR: {
        title: 'Calendar',
        subtitle: 'Subtitle Calendar',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: '',
        subtitle: '',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: '',
        subtitle: '',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    audiencias: {
      pt_BR: {
        title: 'Complete as Informações',
        subtitle: 'Usaremos essas informações para refinar a audiência e mapear as personas',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Complete the Information',
        subtitle: 'We will use this information to refine the audience and map the personas',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Completa la Información',
        subtitle: 'Utilizaremos esta información para refinar la audiencia y mapear las personas',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    marcas: {
      pt_BR: {
        title: 'Complete as Informações',
        subtitle: 'Essas informações ajudam no processo criativo da Inteligência Artificial',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Complete the Information',
        subtitle: 'We will use this information to identify the archetype of your corporate or personal brand',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Completa la Información',
        subtitle: 'Utilizaremos esta información para identificar el arquetipo de tu marca corporativa o personal',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    produtos: {
      pt_BR: {
        title: 'Complete as Informações',
        subtitle: 'Usaremos essas informações na construção automática das artes',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Complete the Information',
        subtitle: 'We will use this information in the automatic creation of the artwork',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Completa la Información',
        subtitle: 'Utilizaremos esta información en la construcción automática de las artes',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    produtosFotos: {
      pt_BR: {
        title: 'Inclua imagens para destacar',
        subtitle: 'As imagens serão utilizadas no site, materiais de suporte e social media',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Complete the Information',
        subtitle: 'We will use this information in the automatic creation of the artwork',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Completa la Información',
        subtitle: 'Utilizaremos esta información en la construcción automática de las artes',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    produtosCaracteristicas: {
      pt_BR: {
        title: 'Reforce as suas caracteristicas',
        subtitle: 'As características ajudam a entender o produto ou serviço',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Complete the Information',
        subtitle: 'We will use this information in the automatic creation of the artwork',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Completa la Información',
        subtitle: 'Utilizaremos esta información en la construcción automática de las artes',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    produtosDiferenciais: {
      pt_BR: {
        title: 'Explique os principais diferenciais',
        subtitle: 'Os diferenciai são fundamentais para ajudar na tomada de decisão',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Complete the Information',
        subtitle: 'We will use this information in the automatic creation of the artwork',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Completa la Información',
        subtitle: 'Utilizaremos esta información en la construcción automática de las artes',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    modalGenerateProjectFirstSession: {
      pt_BR: {
        title: 'Informações gerais',
        subtitle: 'Determine o direcional para publicação',
        popoverTitle: 'Importância do Briefing ',
        popoverDescription: 'Complete todas as informações para criar o conteúdo perfeito sem necessidade de edições ou retrabalho',
      },
      en_US: {
        title: 'General information',
        subtitle: 'Determine the publishing direction',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Informaciones generales',
        subtitle: 'Determinar la dirección de publicación.',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    modalGenerateProjectSecondSession: {
      pt_BR: {
        title: 'Escolha um template',
        subtitle: 'Determine o layout da sua publicação',
        popoverTitle: 'Estrutura da Publicação',
        popoverDescription: 'Não se preocupe pois o texto e a imagem serão substituídos automaticamente',
      },
      en_US: {
        title: 'Choose a template',
        subtitle: 'Determine the layout of your post',
        popoverTitle: 'Post Structure',
        popoverDescription: 'Dont worry, the text and image will be automatically replaced',
      },
      es_ES: {
        title: 'Elige una plantilla',
        subtitle: 'Determina el diseño de tu publicación',
        popoverTitle: 'Estructura de la Publicación',
        popoverDescription: 'No te preocupes, el texto y la imagen se reemplazarán automáticamente',
      },
    },
    modalGenerateProjectThirdSession: {
      pt_BR: {
        title: 'Complete a publicação',
        subtitle: 'Crie, procure ou envie uma imagem para finalizar a publicação',
        popoverTitle: 'Selecione a imagem',
        popoverDescription: 'Envie uma imagem do seu computador, procure por uma imagem na internet ou crie uma imagem a partir da I.A',
      },
      en_US: {
        title: 'Complete the publication',
        subtitle: 'Create, find or upload an image to finish publishing',
        popoverTitle: 'Select the image',
        popoverDescription: 'Upload an image from your computer, search for an image online, or create an image using AI',
      },
      es_ES: {
        title: 'Completa la publicación',
        subtitle: 'Crea, busca o sube una imagen para terminar de publicar',
        popoverTitle: 'Selecciona la imagen',
        popoverDescription: 'Sube una imagen desde tu computadora, busca una imagen en internet o crea una imagen utilizando I.A.',
      },
    },
    myAccountSubscription: {
      pt_BR: {
        title: 'Contrate ou gerencie a sua assinatura',
        subtitle: 'Pronto para profissionalizar o seu negócio? Contrate o plano pago para destravar todo potencial da copymagico',
        popoverTitle: 'Como funciona?',
        popoverDescription: 'Cobrança por assinatura: A copymagico fará uma cobrança mensal recorrente para utilização da ferramenta de acordo com os termos e condições de nossa politica',
      },
      en_US: {
        title: 'Upgrade to PRO',
        subtitle: 'Ready to professionalize your business? Subscribe to the paid plan to unlock Copymagicos full potential',
        popoverTitle: 'How it works',
        popoverDescription: 'Subscription billing: Copymagico will charge a recurring monthly fee for using the platform in accordance with the terms and conditions of our policy',
      },
      es_ES: {
        title: 'Elige el plan ideal',
        subtitle: '¿Listo para profesionalizar tu negocio? Suscríbete al plan pago para desbloquear todo el potencial de Copymagico',
        popoverTitle: '¿Como funciona?',
        popoverDescription: 'Facturación por suscripción: Copymagico realizará un cobro mensual recurrente por el uso de la plataforma de acuerdo con los términos y condiciones de nuestra política',
      },
    },
    invoicesSection: {
      pt_BR: {
        title: 'Faturas',
        subtitle: 'Aqui você encontra todas as faturas geradas pela copymagico',
        popoverTitle: 'Sobre as faturas',
        popoverDescription: 'Aqui você encontra todas as faturas geradas pela copymagico',
      },
      en_US: {
        title: 'Invoices',
        subtitle: 'Here you will find all invoices generated by copymagico',
        popoverTitle: 'About invoices',
        popoverDescription: 'Here you will find all invoices generated by copymagico',
      },
      es_ES: {
        title: 'Facturas',
        subtitle: 'Aquí encontrarás todas las facturas generadas por copymagico',
        popoverTitle: 'Sobre las facturas',
        popoverDescription: 'Aquí encontrarás todas las facturas generadas por copymagico',
      },
    },
    myPaymentMethods: {
      pt_BR: {
        title: 'Métodos de pagamento',
        subtitle: 'Adicione ou remova métodos de pagamento para assinatura',
        popoverTitle: 'Sobre os métodos de pagamento',
        popoverDescription: 'Adicione ou remova métodos de pagamento para assinatura',
      },
      en_US: {
        title: 'Payment methods',
        subtitle: 'Add or remove payment methods for subscription',
        popoverTitle: 'About payment methods',
        popoverDescription: 'Add or remove payment methods for subscription',
      },
      es_ES: {
        title: 'Métodos de pago',
        subtitle: 'Agregue o elimine métodos de pago para la suscripción',
        popoverTitle: 'Sobre los métodos de pago',
        popoverDescription: 'Agregue o elimine métodos de pago para la suscripción',
      },
    },
    myAccountForm: {
      pt_BR: {
        title: 'Informações principais da sua conta',
        subtitle: 'Essas informações influenciam diretamente a experiência de criação com a copymagico',
        popoverTitle: 'Sobre a sua conta',
        popoverDescription: 'Revise os dados a seguir pois eles influenciam a sua experiência com a copymagico',
      },
      en_US: {
        title: 'Key account information',
        subtitle: 'This information directly influences the creation experience with copymagico',
        popoverTitle: 'About your account',
        popoverDescription: 'Please review the data below as it influences your experience with copymagico',
      },
      es_ES: {
        title: 'Información clave de la cuenta',
        subtitle: 'Esta información influye directamente en la experiencia de creación con copymagico',
        popoverTitle: 'Sobre tu cuenta',
        popoverDescription: 'Revise los datos a continuación, ya que influyen en su experiencia con copymagico',
      },
    },
    myAccountGraphic: {
      pt_BR: {
        title: 'Gráfico de consumo',
        subtitle: 'Acompanhe o volume de utilizações da copymagico',
        popoverTitle: 'Monitore o seu consumo',
        popoverDescription: 'Selecione uma data início e uma data fim para avaliar o consumo no período',
      },
      en_US: {
        title: 'Consumption graph',
        subtitle: "Keep track of copymagico's usage volume",
        popoverTitle: 'Monitor your consumption',
        popoverDescription: 'Select a start date and an end date to evaluate consumption in the period',
      },
      es_ES: {
        title: 'Gráfico de consumo',
        subtitle: 'Mantenga un registro del volumen de uso de copymagico',
        popoverTitle: 'Controla tu consumo',
        popoverDescription: 'Seleccione una fecha de inicio y una fecha de finalización para evaluar el consumo en el período',
      },
    },
    myAccountChangePassword: {
      pt_BR: {
        title: 'Segurança',
        subtitle: 'Mudar senha de acesso',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: 'Security',
        subtitle: 'Change access password',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: 'Seguridad',
        subtitle: 'Cambiar contraseña de acceso',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
    wololo: {
      pt_BR: {
        title: '',
        subtitle: '',
        popoverTitle: '',
        popoverDescription: '',
      },
      en_US: {
        title: '',
        subtitle: '',
        popoverTitle: '',
        popoverDescription: '',
      },
      es_ES: {
        title: '',
        subtitle: '',
        popoverTitle: '',
        popoverDescription: '',
      },
    },
  }
}