import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/undraw/undraw-component";
import * as d1 from "@components/card-header/card-header-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
export const name = "go-premium-dialog";
export const template = "\n\n\n\n<div class=\"bg-white rounded-lg shadow dark:bg-gray-700 h-full w-full p-5\">\n\n  <!-- begin::header -->\n  <div class=\"flex items-start justify-between p-2 border-b rounded-t dark:border-gray-600\"\n    style=\"align-items: center;\">\n\n    <div class=\"flex flex-col\">\n      <h3 class=\"text-xl font-semibold text-gray-900 dark:text-white\">Faça o upgrade para o plano copymagico PRO</h3>\n    </div>\n\n    <button type=\"button\" click.trigger=\"$dialog.cancel()\"\n      class=\"text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 mx-5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white\"\n      data-modal-hide=\"defaultModal\" style=\"justify-content: center; align-items: center;\">\n      <svg class=\"w-4 h-4\" aria-hidden=\"true\" xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 14 14\">\n        <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"\n          d=\"m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6\" />\n      </svg>\n      <span class=\"sr-only\">Close modal</span>\n    </button>\n  </div>\n  <!-- end::header -->\n\n  <!-- begin::body -->\n  <div class=\"flex flex-col gap-4 p-4\">\n    <span class=\"text-sm font-normal text-gray-700 dark:text-gray-400\">\n      Apenas assinantes podem utilizar esta funcionalidade</span>\n  </div>\n  <!-- end::body -->\n\n\n  <div class=\"flex items-center p-4 justify-center space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600\">\n    <button type=\"button\" click.trigger=\"navigateToSubscriptionAndPlans()\" class=\"btn-secondary\">Faça o upgrade\n      aqui</button>\n  </div>\n\n\n</div>";
export default template;
export const dependencies = [ d0, d1, d2 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
