import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/create-modal/create-campaign-modal/create-campaign-modal-component";
export const name = "campaign-component";
export const template = "\n\n<section>\n  <!-- <button id=\"button-capaign\" type=\"button\" click.trigger=\"createCampaignModalRef.showModal()\" class=\"btn-primary\">Criar campanha</button> -->\n\n  <create-campaign-modal-component view-model.ref=\"createCampaignModalRef\"></create-campaign-modal-component>\n</section>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
