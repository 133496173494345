import { inject } from "aurelia";
import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IUpsertBrand } from '@interfaces/graphql-service/commands';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IBrandGuidService {
  getBrands(): Promise<ModelTypes["brands"][]>;
  upsertBrand(command: IUpsertBrand): Promise<ModelTypes["upsertBrandOutput"]>;
}

@inject(IZeusClient)
export class BrandGuidService implements IBrandGuidService {

  constructor(private zeusClient: ZeusClient) { }

  async getBrands(): Promise<ModelTypes["brands"][]> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {
          limit: 1,
        },
        {
          id: true,
          brandName: true,
          slogan: true,
          voiceTone: true,
          segment: true,
          productOrService: true,
          fontPrimary: true,
          fontSecondary: true,
          isBrand: true,
          personality: true,
          logoFileId: true,
          backgroundColor: true,
          foregroundColor: true,
          accentColor: true,
        }
      ]
    });

    return result.brands as ModelTypes["brands"][];
  }

  async upsertBrand(command: IUpsertBrand): Promise<ModelTypes["upsertBrandOutput"]> {
    const result = await this.zeusClient.chain('mutation')({
      upsertBrand: [
        { ...command },
        {
          id: true,
          brandName: true,
          isBrand: true,
          slogan: true,
          segment: true,
          productOrService: true,
          fontPrimary: true,
          fontSecondary: true,
          voiceTone: true,
          personality: true,
          logoFileId: true,
          backgroundColor: true,
          foregroundColor: true,
          accentColor: true,
        }
      ]
    });

    return result.upsertBrand as ModelTypes["upsertBrandOutput"];
  }
}

export const IBrandGuidService = DI.createInterface<IBrandGuidService>((x) => x.singleton(BrandGuidService));
