import { inject } from "aurelia";
import { IRouter } from '@aurelia/router';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient, IRouter)
export class LinkedInSuccessPage {

  constructor(
    private zeusClient: ZeusClient,
    private router: IRouter
  ) { }

  async loading(parameters: { code: string }) {
    if (parameters) {
      const { code } = parameters;

      await this.handleLinkedInTokenCallback(code);
    }
  }

  private async handleLinkedInTokenCallback(code: string) {
    await this.zeusClient.chain('mutation')({
      linkedInTokenCallback: [
        {
          code
        },
        {
          success: true,
        }
      ]
    });
  }
}