import { CustomElement } from '@aurelia/runtime-html';
export const name = "slider-component";
export const template = "<input id=\"small-range\" type=\"range\" value.bind=\"value\" min.bind=\"min\" max.bind=\"max\" step.bind=\"step\" class=\"block h-1 w-full rounded-full bg-primary-500 transition-colors duration-500 focus:outline-none dark:bg-primary-400\">";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
