import { CustomElement } from '@aurelia/runtime-html';
export const name = "youtube-video-player-dialog";
export const template = "<div class=\"relative p-4 w-fit max-w-3xl max-h-full\">\n\n  <!-- begin::content -->\n  <div class=\"relative bg-white rounded-lg shadow dark:bg-gray-700\">\n\n    <!-- begin::header -->\n    <div class=\"flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600\">\n\n      <h3 class=\"text-xl font-semibold text-gray-900 dark:text-white\">Video player</h3>\n\n      <button type=\"button\" click.trigger=\"close()\" class=\"text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white\">\n        <svg aria-hidden=\"true\" class=\"w-5 h-5\" fill=\"currentColor\" viewBox=\"0 0 20 20\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path fill-rule=\"evenodd\" d=\"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z\" clip-rule=\"evenodd\"></path>\n        </svg>\n        <span class=\"sr-only\">Close modal</span>\n      </button>\n    </div>\n    <!-- end::header -->\n\n    <!-- begin::body -->\n    <div class=\"flex flex-col items-center justify-center p-4 gap-4\">\n      <div id='video-player'></div>\n    </div>\n    <!-- end::body -->\n\n  </div>\n  <!-- end::content -->\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
