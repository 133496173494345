import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/calendar/calendar-component-widget";
export const name = "calendar-component";
export const template = "\n\n<div class=\"card p-4\">\n  <div class=\"relative\">\n    <h5 t=\"dashboardcards.calendar\" class=\"text-lg font-bold text-gray-900 dark:text-white\">Calendário de Marketing</h5>\n    <h6 t=\"dashboardcards.calendarsubtitle\" class=\"text-sm text-gray-400 dark:text-gray-400 mb-4\"> Gerencie o\n      agendamento e publicação de postagens no menu <span class=\"font-bold\">Calendário</span></h6>\n\n    <button\n      if.bind=\"zeusClient.userDetails.onboardCompleted && zeusClient.userDetails.generatingNextMonthCalendarFromOnboard === null\"\n      class=\"btn-secondary-small absolute -translate-y-1/2 -translate-x-1/2 top-2/4 left-1/2 z-30 motion-safe:animate-pulse w-[250px]\">Gerar</button>\n\n    <div\n      class=\"${zeusClient.userDetails.onboardCompleted && zeusClient.userDetails.generatingNextMonthCalendarFromOnboard === null ? 'blur-sm pointer-events-none' : ''} pt-2\">\n      <calendar-component-widget hide-events=\"true\"></calendar-component-widget>\n    </div>\n\n  </div>\n</div>";
export default template;
export const dependencies = [ d0 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
