import { ModelTypes } from '@zeus';
import { inject, watch } from "aurelia";
import { IRouteableComponent, routes } from "@aurelia/router";

import { BlogModule } from './modules/blog/blog-module';
import { HomeModule } from './modules/home/home-module';
import { AboutModule } from './modules/about/about-module';
import { IWebsiteService } from './services/website-service';
import { OthersModule } from './modules/others/others-module';
import { DomainModule } from "./modules/domain/domain-module";
import { ContactModule } from './modules/contact/contact-module';
import { ProductModule } from './modules/product/product-module';
import { IConfirmAction } from '@components/confirm-action';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { toastifyError, toastifySuccess } from '@helpers/toastify';

@routes([
  {
    path: ['/home', ''],
    title: 'Página Inicial',
    component: HomeModule,
    data: {
      private: true,
    }
  },
  {
    path: '/about',
    title: 'Sobre',
    component: AboutModule,
    data: {
      private: true,
    }
  },
  {
    path: '/product',
    title: 'Produtos',
    component: ProductModule,
    data: {
      private: true,
    }
  },
  {
    path: '/contact',
    title: 'Contato',
    component: ContactModule,
    data: {
      private: true,
    }
  },
  {
    path: '/blog',
    title: 'Blog',
    component: BlogModule,
    data: {
      private: true,
    }
  },
  {
    path: '/settings',
    title: 'Settings',
    component: OthersModule,
    data: {
      private: true,
    }
  },
  {
    path: '/domain',
    title: 'Domain',
    component: DomainModule,
    data: {
      private: true,
    }
  }
])

@inject(IZeusClient, IWebsiteService, IConfirmAction)
export class WebsiteConfig implements IRouteableComponent {
  private website: ModelTypes['website']
  private isLoading: boolean = true;
  constructor(
    private zeusClient: ZeusClient,
    private websiteService: IWebsiteService,
    private confirmAction: IConfirmAction,
  ) { }

  loading() {
    this.isLoading = true;
    this.websiteService.getWebsiteSubscription();

    this.website = this.websiteService.websiteSubscription
    this.isLoading = false;
  }

  @watch('websiteService.websiteSubscription')
  async handleDealSubscription(newData: ModelTypes['website'], oldData: ModelTypes['website']) {
    if (newData && newData !== oldData) {
      this.website = newData;
    }
  }

  private async handleCreateWebsite() {
    await this.confirmAction.open({
      type: "success",
      title: "Criar site",
      message: "Deseja criar o seu site? Basta clicar em sim e aguardar alguns segundos",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        try {
          const createWebsiteMutation = await this.zeusClient.chain('mutation')({
            createWebsite: {
              success: true,
              errorMessage: true,
            }
          });

          if (!createWebsiteMutation.createWebsite.success) {
            toastifyError({ message: createWebsiteMutation.createWebsite.errorMessage });
          }

          toastifySuccess({ message: 'Site criado com sucesso' });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          toastifyError({ message: 'Erro ao criar site' });
        }
      }
    });
  }

  private async handleActivateWebsite() {
    await this.confirmAction.open({
      type: "success",
      title: "Ativar site",
      message: "Deseja ativar o seu site? Basta clicar em sim e aguardar alguns segundos",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        try {


          const mutations = await this.zeusClient.chain('mutation')({
            update_website_by_pk: [
              {
                pk_columns: { id: this.website.id },
                _set: {
                  active: true
                }
              },
              {
                id: true
              }
            ]
          });

          if (!mutations.update_website_by_pk?.id) {
            toastifyError({ message: 'Erro ao ativar site' });
          }

          toastifySuccess({ message: 'Site ativado com sucesso' });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          toastifyError({ message: 'Erro ao ativar site' });
        }
      }
    });
  }

  private async handleDisableWebsite() {
    await this.confirmAction.open({
      type: "danger",
      title: "Desativar site",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        try {
          const mutations = await this.zeusClient.chain('mutation')({
            update_website_by_pk: [
              {
                pk_columns: { id: this.website.id },
                _set: {
                  active: false
                }
              },
              {
                id: true
              }
            ]
          });

          if (!mutations.update_website_by_pk?.id) throw new Error('Erro ao ativar site');

          toastifySuccess({ message: 'Site desativado com sucesso' });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          toastifyError({ message: 'Erro ao desativar site' });
        }
      }
    });
  }
}
