import { inject } from 'aurelia';
import axios from 'axios';
import { IDialogService } from '@aurelia/dialog';
import { YoutubeVideoPlayerDialog } from '@components/youtube-video-player/youtube-video-player-dialog';

@inject(IDialogService)
export class TutorialsPage {

  constructor(private dialogService: IDialogService) { }

  private async getYoutubePlaylists() {
    const url = 'https://www.googleapis.com/youtube/v3/playlistItems'

    const params = {
      part: 'snippet',
      playlistId: 'PLwrErXjK5LBYW-m25jqdKI5qOKtMUsjCs',
      key: 'AIzaSyD_XYESgmHlD2ocQRvJ-HOKi_P8KMZBias',
      maxResults: 25,
    }

    try {
      const result = await axios.get(url, { params })
      return result.data
    } catch (error) {
      console.error(error)
    }
  }

  private async handleVideoPlay(videoId: string) {
    await this.dialogService.open({
      component: () => YoutubeVideoPlayerDialog,
      model: { videoId },
      lock: true,
      startingZIndex: 100,
    });
  }
}