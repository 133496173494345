import { bindable } from 'aurelia';
import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';

type ImageCarouselType = {
  linkImage: string;
};

export class ImageCarouselComponent {
  @bindable product: ModelTypes["products"];

  private swiper: Swiper;
  private images: string[] = [];
  private files: ImageCarouselType[] = [];

  constructor() { }

  @watch('product')
  async handleDealSubscription(newData: ModelTypes["products"]) {
    this.product = newData;

    if (this.product) {
      this.getThumbnailURL();
    }
  }

  initSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
    }

    this.swiper = new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 1,
      freeMode: false,
      navigation: {
        prevEl: '.swiper-button-prev-custom',
        nextEl: '.swiper-button-next-custom',
      },
    });
  }

  private getThumbnailURL(): void {
    this.images = []
    this.files = []

    this.images = [this.product.imageOneId, this.product.imageTwoId, this.product.imageThreeId, this.product.imageFourId]

    for (const image of this.images) {

      if (!image) {
        this.files.push({ linkImage: 'assets/images/default_image.jpg' });
        continue;
      }

      const storageUrl = import.meta.env.VITE_STORAGE_URL;

      this.files.push({ linkImage: `${storageUrl}/${image}` });
    }

    this.initSwiper();
  }
}