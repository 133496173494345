import { inject } from "aurelia";
import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { actionsFromPublish } from '@data/index';
import moment from 'moment';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { toastifyError, toastifySuccess, toastifyWarning } from '@helpers/toastify';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
@inject(IZeusClient, IDialogDom)
export class AddCardDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;
  stripe: Stripe
  stripeElements: StripeElements
  paymentElement: HTMLElement
  constructor(
    private zeusClient: ZeusClient,
    private dialogDom: DefaultDialogDom,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async attached() {
    console.log(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
    this.stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
      locale: 'pt-BR',
    });
    const paymentSetupIntentQuery = await this.zeusClient.chain('query')({
      getPaymentSetupIntent: {
        client_secret: true
      }
    });
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    this.stripeElements = this.stripe.elements({
      clientSecret: paymentSetupIntentQuery.getPaymentSetupIntent.client_secret,
      loader: 'auto',
      appearance: {
        theme: colorTheme === 'dark' ? 'night' : 'stripe',
      }
    });

    const paymentElementCreate = this.stripeElements.create("payment", {
      layout: 'tabs',
    })
    paymentElementCreate.mount(this.paymentElement);

  }

  async close() {
    this.$dialog.ok();
  }
  async handleAddCard() {
    try {

      const result = await this.stripe.confirmSetup({
        elements: this.stripeElements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://app.copymagico.com',
        },
      });
      console.log(result)
      if (result.error) {
        toastifyError({ message: result.error.message });
      } else {
        toastifySuccess({ message: 'Cartão adicionado com sucesso' });
        this.$dialog.ok();
      }

    } catch (error) {
      console.log(error)
      throw new Error('Error adding card')
    }
  }

}