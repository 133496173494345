import { bindable } from 'aurelia';

export class IconsComponent {
  @bindable icon: string

  async getIcon(iconName: string) {
    const icon = `assets/icons/${iconName}.svg`

    const result = await fetch(icon)

    return result.text()
  }

}