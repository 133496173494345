import { CustomElement } from '@aurelia/runtime-html';
import "./template-editor-page.css";
import * as d1 from "@components/card-status/card-status-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
export const name = "template-editor-page";
export const template = "\n\n\n<section class=\"polotno-section\">\n\n  <card-status-component>\n    <div au-slot=\"status\"></div>\n    <div au-slot=\"actions\" class=\"flex gap-2\">\n      <button click.trigger=\"handleOpenEdtiTemplateDialog()\" class=\"btn-danger-small !px-10\">Salvar template</button>\n      <spinner-button-component label=\"Salvar\" btn-class=\"btn-primary\"\n        trigger-function.bind=\"() => handleSave()\"></spinner-button-component>\n    </div>\n  </card-status-component>\n\n  <div id=\"polotno\" class=\"bp5-dark p-0\">\n    <div id=\"container\" class=\"w-full h-[77vh]\"></div>\n  </div>\n\n</section>";
export default template;
export const dependencies = [ d1, d2 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
