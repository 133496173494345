import { inject } from "aurelia";
import { ModelTypes } from '@zeus';
import { IRouter } from '@aurelia/router';
import { IEventAggregator, bindable } from 'aurelia'
import { IAuthService } from "@interfaces/auth-service/auth-service-interface";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { Dropdown, type DropdownOptions, type DropdownInterface } from 'flowbite';
import { IConfirmAction } from '@components/confirm-action';

@inject(IRouter, IZeusClient, IEventAggregator, IAuthService, IConfirmAction)
export class TableActionsComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  @bindable project: ModelTypes["project"];
  @bindable deleteCallback: () => Promise<void>;

  constructor(
    private router: IRouter,
    readonly zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private authService: IAuthService,
    private confirmAction: IConfirmAction,
  ) { }

  attached() {
    this.$targetEl = document.getElementById(`dropdown-${this.project.id}`);

    this.$triggerEl = document.getElementById(`dropdown-${this.project.id}-button`);

    const options: DropdownOptions = {
      placement: 'left',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);
  }

  private showDropdown(): void {
    this.dropdown.show();
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  private async handleRedirectProject(): Promise<void> {
    switch (this.project.type) {
      case 'image':
        await this.router.load(`/image/${this.project.id}`);
        break;
      case 'text':
        await this.router.load(`/text/${this.project.id}`);
        break;
      case 'post':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'blog':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'print':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'presentation':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'advert':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'course':
        alert('courses')
        break;

      default:
        break;
    }
  }

  async handleDeleteProject(): Promise<void> {
    this.dropdown.hide();

    await this.confirmAction.open({
      type: "danger",
      title: "Deletar projeto",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        await this.deleteCallback()
      }
    });
  }

  detaching() {
    if (!this.$targetEl || !this.$triggerEl) {
      return;
    }

    this.$targetEl.remove();
    this.$triggerEl.remove();

    this.$targetEl = null;
    this.$triggerEl = null;
    this.dropdown = null;
  }
}