import { inject } from "aurelia";
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IAurelia, IEventAggregator, bindable } from 'aurelia';
import { Dropdown } from 'flowbite';
import { watch } from "@aurelia/runtime-html";
import type { DropdownOptions, DropdownInterface } from "flowbite";
import { User } from '@nhost/nhost-js';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { CreateProjectDialog } from '@components/create-project-dialog/create-project-dialog';
import { IRouter } from '@aurelia/router';
import { IDialogService } from '@aurelia/dialog';
import { ModelTypes } from "@zeus";
import { DrawerComponent } from '@components/drawer/drawer-component';
import { GoPremiumDialog } from "@components/calendar/components/go-premium-dialog/go-premium-dialog";

@inject(IAurelia, IRouter, IAuthService, IZeusClient, IEventAggregator, IDialogService, IAuthService)
export class TopnavComponent {
  @bindable open: boolean;
  @bindable isDark: boolean;

  private domain: string;
  private dropdown: DropdownInterface

  private user: User;
  trialExpirationText: string;

  constructor(
    private au: IAurelia,
    private router: IRouter,
    private auth: IAuthService,
    private zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private dialogService: IDialogService,
    private authService: IAuthService
  ) { this.verifyIsOpenDrawer() }

  async attached() {

    this.user = this.auth.user;
    await this.zeusClient.getUserSubscription();


    const websiteSubscription = this.zeusClient.subscription('subscription')({
      website: [
        {
          where: {
            brand: {
              created_by: {
                _eq: this.user.id
              }
            }
          }
        }, {
          domain: true
        }
      ]
    });

    websiteSubscription.on(({ website }: { website: ModelTypes["website"][] | undefined }) => {

      if (website.length > 0) {
        this.domain = 'https://' + website?.at(0)?.domain;
      }
    }
    );


    const $targetEl: HTMLElement = document.getElementById('dropdownUserMenu');

    const $triggerEl: HTMLElement = document.getElementById('dropdownUserMenuButton');

    const options: DropdownOptions = {
      placement: 'bottom-start',
      triggerType: 'click',
      offsetSkidding: 10,
      offsetDistance: 20,
      delay: 300,
    };

    this.dropdown = new Dropdown($targetEl, $triggerEl, options);

    // theme
    this.verifyColorTheme();

    // drawer
    this.verifyIsOpenDrawer();
  }

  @watch('zeusClient.userSubscription')
  handleCurrentUser(newUser: User) {
    this.user = newUser;

  }
  @watch('zeusClient.userDetails')
  handleUserDetails(newUserMetadata: ModelTypes['userMetadata']) {
    const trialEndsAt = newUserMetadata?.trialEndDate;
    if (this.auth.user.defaultRole == 'user-freetrial') {
      if (trialEndsAt) {
        const trialEndsAtDate = new Date(trialEndsAt);
        const currentDate = new Date();
        if (trialEndsAtDate > currentDate) {
          const daysLeft = Math.floor((trialEndsAtDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
          if (daysLeft === 0) {
            this.trialExpirationText = 'Avaliação gratuita expira hoje';
          }
          else if (daysLeft <= 7) {
            this.trialExpirationText = `Avaliação gratuita expira em ${daysLeft} dias`;
          }
          else if (daysLeft < 0) {
            this.trialExpirationText = '';
          }
        }
      }
    }

  }

  private verifyColorTheme = () => {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    if (colorTheme) {
      if (colorTheme === 'light') {
        this.isDark = false;
        document.body.classList.remove('dark');
      } else {
        this.isDark = true;
        document.body.classList.add('dark');
      }
    } else {
      this.isDark = true;
      document.body.classList.add('dark');
    }
  }

  private verifyIsOpenDrawer = () => {
    const isOpenDrawer = localStorage.getItem('rankmyseite-drawer-open');

    if (isOpenDrawer) {
      if (isOpenDrawer === 'true') {
        this.open = true;
      } else {
        this.open = false;
      }
    }
  }

  private async openSidebar() {
    this.open = !this.open;

    const isOpenDrawer = localStorage.getItem('rankmyseite-drawer-open');

    if (isOpenDrawer) {
      if (isOpenDrawer === 'true') {
        localStorage.setItem('rankmyseite-drawer-open', 'false');
      } else {
        localStorage.setItem('rankmyseite-drawer-open', 'true');
      }
    } else {
      if (this.open) {
        localStorage.setItem('rankmyseite-drawer-open', 'true');
      } else {
        localStorage.setItem('rankmyseite-drawer-open', 'false');
      }
    }
  }

  private async openDrawer() {
    await this.dialogService.open({
      component: () => DrawerComponent,
      model: {},
      lock: false,
      keyboard: ['Escape'],
      startingZIndex: 100,
    });
  }

  private signOut = async () => {
    try {
      await this.auth.signOut();
      this.router.load('login');

    } catch (error) {
      console.log(error);
    }
  }

  private toggleDarkMode = () => {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    if (colorTheme) {

      if (colorTheme === 'light') {
        this.isDark = true;
        document.body.classList.add('dark');
        localStorage.setItem('rankmyseite-color-theme', 'dark');
      } else {
        this.isDark = false;
        document.body.classList.remove('dark');
        localStorage.setItem('rankmyseite-color-theme', 'light');
      }

      // if NOT set via local storage previously
    } else {
      if (document.body.classList.contains('dark')) {
        this.isDark = false;
        document.body.classList.remove('dark');
        localStorage.setItem('rankmyseite-color-theme', 'light');
      } else {
        this.isDark = true;
        document.body.classList.add('dark');
        localStorage.setItem('rankmyseite-color-theme', 'dark');
      }
    }

    this.ea.publish('color-theme', { theme: this.isDark ? 'dark' : 'light' });
  }

  private closeDropdownUserMenu = () => {
    this.dropdown.hide();
  }

  private async openCreateProjectDialog() {
    if (!['user-premium', 'user-freetrial'].includes(this.authService.user?.defaultRole)) {
      await this.dialogService.open({
        component: () => GoPremiumDialog,
        model: {},
        lock: true,
        startingZIndex: 100,
      });

      return
    }
    await this.dialogService.open({
      component: () => CreateProjectDialog,
      model: {},
      lock: false,
      startingZIndex: 100,
    });
  }

}