import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/spinner-button/spinner-button-component";
import * as d1 from "@components/upload/list-file-upload/list-file-upload-component";
import * as d2 from "./../../components/header/header-component.ts";
export const name = "attachments-section";
export const template = "\n\n\n\n\n<section class=\"p-4\">\n  <header-component title=\"Anexos\" subtitle=\"Material de suporte\"></header-component>\n\n  <div class=\"mt-6\">\n    <list-file-upload-component files.bind=\"files\"\n      delete-file-callback.bind=\"(id) => handleDeleteFile(id)\"></list-file-upload-component>\n  </div>\n\n  <div class=\"flex flex-row justify-end mt-4\">\n    <spinner-button-component label=\"Salvar\" btn-class=\"btn-primary min-w-20\"\n      trigger-function.bind=\"() => handleSave()\"></spinner-button-component>\n  </div>\n</section>";
export default template;
export const dependencies = [ d0, d1, d2 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
