import { CustomElement } from '@aurelia/runtime-html';
import "./polotno-module.css";
import * as d1 from "@components/card-status/card-status-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
import * as d3 from "./../../components/publish/publish-social-media/publish-social-media-component.ts";
export const name = "polotno-module";
export const template = "\n\n\n\n\n<section class=\"polotno-section\">\n\n  <card-status-component>\n    <div au-slot=\"status\"></div>\n    <div au-slot=\"actions\" class=\"flex gap-2\">\n      <button class=\"btn-neutral\" click.trigger=\"openEditCaption()\">Legenda</button>\n\n      <!-- <div class=\"relative grid place-content-center btn-danger-small !px-10\">\n        <input type=\"file\" accept=\"application/json\" class=\"opacity-0 absolute inset-0 w-full h-full cursor-pointer\" change.trigger=\"handleSelectedTemplate($event)\"></input>\n\n        <div class='flex items-center justify-center'>\n          <span>Carregar template</span>\n        </div>\n      </div> -->\n\n      <button if.bind=\"auth.user.id === '3a371f49-ec2c-4885-acf2-a33492c07023' \"\n        click.trigger=\"openSaveTemplateDialog()\" class=\"btn-danger-small !px-10\">Salvar template</button>\n\n      <spinner-button-component label=\"Salvar\" btn-class=\"btn-primary\"\n        trigger-function.bind=\"() => handleSave()\"></spinner-button-component>\n      <button if.bind=\"id\" click.trigger=\"saveAndPublishSocialMediaComponentRef(id)\"\n        class=\"btn-success-small !px-10\">Publicar</button>\n    </div>\n  </card-status-component>\n\n  <div id=\"polotno\" class=\"bp5-dark p-0\">\n    <div id=\"container\" class=\"w-full h-[77vh]\"></div>\n  </div>\n\n  <publish-social-media-component view-model.ref=\"publishSocialMediaComponentRef\"></publish-social-media-component>\n</section>";
export default template;
export const dependencies = [ d1, d2, d3 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
