import { inject } from "aurelia";
import { DI, IEventAggregator } from 'aurelia';
import { createClient, type Sink } from 'graphql-ws';
import { IAuthService } from '../../interfaces/auth-service/auth-service-interface';
import { IInsertOrUpdateAudience, IPostToFacebook, IPostToInstagram, IPostToLinkedIn, IPublishOrScheduleProject, ISaveUserOnboardingStepOne, ISaveUserOnboardingStepThree, ISaveUserOnboardingStepTwo, IUpsertBrand } from '@interfaces/graphql-service/commands';
import { GeneratedPromptByTemplate_select_column, ModelTypes, SubscriptionThunder, Thunder, ValueTypes, audiences_select_column, brands_constraint, brands_select_column, brands_update_column, chainOptions, order_by, products_constraint, products_select_column, products_update_column, project_select_column, userAppIntegration_select_column, userOnboarding_constraint, userOnboarding_update_column } from '../../../utils/generated/zeus';
import { IStepOne, IStepThree, IStepTwo } from "@components/onboarding/interfaces/steps-interface";
import slugify from "slugify";

@inject(IAuthService, IEventAggregator)
export class ZeusClient {

	public userMetadataSubscription;
	public userSubscription: ModelTypes["users"];
	public userDetails: ModelTypes["userMetadata"] | undefined;
	public generatedPromptByTemplateSubscription: ModelTypes["GeneratedPromptByTemplate"];
	public consumptionByChannelViewSubscription: Array<ModelTypes["consumptionByChannelView"]>;

	constructor(
		private Auth: IAuthService,
		readonly ea: IEventAggregator
	) { }

	chain = Thunder(async (query, variables: Record<string, unknown> = {}) => {
		const bearer = this.Auth.getAccessToken();

		const response = await fetch(import.meta.env.VITE_GRAPHQL_URL, {
			body: JSON.stringify({ query, variables }),
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${bearer}`
			},
		},
		);

		if (!response.ok) {
			return new Promise((resolve, reject) => {
				response
					.text()
					.then((text) => {
						try {
							reject(JSON.parse(text));
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
						} catch (error) {
							reject(text);
						}
					})
					.catch(reject);
			});
		}


		const json = await response.json();

		return json.data;
	});

	action = Thunder(async (query) => {
		const bearer = this.Auth.getAccessToken();

		const response = await fetch(import.meta.env.VITE_GRAPHQL_URL, {
			body: JSON.stringify({ query }),
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${bearer}`
			},
		},
		);

		if (!response.ok) {
			return new Promise((resolve, reject) => {
				response
					.text()
					.then((text) => {
						try {
							reject(JSON.parse(text));
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
						} catch (err) {
							reject(text);
						}
					})
					.catch(reject);
			});
		}

		const json = await response.json();

		return {
			data: json.data,
			error: json.errors
		};
	});

	apiSubscription = (options: chainOptions) => {

		const client = createClient({
			url: String(options[0]),
			connectionParams: () => {
				const token = this.Auth.getAccessToken();
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			}
		});

		const ws = new Proxy(
			{
				close: () => client.dispose(),
			} as WebSocket,
			{
				get(target, key) {
					if (key === 'close') return target.close;
					throw new Error(`Unimplemented property '${String(key)}', only 'close()' is available.`);
				},
			},
		);

		return (query: string) => {
			let onMessage: ((event) => void) | undefined;
			let onError: Sink['error'] | undefined;
			let onClose: Sink['complete'] | undefined;

			client.subscribe(
				{ query },
				{
					next({ data }) {
						if (onMessage) onMessage(data);
					},
					error(error) {
						if (onError) onError(error);
					},
					complete() {
						if (onClose) onClose();
					},
				},
			);

			return {
				ws,
				on(listener: typeof onMessage) {
					onMessage = listener;
				},
				error(listener: typeof onError) {
					onError = listener;
				},
				open(listener: (socket: unknown) => void) {
					client.on('opened', listener);
				},
				off(listener: typeof onClose) {
					onClose = listener;
				},
			};
		};
	};

	SubscriptionEx = (...options: chainOptions) => SubscriptionThunder(this.apiSubscription(options));

	subscription = this.SubscriptionEx(import.meta.env.VITE_GRAPHQL_WS_URL, {
		headers: {
			'Authorization': `Bearer ${this.Auth.getAccessToken()}`
		},
	});

	async getUserSubscription(): Promise<void> {
		const user = this.Auth.getUser();

		if (!user) return;

		const result = this.subscription('subscription')({
			user: [
				{ id: user.id },
				{
					id: true,
					avatarUrl: true,
					displayName: true,
					email: true,

				}
			]
		});

		result.on(({ user }: { user: ModelTypes["users"] | undefined }) => {
			this.userSubscription = user;
		});
	}

	async getCoursesByCategory(categoryId: string): Promise<Array<ModelTypes["Course"]>> {
		const result = await this.chain('query')({
			Course: [
				{ where: { courseCategoryId: { _eq: +categoryId } } },
				{
					id: true,
					name: true,
					description: true,
					imageUrl: true
				}]
		});

		return result.Course as Array<ModelTypes["Course"]>;
	}

	async getTextParameters(): Promise<Array<ModelTypes["TextGeneratorOption"]>> {
		const result = await this.chain('query')({
			TextGeneratorOption: [
				{},
				{
					id: true,
					Label: [{ path: '$' }, true],
					popover_tooltip: [{ path: '$' }, true],
					TextGeneratorOptionEntries: [
						{},
						{
							id: true,
							Label: [{ path: '$' }, true],
						}
					]
				}
			],
		});

		return result.TextGeneratorOption as Array<ModelTypes["TextGeneratorOption"]>;
	}

	async getTextTemplates(offset: number, limit: number, channelId: string): Promise<Array<ModelTypes["TextPromptTemplate"]>> {
		const result = await this.chain('query')(
			{
				TextPromptTemplate: [
					{
						limit: limit,
						offset: offset,
						...(channelId && channelId != '') && { where: { channel: { _eq: channelId } } },
						order_by: [{ flexible: order_by.desc }, { name: order_by.asc }],
					},
					{
						id: true,
						name: true,
						description: true,
						definitions: [{ path: '$' }, true],
						icon: true,
						categoryId: true,
						saleCreditPrice: true,
						creditPrice: true,
						channel: true,
						TextTemplateClientCategory: {
							displayName: true,
							iconClass: true,
						},
						displayDimensionId: true,
						flexible: true,
					}
				]
			}
		)

		return result.TextPromptTemplate as Array<ModelTypes["TextPromptTemplate"]>;
	}

	async getTextTemplateById(id: string): Promise<ModelTypes["TextPromptTemplate"] | undefined> {
		const result = await this.chain('query')({
			TextPromptTemplate_by_pk: [
				{ id: id },
				{
					name: true,
					description: true,
					id: true,
					definitions: [{ path: '$' }, true]
				}
			]
		});

		return result.TextPromptTemplate_by_pk as ModelTypes["TextPromptTemplate"];
	}

	async getTextTemplateCategoriesView(): Promise<Array<ModelTypes["categorysummaryview"]>> {
		const result = await this.chain('query')({
			categorysummaryview: [
				{},
				{
					categoryName: true,
					id: true,
					count: true,
				}
			]
		});

		return result.categorysummaryview;
	}

	async searchImagesFromPexels({ query, locale, orientationType, page }: { query: string, locale: string, orientationType: string, page: number }): Promise<ModelTypes["SearchImagesFromPexelsResponse"]> {

		if (!query) return;

		const result = await this.chain('query')({
			searchImagesFromPexels: [
				{
					query: query,
					locale,
					orientation: orientationType,
					page: page
				},
				{
					images: {
						id: true,
						url: true,
						small: true,
						photographer: true,
						photographer_url: true,
						height: true,
						width: true,
						alt: true,
					}
				}
			]
		});

		return result?.searchImagesFromPexels as ModelTypes["SearchImagesFromPexelsResponse"];
	}

	async generateImageFromPrompt(query: string): Promise<ModelTypes["GenerateImageFromPromptOutput"]> {
		const result = await this.chain('query')({
			generateImageFromPrompt: [
				{
					command: {
						query: query,
						provider: 'midjourney',
					},
				},
				{
					images: {
						id: true,
						url: true,
					}
				}
			]
		});

		return result.generateImageFromPrompt as ModelTypes["GenerateImageFromPromptOutput"];
	}

	async userDetailsSubscription(): Promise<void> {
		const user = this.Auth.getUser();

		if (!user) return;

		this.userMetadataSubscription = this.subscription('subscription')({
			userMetadata_by_pk: [
				{ id: user.id },
				{
					id: true,
					stripeSubscriptionItem: true,
					onboardCompleted: true,
					creditBalance: true,
					stripePriceId: true,
					pricingPlan: {
						creditUnitDiscount: true,
					},
					trialEndDate: true,
					// generatingNextMonthCalendarFromOnboard: true,
				}
			]
		});

		this.userMetadataSubscription.error((error) => { console.log('userMetadataSubscription error', error) });

		this.userMetadataSubscription.on(({ userMetadata_by_pk }: { userMetadata_by_pk: ModelTypes["userMetadata"] | undefined }) => {
			this.userDetails = userMetadata_by_pk;
		});
	}

	async getAppIntegrations(): Promise<Array<ModelTypes["AppIntegration"]>> {
		const result = await this.chain('query')({
			AppIntegration: [
				{},
				{
					id: true,
					icon: true,
					name: true,
					description: true,
					type: true,
					configParams: [{ path: '$' }, true],
				}
			]
		});

		return result.AppIntegration as Array<ModelTypes["AppIntegration"]>;
	}

	async getUserAppIntegrations(): Promise<Array<ModelTypes["userAppIntegration"]>> {
		const result = await this.chain('query')({
			userAppIntegration: [
				{},
				{
					id: true,
					integrationType: true,
					alias: true,
					params: [{ path: '$' }, true]
				}
			]
		});

		return result.userAppIntegration as Array<ModelTypes["userAppIntegration"]>;
	}

	async deleteUserAppIntegrationById(id: string): Promise<ModelTypes["userAppIntegration"] | undefined> {
		const result = await this.chain('mutation')({
			update_userAppIntegration_by_pk: [
				{
					pk_columns: { id: id },
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_userAppIntegration_by_pk as ModelTypes["userAppIntegration"];
	}

	async getUserAppIntegrationAggregate(): Promise<number> {
		const result = await this.chain('query')({
			userAppIntegration_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [userAppIntegration_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.userAppIntegration_aggregate.aggregate.count as number;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async insertUserAppIntegration(type: string, brandId: string, params: any): Promise<ModelTypes["userAppIntegration"] | undefined> {
		const result = await this.chain('mutation')({
			insert_userAppIntegration_one: [
				{
					object: {
						integrationType: type,
						brandId: brandId,
						params: JSON.stringify(params),
					},
				},
				{
					id: true,
				}
			],
		});

		return result.insert_userAppIntegration_one as ModelTypes["userAppIntegration"];
	}

	async getEvents(startDate: string, endDate: string): Promise<Array<ModelTypes["GeneratedPromptByTemplate"]>> {
		const result = await this.chain('query')({
			GeneratedPromptByTemplate: [
				{
					where: {
						_and:
							[
								{
									created_at: {
										_gte: startDate
									}
								},
								{
									created_at: {
										_lte: endDate
									}
								},
								{
									created_by: {
										_eq: this.Auth.getUser().id
									}
								}
							],
					},
				},
				{
					result: true,
					created_at: true,
					created_by: true,
					id: true,
					alias: true,
					scheduledDate: true,
					channel: true,
					status: true,
					TextPromptTemplate: {
						name: true,
					}
				}
			]
		});

		return result?.GeneratedPromptByTemplate as Array<ModelTypes["GeneratedPromptByTemplate"]>;
	}

	async getUserOnboarding(): Promise<{ stepOne: IStepOne, stepTwo: IStepTwo, stepThree: IStepThree, currentStep } | undefined> {
		const result = await this.chain('query')({
			userOnboarding_by_pk: [
				{ userId: this.Auth.getUser().id },
				{
					userId: true,
					name: true,
					phone: true,
					brandName: true,
					siteUrl: true,
					companySegment: true,
					productOrService: true,
					staffCount: true,
					marketingObjective: true,
					expectation: true,
					sellOnCopyMagico: true,
					networkingOnCopyMagico: true,
					importantSocialNetworkForBusiness: true,
					investInPaidChannels: true,
					done: true,
					currentStep: true,
				}
			],
			brands: [
				{ where: { created_by: { _eq: this.Auth.getUser().id } } },
				{
					id: true,
					brandName: true,
					slogan: true,
					segment: true,
					fontPrimary: true,
					fontSecondary: true,
					isBrand: true,
					personality: true,
					voiceTone: true,
					accentColor: true,
					backgroundColor: true,
					foregroundColor: true,
					logoFileId: true,
					products: [
						{
							limit: 1,
						},
						{
							id: true,
							alias: true,
							type: true,
							category: true,
							price: true,
							salePrice: true,
							imageOneId: true,
							imageTwoId: true,
							imageThreeId: true,
							imageFourId: true,
						}
					]
				}
			]
		})
		const stepOne: IStepOne = {
			name: result.userOnboarding_by_pk?.name,
			phone: result.userOnboarding_by_pk?.phone,
			brandName: result.userOnboarding_by_pk?.brandName,
			companySegment: result.userOnboarding_by_pk?.companySegment,
			...result.brands?.at(0)?.accentColor && { accentColor: result.brands?.at(0)?.accentColor },
			...result.brands?.at(0)?.backgroundColor && { backgroundColor: result.brands?.at(0)?.backgroundColor },
			...result.brands?.at(0)?.foregroundColor && { foregroundColor: result.brands?.at(0)?.foregroundColor },
			logoFileId: result.brands?.at(0)?.logoFileId?.toString() ?? null,
			personality: result.brands?.at(0)?.personality,
			voiceTone: result.brands?.at(0)?.voiceTone,
			logoFile: null,

		}
		const stepTwo: IStepTwo = {
			alias: result.brands?.at(0)?.products?.at(0)?.alias,
			type: result.brands?.at(0)?.products?.at(0)?.type,
			category: result.brands?.at(0)?.products?.at(0)?.category,
			price: result.brands?.at(0)?.products?.at(0)?.price?.toString() ?? null,
			salePrice: result.brands?.at(0)?.products?.at(0)?.salePrice?.toString() ?? null,
			imageOneFileId: result.brands?.at(0)?.products?.at(0)?.imageOneId?.toString() ?? null,
			imageTwoFileId: result.brands?.at(0)?.products?.at(0)?.imageTwoId?.toString() ?? null,
			imageThreeFileId: result.brands?.at(0)?.products?.at(0)?.imageThreeId?.toString() ?? null,
			imageFourFileId: result.brands?.at(0)?.products?.at(0)?.imageFourId?.toString() ?? null,
			stepTwoimageOneFile: null,
			stepTwoimageTwoFile: null,
			stepTwoimageThreeFile: null,
			stepTwoimageFourFile: null,

		}
		const stepThree: IStepThree = {
			hasWebsite: !!result.userOnboarding_by_pk?.siteUrl,
			site: result.userOnboarding_by_pk?.siteUrl,
			activateWebsite: false
		}
		return { stepOne, stepTwo, stepThree, currentStep: result.userOnboarding_by_pk?.currentStep };
	}

	async saveUserOnboardingStepOne({ ...command }: ISaveUserOnboardingStepOne): Promise<ModelTypes["userOnboarding"] | undefined> {
		const userBrand = await this.chain('query')({
			brands: [
				{ where: { created_by: { _eq: this.Auth.getUser().id } } },
				{
					id: true,
				}
			]
		});
		const result = await this.chain('mutation')({
			insert_userOnboarding_one: [
				{
					object: {
						userId: this.Auth.getUser().id,
						name: command.name,
						brandName: command.brandName,
						companySegment: command.companySegment,
						currentStep: 1,
						done: false,
						phone: command.phone,
					},
					on_conflict: {
						constraint: userOnboarding_constraint.userOnboarding_pkey,
						update_columns: [
							userOnboarding_update_column.userId,
							userOnboarding_update_column.name,
							userOnboarding_update_column.brandName,
							userOnboarding_update_column.companySegment,
							userOnboarding_update_column.currentStep,
							userOnboarding_update_column.done,
							userOnboarding_update_column.phone,
						]
					}
				},
				{
					currentStep: true,
				}
			],
			insert_brands_one: [
				{
					object: {
						...userBrand?.brands?.at(0)?.id && { id: userBrand?.brands?.at(0).id },
						brandName: command.brandName,
						slogan: '',
						segment: command.companySegment,
						fontPrimary: '',
						fontSecondary: '',
						isBrand: false,
						archtypeAdherence: [],
						personality: command.personality,
						voiceTone: command.voiceTone,
						backgroundColor: command?.backgroundColor || '#ff3e00',
						foregroundColor: command?.foregroundColor || '#ffffff',
						accentColor: command?.accentColor || '#4a90e2',
						logoFileId: command.logoFileId,

					},
					on_conflict: {
						constraint: brands_constraint.brands_pkey,
						update_columns: [
							brands_update_column.brandName,
							brands_update_column.slogan,
							brands_update_column.segment,
							brands_update_column.fontPrimary,
							brands_update_column.fontSecondary,
							brands_update_column.isBrand,
							brands_update_column.personality,
							brands_update_column.voiceTone,
							brands_update_column.archtypeAdherence,
							brands_update_column.accentColor,
							brands_update_column.backgroundColor,
							brands_update_column.foregroundColor,
							brands_update_column.logoFileId,
						]
					}
				},
				{
					id: true,
				}
			]
		})

		return result.insert_userOnboarding_one as ModelTypes["userOnboarding"] | undefined;
	}


	async getUserOnboardingRaw(): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('query')({
			userOnboarding_by_pk: [
				{ userId: this.Auth.getUser().id },
				{
					userId: true,
					name: true,
					phone: true,
					brandName: true,
					siteUrl: true,
					companySegment: true,
					productOrService: true,
					staffCount: true,
					marketingObjective: true,
					expectation: true,
					sellOnCopyMagico: true,
					networkingOnCopyMagico: true,
					importantSocialNetworkForBusiness: true,
					investInPaidChannels: true,
					done: true,
					currentStep: true,
				}
			]
		})

		return result.userOnboarding_by_pk;
	}


	async saveUserOnboardingStepTwo({ ...command }: ISaveUserOnboardingStepTwo): Promise<ModelTypes["userOnboarding"] | undefined> {
		const prouductQuery = await this.chain('query')({
			products: [
				{
					where: { created_by: { _eq: this.Auth.getUser().id } },
				},
				{
					id: true,
					slug: true,
				}
			],
			brands: [
				{ where: { created_by: { _eq: this.Auth.getUser().id } } },
				{
					id: true,
				}
			]
		});

		const productId = prouductQuery.products?.at(0)?.id;
		const slugCounter = 1;

		let targetSlug = prouductQuery.products?.at(0)?.slug;
		let slugChallange = slugify(command.alias, { strict: true, lower: true })

		while (!targetSlug) {
			const verifySlug = await this.verifyProductExistBySlug({ ...productId && { id: productId as string }, slug: slugChallange, brandId: prouductQuery.brands?.at(0)?.id as string });
			if (!verifySlug) {
				targetSlug = slugChallange;
			} else {
				slugChallange = slugify(command.alias + '-' + slugCounter, { strict: true, lower: true });
			}
		}

		const result = await this.chain('mutation')({
			update_userOnboarding_by_pk: [
				{
					pk_columns: {
						userId: this.Auth.getUser().id
					},
					_set: {

						currentStep: 2,
						done: false,
					}
				},
				{
					currentStep: true,
				}

			],
			insert_products_one: [
				{
					object: {
						...productId && { id: productId },
						alias: command.alias,
						type: command.type,
						category: command.category,
						price: command.price,
						salePrice: command.salePrice,
						imageOneId: command.imageOneFileId,
						imageTwoId: command.imageTwoFileId,
						imageThreeId: command.imageThreeFileId,
						imageFourId: command.imageFourFileId,
						brandId: prouductQuery.brands?.at(0)?.id,
						slug: targetSlug,
					},
					on_conflict: {
						constraint: products_constraint.projects_pkey,
						update_columns: [
							products_update_column.alias,
							products_update_column.type,
							products_update_column.category,
							products_update_column.price,
							products_update_column.salePrice,
							products_update_column.imageOneId,
							products_update_column.imageTwoId,
							products_update_column.imageThreeId,
							products_update_column.imageFourId,
							products_update_column.brandId,
							products_update_column.slug,
						]
					}
				},
				{
					id: true,
				}
			]
		})

		return result.update_userOnboarding_by_pk as ModelTypes["userOnboarding"] | undefined;
	}


	async verifyProductExistBySlug({ id, slug, brandId }: { id?: string, slug: string, brandId: string }): Promise<boolean> {
		const result = await this.chain('query')({
			products: [
				{
					limit: 1,
					where: {
						...id && { id: { _neq: id } },
						brandId: { _eq: brandId },
						slug: { _eq: slug },
					}
				},
				{
					id: true,
				}
			]
		});

		return !!result?.products?.length;

	}

	async saveUserOnboardingStepThree({ ...command }: ISaveUserOnboardingStepThree): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('mutation')({
			update_userOnboarding_by_pk: [
				{
					pk_columns: {

						userId: this.Auth.getUser().id
					},
					_set: {
						...command.site && { siteUrl: command.site },
						currentStep: 2,
						done: true,
					}
				},
				{
					currentStep: true,
				}

			]
		})

		return result.update_userOnboarding_by_pk as ModelTypes["userOnboarding"] | undefined;
	}

	async getBrandById(id: string): Promise<ModelTypes["brands"] | undefined> {
		const result = await this.chain('query')({
			brands_by_pk: [
				{ id: id },
				{
					id: true,
					brandName: true,
					slogan: true,
					voiceTone: true,
					segment: true,
					productOrService: true,
					fontPrimary: true,
					fontSecondary: true,
					isBrand: true,
					personality: true,
					archtypeAdherence: [{ path: '$', }, true],
				}
			]
		});

		return result.brands_by_pk as ModelTypes["brands"] | undefined;
	}

	async getBrandAggregate(): Promise<number> {
		const result = await this.chain('query')({
			brands_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [brands_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.brands_aggregate.aggregate.count as number;
	}

	async getProductsAggregate(): Promise<number> {
		const result = await this.chain('query')({
			project_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [project_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.project_aggregate.aggregate.count as number;
	}

	async upsertBrand(command: IUpsertBrand): Promise<ModelTypes["upsertBrandOutput"] | undefined> {
		const result = await this.chain('mutation')({
			upsertBrand: [
				{ ...command },
				{
					id: true,
					brandName: true,
					isBrand: true,
					slogan: true,
					segment: true,
					productOrService: true,
					fontPrimary: true,
					fontSecondary: true,
					voiceTone: true,
					personality: true,
					colors: true,
					archtypeAdherence: true,
				}
			]
		});

		return result.upsertBrand as ModelTypes["upsertBrandOutput"];
	}

	async deleteBrandtById(id: string): Promise<ModelTypes["brands"] | undefined> {
		const result = await this.chain('mutation')({
			update_brands_by_pk: [
				{
					pk_columns: { id: id },
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_brands_by_pk as ModelTypes["brands"];
	}

	async finishOnBoard(activateWebsite: boolean): Promise<ModelTypes["FinishOnboardOutput"] | undefined> {
		const result = await this.chain('mutation')({
			finishOnboard: [
				{
					activateWebsite: activateWebsite
				},
				{
					success: true,
				}
			]
		});

		return result.finishOnboard
	}

	async getAudiences(): Promise<Array<ModelTypes["audiences"]>> {
		const result = await this.chain('query')({
			audiences: [
				{},
				{
					id: true,
					brandName: true,
					segment: true,
					productOrService: true,
					targetAudience: true,
					personas: [{ path: '$', }, true],
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			]
		});

		return result.audiences as Array<ModelTypes["audiences"]>;
	}

	async getAudienceById(id: string): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('query')({
			audiences_by_pk: [
				{ id: id },
				{
					id: true,
					brandId: true,
					brandName: true,
					segment: true,
					productOrService: true,
					targetAudience: true,
					created_by: true,
					created_at: true,
					updated_at: true,
					personas: [{ path: '$', }, true]
				}
			]
		});

		return result.audiences_by_pk as ModelTypes["audiences"] | undefined;
	}

	async getAudienceAggregate(): Promise<number> {
		const result = await this.chain('query')({
			audiences_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [audiences_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.audiences_aggregate.aggregate.count as number;
	}

	async insertAudience({ ...command }: IInsertOrUpdateAudience): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('mutation')({
			insert_audiences_one: [
				{
					object: {
						...command
					}
				},
				{
					id: true,
				}
			]
		});

		return result.insert_audiences_one as ModelTypes["audiences"];
	}

	async updateAudienceById({ id, command }: { id: string, command: IInsertOrUpdateAudience }): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('mutation')({
			update_audiences_by_pk: [
				{
					pk_columns: {
						id,
					},
					_set: command as ValueTypes["audiences_set_input"] | undefined | null
				},
				{
					id: true,
				}
			]
		});

		return result.update_audiences_by_pk as ModelTypes["audiences"];
	}

	async deleteAudienceById(id: string): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('mutation')({
			update_audiences_by_pk: [
				{
					pk_columns: { id: id },
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_audiences_by_pk as ModelTypes["audiences"];
	}

	async getGeneratedPromptByTemplate(): Promise<Array<ModelTypes["GeneratedPromptByTemplate"]>> {
		const result = await this.chain('query')({
			GeneratedPromptByTemplate: [
				{},
				{
					id: true,
					result: true,
					created_at: true,
					created_by: true,
					alias: true,
					scheduledDate: true,
					channel: true,
					status: true,
					TextPromptTemplate: {
						name: true,
						creditPrice: true,
						saleCreditPrice: true,
					}
				}
			]
		});

		return result.GeneratedPromptByTemplate as Array<ModelTypes["GeneratedPromptByTemplate"]>;
	}

	async getGeneratedPromptByTemplatePaging(page: number = 1, pageSize: number = 10, where: object): Promise<{ data: Array<ModelTypes["GeneratedPromptByTemplate"]>, total: number }> {
		const offset = (page - 1) * pageSize;

		const result = await this.chain('query')({
			GeneratedPromptByTemplate: [
				{
					order_by: [{ created_at: order_by.desc }],
					limit: pageSize,
					offset: offset,
					where: where
				},
				{
					id: true,
					result: true,
					created_at: true,
					created_by: true,
					alias: true,
					scheduledDate: true,
					channel: true,
					status: true,
					publishDate: true,
					TextPromptTemplate: {
						name: true,
						creditPrice: true,
						saleCreditPrice: true,
					}
				}
			],
			GeneratedPromptByTemplate_aggregate: [
				{
					where: where
				},
				{
					aggregate: {
						count: [{ columns: [GeneratedPromptByTemplate_select_column.id], distinct: true }, true]
					}
				}
			]
		});

		return { data: result.GeneratedPromptByTemplate, total: result.GeneratedPromptByTemplate_aggregate.aggregate.count } as { data: Array<ModelTypes["GeneratedPromptByTemplate"]>, total: number };
	}

	async getGeneratedPromptByTemplateAggregateProject(): Promise<{ draft: ModelTypes["GeneratedPromptByTemplate_aggregate"], scheduled: ModelTypes["GeneratedPromptByTemplate_aggregate"], posted: ModelTypes["GeneratedPromptByTemplate_aggregate"] }> {
		const result = await this.chain('query')({
			__alias: {
				draft: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { status: { _eq: "draft" } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.status] },
									true
								]
							}
						}
					]
				},
				scheduled: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { status: { _eq: "scheduled" } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.status] },
									true
								]
							}
						}
					]
				},
				posted: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { status: { _eq: "posted" } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.status] },
									true
								]
							}
						}
					]
				},
			}
		});

		return result as { draft: ModelTypes["GeneratedPromptByTemplate_aggregate"], scheduled: ModelTypes["GeneratedPromptByTemplate_aggregate"], posted: ModelTypes["GeneratedPromptByTemplate_aggregate"] };
	}

	async getGeneratedPromptByTemplateAggregateFunnel(): Promise<{ meioFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], fundoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], topoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"] }> {
		const result = await this.chain('query')({
			__alias: {
				meioFunil: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { TextPromptTemplate: { TextPromptTemplateCategory: { id: { _eq: "f81f22db-494a-4e5e-90f1-787651f5875a" } } } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.id] },
									true
								]
							}
						}
					]
				},
				fundoFunil: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { TextPromptTemplate: { TextPromptTemplateCategory: { id: { _eq: "1fb4a117-f9cd-4ba8-bf6c-5677cfc842c0" } } } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.id] },
									true
								]
							}
						}
					]
				},
				topoFunil: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { TextPromptTemplate: { TextPromptTemplateCategory: { id: { _eq: "b4077f22-1716-45ff-9f40-bfc94b50a2f2" } } } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.id] },
									true
								]
							}
						}
					]
				}
			}
		});

		return result as { meioFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], fundoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], topoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"] };
	}

	async getGeneratedPromptByTemplateById(id: string): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const result = await this.chain('query')({
			GeneratedPromptByTemplate_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					channel: true,
					created_at: true,
					created_by: true,
					result: true,
					scheduledDate: true,
					status: true,
					textPromptTemplateId: true,
					selectedDisplayFormatId: true,
					generatingDisplayStatus: true,
					displayImageUrl: true,
					displayMapper: [{ path: '$' }, true],
					command: [{ path: '$' }, true],
					displayFormat: {
						displayDimensionId: true,
					},
					TextPromptTemplate: {
						availableDisplays: [
							{},
							{
								displayFormatId: true,
								displayFormat: {
									id: true,
									mapper: [{ path: '$' }, true],
								}
							}
						]
					}
				}
			]
		});

		return result.GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async getGeneratedPromptByTemplateByIdSubscription(id: string): Promise<void> {

		const result = this.subscription('subscription')({
			GeneratedPromptByTemplate_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					channel: true,
					created_at: true,
					created_by: true,
					result: true,
					scheduledDate: true,
					status: true,
					textPromptTemplateId: true,
					selectedDisplayFormatId: true,
					generatingDisplayStatus: true,
					displayImageUrl: true,
					displayMapper: [{ path: '$' }, true],
					command: [{ path: '$' }, true],
					displayFormat: {
						displayDimensionId: true,
						newTemplate: true,
						bannerbearId: true,
					},
					TextPromptTemplate: {
						availableDisplays: [
							{},
							{
								displayFormatId: true,
								displayFormat: {
									id: true,
									mapper: [{ path: '$' }, true],
								}
							}
						]
					},
					GeneratedPromptByTemplateImages: [
						{},
						{
							id: true,
							fileId: true,
						}
					],
				}
			]
		});

		result.on(({ GeneratedPromptByTemplate_by_pk }: { GeneratedPromptByTemplate_by_pk: ModelTypes["GeneratedPromptByTemplate"] }) => {
			console.log('GeneratedPromptByTemplate_by_pk', GeneratedPromptByTemplate_by_pk);

			this.generatedPromptByTemplateSubscription = GeneratedPromptByTemplate_by_pk
		})
	}

	async insertGeneratedPromptByTemplateOne({ textPromptTemplateId, alias, channel }: { textPromptTemplateId: string, alias: string, channel: string }): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const result = await this.chain('mutation')({
			insert_GeneratedPromptByTemplate_one: [
				{
					object: {
						textPromptTemplateId: textPromptTemplateId,
						alias: alias,
						channel: channel,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.insert_GeneratedPromptByTemplate_one as ModelTypes["GeneratedPromptByTemplate"];
	}

	async deleteGeneratedPromptByTemplateById(id: string): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const result = await this.chain('mutation')({
			delete_GeneratedPromptByTemplate_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					channel: true,
					created_at: true,
					created_by: true,
					result: true,
					scheduledDate: true,
					status: true,
					textPromptTemplateId: true,
					command: [{ path: '$' }, true]
				}
			]
		});

		return result.delete_GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async deactivateGeneratedPromptByTemplateById(id: string): Promise<ModelTypes["GeneratedPromptByTemplate"]> {
		const result = await this.chain('mutation')({
			update_GeneratedPromptByTemplate_by_pk: [
				{
					pk_columns: { id: id },
					_set: { active: false }
				},
				{
					id: true,
				}
			]
		});

		return result.update_GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async getDisplayFormats(): Promise<Array<ModelTypes["displayFormats"]>> {
		const result = await this.chain('query')({
			displayFormats: [
				{},
				{
					id: true,
					name: true,
					bannerbearId: true,
					displayName: [{ path: '$', }, true],
					mapper: [{ path: '$', }, true],
					displayCategoryId: true,
				}
			]
		});

		return result.displayFormats as Array<ModelTypes["displayFormats"]>;
	}

	async getDisplayFormatsByDimension(dimensionId: string, requiresBrand: boolean): Promise<Array<ModelTypes["displayFormats"]>> {
		const result = await this.chain('query')({
			displayFormats: [
				{
					where: {
						displayDimensionId: { _eq: dimensionId },
						requiresBrand: { _eq: requiresBrand }
					}
				},
				{
					id: true,
					name: true,
					bannerbearId: true,
					displayDimension: {
						displayName: [{ path: '$', }, true],
						id: true,
					},
					displayName: [{ path: '$', }, true],
					mapper: [{ path: '$', }, true]
				}
			]
		});

		return result.displayFormats as Array<ModelTypes["displayFormats"]>;
	}

	async getAllDisplayFormatsByDimension(dimensionId: string): Promise<Array<ModelTypes["displayFormats"]>> {
		const result = await this.chain('query')({
			displayFormats: [
				{
					where: {
						displayDimensionId: { _eq: dimensionId },
					}
				},
				{
					id: true,
					name: true,
					bannerbearId: true,
					displayDimension: {
						displayName: [{ path: '$', }, true],
						id: true,
					},
					displayName: [{ path: '$', }, true],
					mapper: [{ path: '$', }, true]
				}
			]
		});

		return result.displayFormats as Array<ModelTypes["displayFormats"]>;
	}
	async getProducts(): Promise<Array<ModelTypes["products"]>> {
		const result = await this.chain('query')({
			products: [
				{},
				{
					id: true,
					alias: true,
					type: true,
					category: true,
					price: true,
					salePrice: true,
					linkConversion: true,
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			]
		})

		return result.products as Array<ModelTypes["products"]>;
	}

	async getProductsPaging(page: number = 1, pageSize: number = 10): Promise<{ data: Array<ModelTypes["products"]>, total: number }> {
		const offset = (page - 1) * pageSize;

		const result = await this.chain('query')({
			products: [
				{
					limit: pageSize,
					offset: offset,
				},
				{
					id: true,
					alias: true,
					type: true,
					category: true,
					price: true,
					salePrice: true,
					linkConversion: true,
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			],
			products_aggregate: [
				{},
				{
					aggregate: {
						count: [{ columns: [products_select_column.id], distinct: true }, true]
					}
				}
			]
		});

		return { data: result.products, total: result.products_aggregate.aggregate.count } as { data: Array<ModelTypes["products"]>, total: number };
	}

	async getProductById(id: string): Promise<ModelTypes["products"]> {
		const result = await this.chain('query')({
			products_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					type: true,
					category: true,
					linkConversion: true,
					price: true,
					salePrice: true,
					wordKeys: [{ path: '$', }, true],
					testimonials: [{ path: '$', }, true],
					description: true,
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			]
		});

		return result.products_by_pk as ModelTypes["products"];
	}

	async deleteProductById(id: string): Promise<ModelTypes["products"] | undefined> {
		const result = await this.chain('mutation')({
			update_products_by_pk: [
				{
					pk_columns: {
						id: id,
					},
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_products_by_pk as ModelTypes["products"];
	}

	async getDisplayDimension(): Promise<Array<ModelTypes["displayDimension"]>> {
		const result = await this.chain('query')({
			displayDimension: [
				{},
				{
					id: true,
					name: true,
					displayName: [{ path: '$', }, true],
				}
			]
		});

		return result.displayDimension as Array<ModelTypes["displayDimension"]>;
	}

	async getChannels(): Promise<Array<ModelTypes["channels"]>> {
		const result = await this.chain('query')({
			channels: [
				{},
				{
					id: true,
					alias: true,
					displayName: true,
				}
			]
		});

		return result.channels as Array<ModelTypes["channels"]>;
	}

	async getUserCreditUsageSummary({ initialDate, finalDate }: { initialDate: string, finalDate: string }): Promise<Array<ModelTypes["userCreditUsageSummary"]>> {
		const result = await this.chain('query')({
			userCreditUsageSummary: [
				{
					where: { yearMonthDayReference: { _gte: initialDate, _lte: finalDate } }
				},
				{
					freeCreditUsage: true,
					totalCharged: true,
					yearMonthDayReference: true,
				}
			]
		});

		return result.userCreditUsageSummary as Array<ModelTypes["userCreditUsageSummary"]>;
	}

	async postToLinkedIn(command: IPostToLinkedIn): Promise<ModelTypes["PostToLinkedInOutput"] | undefined> {
		const result = await this.chain('mutation')({
			postToLinkedIn: [
				{ ...command },
				{ success: true }
			]
		});

		return result.postToLinkedIn as ModelTypes["PostToLinkedInOutput"];
	}

	async getFacebookToken(): Promise<ModelTypes["GetFacebookTokenOutput"] | undefined> {
		const result = await this.chain('query')({
			getFacebookToken:
			{
				loginUrl: true,
			}

		});

		return result.getFacebookToken;
	}

	async facebookAuthCallback(code: string, brandId: string): Promise<ModelTypes["FacebookAuthCallbackOutput"] | undefined> {
		const result = await this.chain('mutation')({
			facebookAuthCallback: [
				{
					code,
					brandId
				},
				{
					success: true,
				}
			]
		});

		return result.facebookAuthCallback;
	}

	async getFacebookPages(integrationId: string): Promise<ModelTypes["GetFacebookPagesOutput"] | undefined> {
		const result = await this.chain('query')({
			getFacebookPages: [
				{ integrationId },
				{
					pages: {
						id: true,
						name: true,
					}
				}
			]
		});

		return result.getFacebookPages as ModelTypes["GetFacebookPagesOutput"];
	}

	async postToFacebook(command: IPostToFacebook): Promise<ModelTypes["PostToFacebookOutput"] | undefined> {
		const result = await this.chain('mutation')({
			postToFacebook: [
				{ ...command },
				{ success: true }
			]
		});

		return result.postToFacebook as ModelTypes["PostToFacebookOutput"];
	}

	async getInstagramToken(): Promise<ModelTypes["GetInstagramTokenOutput"] | undefined> {
		const result = await this.chain('query')({
			getInstagramToken:

			{
				loginUrl: true,
			}

		});

		return result.getInstagramToken;
	}

	async instagramAuthCallback(code: string, brandId: string): Promise<ModelTypes["GetInstagramAuthCallbackOutput"] | undefined> {
		const result = await this.chain('mutation')({
			getInstagramAuthCallback: [
				{
					code,
					brandId
				},
				{
					success: true,
				}
			]
		});

		return result.getInstagramAuthCallback;
	}

	async getInstagramPages(integrationId: string): Promise<ModelTypes["GetInstagramPagesOutput"] | undefined> {
		const result = await this.chain('query')({
			getInstagramPages: [
				{ integrationId },
				{
					accounts: {
						id: true,
						name: true,
						instagramBusinessAccountId: true
					}
				}
			]
		});

		return result.getInstagramPages;
	}

	async postFeedToInstagram(command: IPostToInstagram): Promise<ModelTypes["PostFeedToInstagramOutput"] | undefined> {
		const result = await this.chain('mutation')({
			postFeedToInstagram: [
				{ ...command },
				{ success: true }
			]
		});

		return result.postFeedToInstagram as ModelTypes["PostFeedToInstagramOutput"];
	}

	async getFileById(id: string): Promise<ModelTypes["files"]> {
		const result = await this.chain('query')({
			file: [
				{ id: id },
				{
					id: true,
					bucketId: true,
				}
			]
		})

		return result?.file as ModelTypes["files"];
	}

	async saveUserData(command: ModelTypes["userOnboarding_insert_input"]): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('mutation')({
			update_userOnboarding_by_pk: [
				{
					pk_columns: {
						userId: command.userId
					},
					_set: {
						userId: command.userId,
						name: command.name,
						phone: command.phone,
						brandName: command.brandName,
						siteUrl: command.siteUrl,
						companySegment: command.companySegment,
						staffCount: command.staffCount,
						expectation: command.expectation,
						productOrService: command.productOrService,
						marketingObjective: command.marketingObjective,
						importantSocialNetworkForBusiness: command.importantSocialNetworkForBusiness,
						sellOnCopyMagico: command.sellOnCopyMagico,
						networkingOnCopyMagico: command.networkingOnCopyMagico,
						investInPaidChannels: command.investInPaidChannels,
					}
				},
				{
					userId: true,
				}
			]
		})

		return result.update_userOnboarding_by_pk as ModelTypes["userOnboarding"] | undefined;
	}

	async updateUser(command: ModelTypes["users_insert_input"]): Promise<ModelTypes["users"]> {
		const result = await this.chain('mutation')({
			updateUser: [
				{
					pk_columns: { id: command.id },
					_set: {
						displayName: command.displayName,
						email: command.email,
						phoneNumber: command.phoneNumber,
						avatarUrl: command.avatarUrl
					}
				},
				{
					id: true,
					avatarUrl: true,
				}
			]
		});

		return result.updateUser as ModelTypes["users"];
	}

	async updateGeneratedPromptByTemplateById({ id, result, displayMapper }: { id: string, result: string, displayMapper: string }): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const response = await this.chain('mutation')({
			update_GeneratedPromptByTemplate_by_pk: [
				{
					pk_columns: {
						id
					},
					_set: {
						result,
						displayMapper,
					}
				},
				{
					id: true,
				}
			]
		})

		return response.update_GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async consumptionByChannelView(): Promise<Array<ModelTypes["consumptionByChannelView"]>> {
		const result = await this.chain('query')({
			consumptionByChannelView: [
				{},
				{
					channel: true,
					rascunhos: true,
					agendados: true,
					publicados: true,
					mercado: true,
					copymagico: true,
					economia: true,
				}
			]
		});

		return result.consumptionByChannelView;
	}

	async getConsumptionByChannelViewSubscription(): Promise<void> {
		const result = this.subscription('subscription')({
			consumptionByChannelView: [
				{},
				{
					channel: true,
					rascunhos: true,
					agendados: true,
					publicados: true,
					mercado: true,
					copymagico: true,
					economia: true,
				}
			]
		});

		result.error((error) => { console.log('consumptionByChannelView', error) });

		result.on(({ consumptionByChannelView }: { consumptionByChannelView: Array<ModelTypes["consumptionByChannelView"]> }) => {
			this.consumptionByChannelViewSubscription = consumptionByChannelView;
		});
	}

	async insertUserFeedback(command: ModelTypes['userFeedback_insert_input']): Promise<string> {
		const result = await this.chain('mutation')({
			insert_userFeedback_one: [
				{
					object: command
				},
				{
					id: true,
				}
			]
		});

		return result.insert_userFeedback_one.id as string;
	}

	async userMedia(): Promise<Array<ModelTypes["userMedia"]>> {
		const result = await this.chain('query')({
			userMedia: [
				{},
				{
					id: true,
					fileId: true,
					ownerId: true,
					created_at: true,
					updated_at: true,
				}
			]
		});

		return result?.userMedia as Array<ModelTypes["userMedia"]>
	}

	async publishOrScheduleProject(command: IPublishOrScheduleProject): Promise<ModelTypes["PublishOrScheduleProjectOutput"]> {
		const mutation = await this.chain('mutation')({
			publishOrScheduleProject: [
				{
					channel: command.channel,
					subtype: command.subtype,
					projectId: command.projectId,
					integrationId: command.integrationId,
					scheduledDate: command.scheduledDate,
					facebookPageId: command.facebookPageId,
					instagramBusinessId: command.instagramBusinessId,
					blogTags: command.blogTags,
					blogCategory: command.blogCategory,
				},
				true
			]
		});

		const result: { output: ModelTypes["PublishOrScheduleProjectOutput"] } = await new Promise((resolve) => {
			const subscription = this.subscription('subscription')({
				createImageUsingImagine: [
					{
						id: mutation.publishOrScheduleProject
					},
					{
						output: {
							fileId: true,
							success: true,
							errorMessage: true
						}
					}
				]
			});

			subscription.error((error) => console.log('publish or submit error', error));

			subscription.on(({ createImageUsingImagine }: { createImageUsingImagine: { output: ModelTypes['PublishOrScheduleProjectOutput'] } }) => {
				if (createImageUsingImagine?.output) {
					resolve(createImageUsingImagine);
				}
			});
		});

		return result.output as ModelTypes["PublishOrScheduleProjectOutput"];
	}

	async deleteProjectById(id: string): Promise<ModelTypes["project"] | undefined> {
		const result = await this.chain('mutation')({
			update_project_by_pk: [
				{
					pk_columns: {
						id: id,
					},
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_project_by_pk as ModelTypes["project"];
	}
}

export const IZeusClient = DI.createInterface<ZeusClient>(x => x.singleton(ZeusClient));