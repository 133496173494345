import { inject } from "aurelia";
import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { format, parseISO } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

@valueConverter('dateToFullDateFormat')
@inject(I18N)
export class DateToFullDateFormat {
  constructor(private I18N: I18N) { }

  toView(date) {
    return format(parseISO(date), 'dd/MM/yyyy', { locale: ptBR });
  }
}