import { CustomElement } from '@aurelia/runtime-html';
export const name = "single-file-upload-component";
export const template = "<div>\n\t<label class=\"form-label\">${label}</label>\n\n\t<div class=\"w-full p-2 border-2 border-dashed border-gray-300 rounded flex items-center justify-center h-60 group\">\n\t\t<template if.bind=\"selectedFileId\">\n\t\t\t<div class=\"relative h-full w-full\">\n\t\t\t\t<img src=\"${'https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/' + selectedFileId}\" class=\"w-full h-full object-cover rounded\" />\n\n\t\t\t\t<label for=\"${elementid}\" class=\"absolute bg-blue-100/75 cursor-pointer group-hover:block h-full hidden p-4 text-center top-0 w-full\">\n\t\t\t\t\t<div class=\"flex flex-col justify-center items-center w-full h-full\">\n\t\t\t\t\t\t<i class=\"fas fa-cloud-upload-alt text-4xl text-gray-600\"></i>\n\t\t\t\t\t\t<p class=\"text-gray-600\">Trocar Foto</p>\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t\t<input type=\"file\" class=\"hidden\" id=\"${elementid}\" change.trigger=\"changeFile($event)\" accept=\"${accept}\" />\n\n\t\t\t\t<button click.trigger=\"removeFile()\" class=\"absolute bg-red-500/75 cursor-pointer group-hover:flex h-8 hidden p-2 text-center top-2 right-2 text-white w-8 rounded-full items-center justify-center\">\n\t\t\t\t\t<i class=\"fas fa-trash text-sm\"></i>\n\t\t\t\t</button>\n\t\t\t</div>\n\n\t\t</template>\n\t\t<template else>\n\t\t\t<label for=\"${elementid}\" class=\"cursor-pointer text-center\">\n\t\t\t\t<i class=\"fas fa-cloud-upload-alt text-4xl text-gray-400\"></i>\n\t\t\t\t<p class=\"text-gray-400\">Escolher Foto</p>\n\t\t\t</label>\n\t\t</template>\n\t</div>\n\n\t<input type=\"file\" class=\"hidden\" id=\"${elementid}\" change.trigger=\"changeFile($event)\" accept=\"${accept}\" />\n</div>";
export default template;
export const dependencies = [  ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
