import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/form/input/input-component";
import * as d1 from "@components/accordion/accordion-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
import * as d3 from "@components/upload/single-file-upload/single-file-upload-component";
export const name = "contact-form-section";
export const template = "\n\n\n\n\n<accordion-component title=\"Formulário de Contato\">\n  <div au-slot=\"body\">\n    <form class=\"flex flex-col gap-4\" autocomplete=\"off\">\n\n      <div class=\"flex-1\">\n        <input-component type=\"text\" label=\"Título do Formulário de Contato\" value.bind=\"website.aboutUsContactTitle\" />\n      </div>\n\n      <div class=\"flex-1\">\n        <single-file-upload-component label=\"Banner do Formulário de Contato\"\n          selected-file-id.bind=\"website.aboutUsContactBannerImageFileId\"></single-file-upload-component>\n      </div>\n\n      <div class=\"flex flex-row justify-end mt-4\">\n        <spinner-button-component label=\"Salvar\" btn-class=\"btn-primary min-w-20\"\n          trigger-function.bind=\"() => handleSave()\"></spinner-button-component>\n      </div>\n\n    </form>\n  </div>\n</accordion-component>";
export default template;
export const dependencies = [ d0, d1, d2, d3 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
