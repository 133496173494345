import { inject } from "aurelia";
import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

@valueConverter('sameMonthClass')
@inject(I18N)
export class SameMonthClass {

  constructor(private I18N: I18N) { }

  toView(date, baseDate) {
    const isEqual = format(date, 'yyyy-MM', { locale: ptBR }) === format(baseDate, 'yyyy-MM', { locale: ptBR });

    return isEqual ? 'bg-white dark:bg-gray-800 dark:text-gray-400' : 'bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-200';
  }

}