import { nhost } from '@lib/nhost';
import { bindable, BindingMode } from "aurelia";

export class SingleFileUploadComponent {

  @bindable label: string;
  @bindable({ mode: BindingMode.twoWay }) selectedFileId: string;
  @bindable({ mode: BindingMode.twoWay }) accept: string = 'image/*';

  private elementid: string = crypto.randomUUID();

  async changeFile(event: { target: { files: any[]; }; }) {
    const file = event.target.files[0]

    if (!file) return

    const { fileMetadata, error: errorUpload } = await nhost.storage.upload(
      { file: file, bucketId: 'public' }
    );

    console.error(fileMetadata)

    if (fileMetadata.id) {
      this.selectedFileId = fileMetadata.id
    }
  }

  removeFile() {
    this.selectedFileId = null
  }
}