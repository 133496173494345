import { inject } from "aurelia";
import { I18N } from "@aurelia/i18n";
import { IRouter } from '@aurelia/router';
import { toastifyError } from '@helpers/toastify';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { Parameters } from "@aurelia/router";
import { GoogleAnalyticsHelper, GoogleAnalyticsEventAction, GoogleAnalyticsEventCategory } from "@helpers/google-analytics/google-analytics-helper";

@inject(I18N, IRouter, IAuthService, IValidationRules, newInstanceForScope(IValidationController))
export class LoginOtpPage {
  private email: string;
  private termOfAdhesion: boolean = false;
  private isLoading: boolean;

  constructor(
    private I18N: I18N,
    private router: IRouter,
    private auth: IAuthService,
    private validationRules: IValidationRules,
    private validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('email')
      .email()
      .withMessage('Email inválido')
      .required()
      .withMessage('Email é obrigatório')

    // .ensure('termOfAdhesion')
    // .required()
    // .satisfies((value: boolean) => value === true)
    // .withMessage('É preciso aceitar os termos de adesão')
  }

  loading(parameters: Parameters) {
    if (parameters) {
      localStorage.setItem('cm_metadata', JSON.stringify(parameters));
    }
  }

  private async signInOTP() {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    this.isLoading = true;

    try {

      const cm_metadata = localStorage.getItem('cm_metadata') ?? '{}';
      GoogleAnalyticsHelper.reportConversion();


      const response = await this.auth.signInOTP({ email: this.email, metadata: JSON.parse(cm_metadata) });

      if (!response) throw new Error('Algo deu errado, tente novamente mais tarde');

      localStorage.removeItem('cm_metadata');
      localStorage.setItem('copymagico-email', this.email);

      this.router.load('/otp');
    } catch (error) {
      if (error instanceof Error) {
        toastifyError({ message: error.message });
      } else {
        toastifyError({ message: 'Algo deu errado, tente novamente mais tarde' });
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async handleLoginWithGoogle() {
    try {
      const cm_metadata = localStorage.getItem('cm_metadata');
      GoogleAnalyticsHelper.reportConversion();

      await this.auth.signInForGoogle({
        ...cm_metadata && { metadata: JSON.parse(cm_metadata) }
      });

      localStorage.removeItem('cm_metadata');

      this.router.load('/');
    } catch (error) {
      if (error instanceof Error) {
        toastifyError({ message: error.message })
      } else {
        toastifyError({ message: 'Algo deu errado, tente novamente mais tarde' });
      }
    }
  }
}
