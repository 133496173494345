import { inject } from "aurelia";
import { Modal } from 'flowbite'
import { ModelTypes } from '@zeus';
import type { ModalOptions, ModalInterface } from 'flowbite'
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

@inject(IZeusClient)
export class AddIntegrationModalComponent {

  private brandId: string;
  private modal: ModalInterface;

  private wordpressUrl: string;

  constructor(
    private zeusClient: ZeusClient
  ) { }

  attached() {
    const $modalElement: HTMLElement = document.querySelector('#add-integration-modal');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
    }

    this.modal = new Modal($modalElement, modalOptions);
  }

  public configureIntegrationCallback(e: string) {
    if (e === 'instagram') {
      this.instagramConfig();
      return;
    }

    if (e === 'linkedin') {
      this.linkedInConfig();
      return;
    }

    if (e === 'facebook') {
      this.facebookConfig();
      return;
    }
  }

  async getAppIntegrations(): Promise<Array<ModelTypes["AppIntegration"]>> {
    const integrations = await this.zeusClient.getAppIntegrations();

    return integrations;
  }

  async instagramConfig() {
    const { loginUrl } = await this.zeusClient.getInstagramToken();

    window.document.location.href = loginUrl;
  }

  async facebookConfig() {
    const { loginUrl } = await this.zeusClient.getFacebookToken();

    window.document.location.href = loginUrl;
  }

  async linkedInConfig() {
    const CLIENT_ID = '77k8v77rx23x9x'
    const URL_REDIRECT = 'https://app.copymagico.com/linkedin-success'

    const csrfState = '';

    let url = 'https://www.linkedin.com/oauth/v2/authorization/'

    url += `?client_id=${CLIENT_ID}`
    url += '&scope=r_liteprofile%20r_emailaddress%20w_member_social'
    url += '&response_type=code'
    url += `&redirect_uri=${URL_REDIRECT}`
    url += `&state=${csrfState}`

    window.document.location.href = url;
  }

  // toQueryString(obj: { [x: string]: string | number | boolean; response_type?: string; client_id?: string; redirect_uri?: string; state?: string; scope?: string; hasOwnProperty?: any; }) {
  //   const parts = [];
  //   for (const i in obj) {
  //     if (obj.hasOwnProperty(i)) {
  //       parts.push(`${encodeURIComponent(i)}=${encodeURIComponent(obj[i])}`);
  //     }
  //   }
  //   return parts.join('&');
  // }

  public openAddIntegrationModal(): void {
    this.modal.show();
  }

  private hideModal(): void {
    this.modal.hide();
  }

}
