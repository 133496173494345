import { uuid4 } from '@helpers/crypto/crypto';
import { BindingMode, bindable, capture } from "aurelia";
import { IFormInputOption } from '../interfaces/form-interface';

@capture((attrs) => attrs !== 'id' && attrs !== 'label' && attrs !== 'validate' && attrs !== 'class')
export class RadioButtonComponent {
  private id: string = uuid4()

  @bindable public disabled: string
  @bindable public label: string
  @bindable public validate: boolean = false
  @bindable public options: IFormInputOption[]
  @bindable({ mode: BindingMode.twoWay }) public selectedValue: string | number | boolean
}