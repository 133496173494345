import { inject } from "aurelia";
import { nhost } from '@lib/nhost';
import { IRouter } from '@aurelia/router';
import { IEventAggregator, bindable } from 'aurelia';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { StorageUploadFileResponse } from '@nhost/nhost-js';
import { IValidationController } from '@aurelia/validation-html';
import { IBrandGuidService } from './services/brand-guide-service';
import chroma from 'chroma-js';
import WebFont from 'webfontloader';

import { companySegmentData, fontFamilyData, isBrandData, personalityData, productOrServiceData, voiceToneData } from '@data/index';
import { IConfirmAction } from '@components/confirm-action';
import { toastifyError, toastifySuccess } from "@helpers/toastify";

@inject(IRouter, IEventAggregator, IBrandGuidService, IValidationRules, newInstanceForScope(IValidationController), IConfirmAction)
export class BrandGuidePage {
  @bindable logoFile: File;
  @bindable logoFileId: string;

  private currentFile: CurrentFile;

  private brandId: string;
  private brandName: string;
  private isBrand: boolean;
  private slogan: string;
  private segment: string;
  private productOrService: string;
  private fontPrimary: string;
  private fontSecondary: string;
  private voiceTone: string;
  private personality: string;

  @bindable backgroundColor: string;
  @bindable foregroundColor: string;
  @bindable accentColor: string;

  private isBrandData = isBrandData;
  private voiceToneData = voiceToneData;
  private fontFamilyData = fontFamilyData;
  private segmentData = companySegmentData;
  private personalityData = personalityData;
  private productOrServiceData = productOrServiceData;

  private colorErrors: string;

  private textColor: string = '#ffffff';
  private shapeBlue: string = '#4a90e2';
  private shapeOrange: string = '#ff3e00';

  private titleRef: HTMLElement;
  private headlineRef: HTMLElement;

  constructor(
    private router: IRouter,
    readonly ea: IEventAggregator,
    private service: IBrandGuidService,
    readonly validationRules: IValidationRules,
    readonly validationController: IValidationController,
    private confirmAction: IConfirmAction,
  ) {
    validationRules
      .on(this)
      .ensure('brandName')
      .required().withMessage('Informe o nome da marca')

      .ensure('isBrand')
      .required().withMessage('Selecione o contexto da marca')

      .ensure('logoFile')
      .required()
      .when((x: BrandGuidePage) => !x.logoFileId)
      .withMessage('Selecione um logo')

      .ensure('segment')
      .required().withMessage('Selecione o segmento')

      .ensure('productOrService')
      .required().withMessage('Selecione o produto ou serviço')

      .ensure('fontPrimary')
      .required().withMessage('Selecione a fonte primária')

      .ensure('fontSecondary')
      .required().withMessage('Selecione a fonte secundária')

      .ensure('voiceTone')
      .required().withMessage('Selecione o tom de voz')

      .ensure('personality')
      .required().withMessage('Selecione a personalidade')

      .ensure('backgroundColor')
      .required().withMessage('Selecione a cor principal')

      .ensure('foregroundColor')
      .required().withMessage('Selecione a cor base')

      .ensure('accentColor')
      .required().withMessage('Selecione a cor de destaque');
  }

  async loading() {
    await this.getBrands();
  }

  private async getBrands() {
    const result = await this.service.getBrands();

    if (!result.length) return;

    const brand = result[0];

    this.brandId = brand.id;
    this.brandName = brand.brandName;
    this.isBrand = brand.isBrand;
    this.logoFileId = brand.logoFileId;
    this.slogan = brand.slogan;
    this.segment = brand.segment;
    this.productOrService = brand.productOrService;
    this.fontPrimary = brand.fontPrimary;
    this.fontSecondary = brand.fontSecondary;
    this.voiceTone = brand.voiceTone;
    this.personality = brand.personality;
    this.backgroundColor = brand.backgroundColor;
    this.foregroundColor = brand.foregroundColor;
    this.accentColor = brand.accentColor;

    this.gandleGetShade();
    this.updateFont();
  }

  private backgroundColorChanged() {
    this.gandleGetShade();
  }

  private foregroundColorChanged() {
    this.gandleGetShade();
  }

  private accentColorChanged() {
    this.gandleGetShade();
  }

  private gandleGetShade() {
    if (!this.backgroundColor && !this.foregroundColor) return;

    this.textColor = this.foregroundColor;
    this.shapeOrange = this.backgroundColor;
    this.shapeBlue = this.accentColor;
  }

  private getShade(color: string, index: string | number): string {
    const shades = chroma.scale([color, '#ffffff']).colors(7)
    return shades[index];
  }

  private updateFont() {
    if (this.fontPrimary) {
      this.loadFont(this.fontPrimary);
    }
  }

  private loadFont(fontPrimary: string) {
    WebFont.load({
      google: {
        families: [fontPrimary]
      },
      fontactive: (familyName: string) => {
        this.applyFont(familyName);
      }
    });
  }

  private applyFont(familyName: string) {
    if (this.titleRef) {
      this.titleRef.style.fontFamily = familyName;
    }

    if (this.headlineRef) {
      this.headlineRef.style.fontFamily = familyName;
    }
  }

  private async handleUpsertBrand(): Promise<void> {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    await this.confirmAction.open({
      type: "success",
      title: "Salvar marca",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        try {
          let logoResponse: StorageUploadFileResponse | null = null;

          if (this.logoFile) {
            logoResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.logoFile,
              ...this.logoFileId && { fileId: this.logoFileId }
            });
          }
          console.log('before upsertBrand');
          await this.service.upsertBrand({
            brandId: this.brandId,
            brandName: this.brandName,
            isBrand: this.isBrand,
            logoFileId: logoResponse ? logoResponse.fileMetadata.id : this.logoFileId,
            slogan: this.slogan,
            segment: this.segment,
            productOrService: this.productOrService,
            fontPrimary: this.fontPrimary,
            fontSecondary: this.fontSecondary,
            voiceTone: this.voiceTone,
            personality: this.personality,
            backgroundColor: this.backgroundColor,
            foregroundColor: this.foregroundColor,
            accentColor: this.accentColor,
          });
          toastifySuccess({ message: 'Marca salva com sucesso!' });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          toastifyError({ message: 'Erro ao salvar marca!' });
        }
      }

    });
  }

  private logoFileChanged(file: File) {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      const base64Data = fileReader.result as string;

      const fileId = Math.random().toString(36).substring(2, 9);

      const fileData: CurrentFile = {
        id: fileId,
        file: base64Data,
        size: file.size,
      }

      this.currentFile = fileData;
    }

    if (file) {
      fileReader.readAsDataURL(file);
    }

  }
}

type CurrentFile = {
  id: string;
  file: string;
  size: number;
}