import { inject } from "aurelia";
import { bindable } from 'aurelia';
import { IRouter, Route } from '@aurelia/router';
import { initDropdowns, initCollapses, initDrawers } from 'flowbite';
import { INavBarStructure } from '@helpers/router/router-navigation-helper';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from "@services/graphql-service/zeus-client";
import { ModelTypes } from "@zeus";

@inject(IRouter, IAuthService, INavBarStructure, IZeusClient)
export class SidebarComponent {
  @bindable open: boolean;

  private routes: Route[];

  private version: string = import.meta.env.APP_VERSION;
  private showWebsiteConfigSpinner: boolean = true;

  public constructor(
    private router: IRouter,
    private auth: IAuthService,
    private navBarStructure: INavBarStructure,
    private zeusClient: ZeusClient,
  ) {
    this.routes = this.router?.rootScope?.getRoutes() ?? [];
  }

  attached() {
    initDrawers();
    initCollapses();
    initDropdowns();

    const user = this.auth.user;

    const websiteSubscription = this.zeusClient.subscription('subscription')({
      website: [
        {
          where: {
            brand: {
              created_by: {
                _eq: user.id
              }
            }
          }
        }, {
          domain: true
        }
      ]
    });

    websiteSubscription.on(({ website }: { website: ModelTypes["website"][] | undefined }) => {

      if (website.length > 0) {
        this.showWebsiteConfigSpinner = false;
      }
    }
    );
  }



  private routeById(id: string) {
    return this.routes.find((route) => route.data['id'] === id).path;
  }
}