import { CustomElement } from '@aurelia/runtime-html';
import * as d0 from "@components/form/input/input-component";
import * as d1 from "@components/accordion/accordion-component";
import * as d2 from "@components/spinner-button/spinner-button-component";
import * as d3 from "@components/upload/single-file-upload/single-file-upload-component";
export const name = "what-move-us-section";
export const template = "\n\n\n\n\n<accordion-component title=\"Missão, Visão & Valores\">\n  <div au-slot=\"body\">\n    <form class=\"flex flex-col gap-4\" autocomplete=\"off\">\n\n      <div class=\"flex-1\">\n        <input-component type=\"text\" label=\"Título da Sessão Sobre Valores da Marca\"\n          value.bind=\"website.aboutUsWhatMoveUsTitle\" />\n      </div>\n      <div class=\"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4\">\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Missão da Marca\" value.bind=\"website.aboutUsWhatMoveUsMission\" />\n        </div>\n\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Visão da Marca\" value.bind=\"website.aboutUsWhatMoveUsVision\" />\n        </div>\n\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Valores da Marca\" value.bind=\"website.aboutUsWhatMoveUsValues\" />\n        </div>\n\n        <div class=\"flex-1\">\n          <input-component type=\"text\" label=\"Propósito da Marca\" value.bind=\"website.aboutUsWhatMoveUsPurpose\" />\n        </div>\n      </div>\n\n      <div class=\"flex-1\">\n        <single-file-upload-component label=\"Banner Da Sessão Sobre Valores\"\n          selected-file-id.bind=\"website.aboutUsWhatMoveBannerImageFileId\"></single-file-upload-component>\n      </div>\n\n      <div class=\"flex flex-row justify-end mt-4\">\n        <spinner-button-component label=\"Salvar\" btn-class=\"btn-primary min-w-20\"\n          trigger-function.bind=\"() => handleSave()\"></spinner-button-component>\n      </div>\n\n    </form>\n  </div>\n</accordion-component>";
export default template;
export const dependencies = [ d0, d1, d2, d3 ];
export const bindables = {};
let _e;
export function register(container) {
  if (!_e) {
    _e = CustomElement.define({ name, template, dependencies, bindables });
  }
  container.register(_e);
}
