import { inject } from "aurelia";
import { ModelTypes } from '@zeus'
import { IEventAggregator, bindable } from 'aurelia'
import { IConfirmAction } from '@components/confirm-action';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client'
import { type DropdownOptions, type DropdownInterface, Dropdown } from 'flowbite'

@inject(IZeusClient, IEventAggregator, IConfirmAction)
export class TableActionsComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  @bindable deleteCallback: () => Promise<void>
  @bindable appIntegration: ModelTypes["AppIntegration"] | undefined

  constructor(
    readonly zeusClient: ZeusClient,
    readonly ea: IEventAggregator,
    private confirmAction: IConfirmAction,
  ) { }

  attached() {
    this.$targetEl = document.getElementById(`dropdown-${this.appIntegration.id}`);

    this.$triggerEl = document.getElementById(`dropdown-${this.appIntegration.id}-button`);

    const options: DropdownOptions = {
      placement: 'left',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  detaching() {
    if (!this.$targetEl || !this.$triggerEl) {
      return;
    }

    this.$targetEl.remove();
    this.$triggerEl.remove();

    this.$targetEl = null;
    this.$triggerEl = null;
    this.dropdown = null;
  }

  async handleDeleteIntegration(): Promise<void> {
    this.dropdown.hide();

    await this.confirmAction.open({
      type: "danger",
      title: "Deletar integração",
      message: "Tem certeza que deseja continuar?",
      confirmText: "Sim, continuar",
      confirmCallback: async () => {
        await this.deleteCallback()
      }
    });
  }
}