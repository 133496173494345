import { inject } from "aurelia";
import { IRouter, Route } from '@aurelia/router';
import { ICustomElementController } from "@aurelia/runtime-html";
import { INavBarStructure } from "@helpers/router/router-navigation-helper";
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';

import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog'

@inject(IDialogDom, IRouter, IAuthService, INavBarStructure)
export class DrawerComponent implements IDialogCustomElementViewModel {
  private routes: Route[];

  private version: string = import.meta.env.APP_VERSION;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  constructor(
    private dialogDom: DefaultDialogDom,
    private router: IRouter,
    private auth: IAuthService,
    private navBarStructure: INavBarStructure
  ) {
    dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

    dialogDom.contentHost.className = '!fixed !m-0 overflow-y-auto overflow-x-hidden top-0 left-0 z-50 h-full max-h-full flex'
  }

  async attaching() {
    const animation = this.dialogDom.contentHost.animate([
      { transform: 'translateX(-100%)' },
      { transform: 'translateX(0)' },
    ], {
      duration: 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    await animation.finished;
  }

  async detaching() {
    const animation = this.dialogDom.contentHost.animate([
      { transform: 'translateX(0)' },
      { transform: 'translateX(-100%)' },
    ], {
      duration: 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    await animation.finished;
  }

  activate() {
    this.routes = this.router?.rootScope?.getRoutes() ?? [];
  }

  private closeDrawer() {
    this.$dialog.cancel();
  }

  private routeById(id: string) {
    const path = this.routes?.find((route) => route.data['id'] === id)?.path;

    return path?.length === 0 ? '/' : path;
  }
}